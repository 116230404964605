<script>
import { hydrateWhenIdle } from 'vue-lazy-hydration'
import defaultLayout from '~~/layouts/default.vue'

const width = 925
const ratio = 0.56349

export default {
  name: 'DefaultLayout',

  components: {
    TopHeaderComponent: hydrateWhenIdle(() =>
      import('~~/components/Layout/TopHeader/TopHeader')
    ),
    MainMenu: () => import('~/components/Layout/MainMenu/MainMenu')
  },

  extends: defaultLayout,

  data: () => ({
    modalOptions: {
      autoplay: true,
      controls: true,
      muted: false,
      width,
      height: width * ratio,
      source:
        'https://dylmsnce3s2r5.cloudfront.net/3ea83cfb-0a6e-4cbc-b949-2ea37dba56e2/hls/introduction.m3u8'
    }
  })
}
</script>
