/* eslint-disable */
export default {
  "cart/bankWireTransfer@2x.png": {
    "retina": "/images/hi-res/cart/bankWireTransfer@2x.png",
    "normal": "/images/normal/cart/bankWireTransfer.png",
    "retinaWebp": "/images/hi-res-webp/cart/bankWireTransfer@2x.webp",
    "webp": "/images/normal-webp/cart/bankWireTransfer.webp"
  },
  "cart/certifiedFunds@2x.png": {
    "retina": "/images/hi-res/cart/certifiedFunds@2x.png",
    "normal": "/images/normal/cart/certifiedFunds.png",
    "retinaWebp": "/images/hi-res-webp/cart/certifiedFunds@2x.webp",
    "webp": "/images/normal-webp/cart/certifiedFunds.webp"
  },
  "cart/creditCard@2x.png": {
    "retina": "/images/hi-res/cart/creditCard@2x.png",
    "normal": "/images/normal/cart/creditCard.png",
    "retinaWebp": "/images/hi-res-webp/cart/creditCard@2x.webp",
    "webp": "/images/normal-webp/cart/creditCard.webp"
  },
  "cart/providers/bank@2x.jpg": {
    "retina": "/images/hi-res/cart/providers/bank@2x.jpg",
    "normal": "/images/normal/cart/providers/bank.jpg",
    "retinaWebp": "/images/hi-res-webp/cart/providers/bank@2x.webp",
    "webp": "/images/normal-webp/cart/providers/bank.webp"
  },
  "cart/providers/bitcoin@2x.jpg": {
    "retina": "/images/hi-res/cart/providers/bitcoin@2x.jpg",
    "normal": "/images/normal/cart/providers/bitcoin.jpg",
    "retinaWebp": "/images/hi-res-webp/cart/providers/bitcoin@2x.webp",
    "webp": "/images/normal-webp/cart/providers/bitcoin.webp"
  },
  "cart/providers/discover@2x.jpg": {
    "retina": "/images/hi-res/cart/providers/discover@2x.jpg",
    "normal": "/images/normal/cart/providers/discover.jpg",
    "retinaWebp": "/images/hi-res-webp/cart/providers/discover@2x.webp",
    "webp": "/images/normal-webp/cart/providers/discover.webp"
  },
  "cart/providers/express@2x.jpg": {
    "retina": "/images/hi-res/cart/providers/express@2x.jpg",
    "normal": "/images/normal/cart/providers/express.jpg",
    "retinaWebp": "/images/hi-res-webp/cart/providers/express@2x.webp",
    "webp": "/images/normal-webp/cart/providers/express.webp"
  },
  "cart/providers/master@2x.jpg": {
    "retina": "/images/hi-res/cart/providers/master@2x.jpg",
    "normal": "/images/normal/cart/providers/master.jpg",
    "retinaWebp": "/images/hi-res-webp/cart/providers/master@2x.webp",
    "webp": "/images/normal-webp/cart/providers/master.webp"
  },
  "cart/providers/visa@2x.jpg": {
    "retina": "/images/hi-res/cart/providers/visa@2x.jpg",
    "normal": "/images/normal/cart/providers/visa.jpg",
    "retinaWebp": "/images/hi-res-webp/cart/providers/visa@2x.webp",
    "webp": "/images/normal-webp/cart/providers/visa.webp"
  },
  "common/modal/gemstone-pairs@2x.jpg": {
    "retina": "/images/hi-res/common/modal/gemstone-pairs@2x.jpg",
    "normal": "/images/normal/common/modal/gemstone-pairs.jpg",
    "retinaWebp": "/images/hi-res-webp/common/modal/gemstone-pairs@2x.webp",
    "webp": "/images/normal-webp/common/modal/gemstone-pairs.webp"
  },
  "common/modal/gemstones@2x.jpg": {
    "retina": "/images/hi-res/common/modal/gemstones@2x.jpg",
    "normal": "/images/normal/common/modal/gemstones.jpg",
    "retinaWebp": "/images/hi-res-webp/common/modal/gemstones@2x.webp",
    "webp": "/images/normal-webp/common/modal/gemstones.webp"
  },
  "common/shopColor/bi-color@2x.png": {
    "retina": "/images/hi-res/common/shopColor/bi-color@2x.png",
    "normal": "/images/normal/common/shopColor/bi-color.png",
    "retinaWebp": "/images/hi-res-webp/common/shopColor/bi-color@2x.webp",
    "webp": "/images/normal-webp/common/shopColor/bi-color.webp"
  },
  "common/shopColor/black@2x.png": {
    "retina": "/images/hi-res/common/shopColor/black@2x.png",
    "normal": "/images/normal/common/shopColor/black.png",
    "retinaWebp": "/images/hi-res-webp/common/shopColor/black@2x.webp",
    "webp": "/images/normal-webp/common/shopColor/black.webp"
  },
  "common/shopColor/blue@2x.png": {
    "retina": "/images/hi-res/common/shopColor/blue@2x.png",
    "normal": "/images/normal/common/shopColor/blue.png",
    "retinaWebp": "/images/hi-res-webp/common/shopColor/blue@2x.webp",
    "webp": "/images/normal-webp/common/shopColor/blue.webp"
  },
  "common/shopColor/brown@2x.png": {
    "retina": "/images/hi-res/common/shopColor/brown@2x.png",
    "normal": "/images/normal/common/shopColor/brown.png",
    "retinaWebp": "/images/hi-res-webp/common/shopColor/brown@2x.webp",
    "webp": "/images/normal-webp/common/shopColor/brown.webp"
  },
  "common/shopColor/color-change@2x.png": {
    "retina": "/images/hi-res/common/shopColor/color-change@2x.png",
    "normal": "/images/normal/common/shopColor/color-change.png",
    "retinaWebp": "/images/hi-res-webp/common/shopColor/color-change@2x.webp",
    "webp": "/images/normal-webp/common/shopColor/color-change.webp"
  },
  "common/shopColor/green@2x.png": {
    "retina": "/images/hi-res/common/shopColor/green@2x.png",
    "normal": "/images/normal/common/shopColor/green.png",
    "retinaWebp": "/images/hi-res-webp/common/shopColor/green@2x.webp",
    "webp": "/images/normal-webp/common/shopColor/green.webp"
  },
  "common/shopColor/grey@2x.png": {
    "retina": "/images/hi-res/common/shopColor/grey@2x.png",
    "normal": "/images/normal/common/shopColor/grey.png",
    "retinaWebp": "/images/hi-res-webp/common/shopColor/grey@2x.webp",
    "webp": "/images/normal-webp/common/shopColor/grey.webp"
  },
  "common/shopColor/multi-color@2x.png": {
    "retina": "/images/hi-res/common/shopColor/multi-color@2x.png",
    "normal": "/images/normal/common/shopColor/multi-color.png",
    "retinaWebp": "/images/hi-res-webp/common/shopColor/multi-color@2x.webp",
    "webp": "/images/normal-webp/common/shopColor/multi-color.webp"
  },
  "common/shopColor/orange@2x.png": {
    "retina": "/images/hi-res/common/shopColor/orange@2x.png",
    "normal": "/images/normal/common/shopColor/orange.png",
    "retinaWebp": "/images/hi-res-webp/common/shopColor/orange@2x.webp",
    "webp": "/images/normal-webp/common/shopColor/orange.webp"
  },
  "common/shopColor/pink@2x.png": {
    "retina": "/images/hi-res/common/shopColor/pink@2x.png",
    "normal": "/images/normal/common/shopColor/pink.png",
    "retinaWebp": "/images/hi-res-webp/common/shopColor/pink@2x.webp",
    "webp": "/images/normal-webp/common/shopColor/pink.webp"
  },
  "common/shopColor/purple@2x.png": {
    "retina": "/images/hi-res/common/shopColor/purple@2x.png",
    "normal": "/images/normal/common/shopColor/purple.png",
    "retinaWebp": "/images/hi-res-webp/common/shopColor/purple@2x.webp",
    "webp": "/images/normal-webp/common/shopColor/purple.webp"
  },
  "common/shopColor/red@2x.png": {
    "retina": "/images/hi-res/common/shopColor/red@2x.png",
    "normal": "/images/normal/common/shopColor/red.png",
    "retinaWebp": "/images/hi-res-webp/common/shopColor/red@2x.webp",
    "webp": "/images/normal-webp/common/shopColor/red.webp"
  },
  "common/shopColor/white@2x.png": {
    "retina": "/images/hi-res/common/shopColor/white@2x.png",
    "normal": "/images/normal/common/shopColor/white.png",
    "retinaWebp": "/images/hi-res-webp/common/shopColor/white@2x.webp",
    "webp": "/images/normal-webp/common/shopColor/white.webp"
  },
  "common/shopColor/yellow@2x.png": {
    "retina": "/images/hi-res/common/shopColor/yellow@2x.png",
    "normal": "/images/normal/common/shopColor/yellow.png",
    "retinaWebp": "/images/hi-res-webp/common/shopColor/yellow@2x.webp",
    "webp": "/images/normal-webp/common/shopColor/yellow.webp"
  },
  "common/shopType/pair/agate@2x.png": {
    "retina": "/images/hi-res/common/shopType/pair/agate@2x.png",
    "normal": "/images/normal/common/shopType/pair/agate.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/agate@2x.webp",
    "webp": "/images/normal-webp/common/shopType/pair/agate.webp"
  },
  "common/shopType/pair/alexandrite@2x.png": {
    "retina": "/images/hi-res/common/shopType/pair/alexandrite@2x.png",
    "normal": "/images/normal/common/shopType/pair/alexandrite.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/alexandrite@2x.webp",
    "webp": "/images/normal-webp/common/shopType/pair/alexandrite.webp"
  },
  "common/shopType/pair/almandine-garnet@2x.png": {
    "retina": "/images/hi-res/common/shopType/pair/almandine-garnet@2x.png",
    "normal": "/images/normal/common/shopType/pair/almandine-garnet.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/almandine-garnet@2x.webp",
    "webp": "/images/normal-webp/common/shopType/pair/almandine-garnet.webp"
  },
  "common/shopType/pair/amethyst@2x.png": {
    "retina": "/images/hi-res/common/shopType/pair/amethyst@2x.png",
    "normal": "/images/normal/common/shopType/pair/amethyst.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/amethyst@2x.webp",
    "webp": "/images/normal-webp/common/shopType/pair/amethyst.webp"
  },
  "common/shopType/pair/ametrine@2x.png": {
    "retina": "/images/hi-res/common/shopType/pair/ametrine@2x.png",
    "normal": "/images/normal/common/shopType/pair/ametrine.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/ametrine@2x.webp",
    "webp": "/images/normal-webp/common/shopType/pair/ametrine.webp"
  },
  "common/shopType/pair/andalusite@2x.png": {
    "retina": "/images/hi-res/common/shopType/pair/andalusite@2x.png",
    "normal": "/images/normal/common/shopType/pair/andalusite.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/andalusite@2x.webp",
    "webp": "/images/normal-webp/common/shopType/pair/andalusite.webp"
  },
  "common/shopType/pair/andesine@2x.png": {
    "retina": "/images/hi-res/common/shopType/pair/andesine@2x.png",
    "normal": "/images/normal/common/shopType/pair/andesine.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/andesine@2x.webp",
    "webp": "/images/normal-webp/common/shopType/pair/andesine.webp"
  },
  "common/shopType/pair/andradite-garnet@2x.png": {
    "retina": "/images/hi-res/common/shopType/pair/andradite-garnet@2x.png",
    "normal": "/images/normal/common/shopType/pair/andradite-garnet.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/andradite-garnet@2x.webp",
    "webp": "/images/normal-webp/common/shopType/pair/andradite-garnet.webp"
  },
  "common/shopType/pair/apatite@2x copy.png": {
    "retina": "/images/hi-res/common/shopType/pair/apatite@2x copy.png",
    "normal": "/images/normal/common/shopType/pair/apatite copy.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/apatite@2x copy.webp",
    "webp": "/images/normal-webp/common/shopType/pair/apatite copy.webp"
  },
  "common/shopType/pair/apatite@2x.png": {
    "retina": "/images/hi-res/common/shopType/pair/apatite@2x.png",
    "normal": "/images/normal/common/shopType/pair/apatite.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/apatite@2x.webp",
    "webp": "/images/normal-webp/common/shopType/pair/apatite.webp"
  },
  "common/shopType/pair/aquamarine@2x.png": {
    "retina": "/images/hi-res/common/shopType/pair/aquamarine@2x.png",
    "normal": "/images/normal/common/shopType/pair/aquamarine.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/aquamarine@2x.webp",
    "webp": "/images/normal-webp/common/shopType/pair/aquamarine.webp"
  },
  "common/shopType/pair/beryl@2x.png": {
    "retina": "/images/hi-res/common/shopType/pair/beryl@2x.png",
    "normal": "/images/normal/common/shopType/pair/beryl.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/beryl@2x.webp",
    "webp": "/images/normal-webp/common/shopType/pair/beryl.webp"
  },
  "common/shopType/pair/carnelian@2x copy.png": {
    "retina": "/images/hi-res/common/shopType/pair/carnelian@2x copy.png",
    "normal": "/images/normal/common/shopType/pair/carnelian copy.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/carnelian@2x copy.webp",
    "webp": "/images/normal-webp/common/shopType/pair/carnelian copy.webp"
  },
  "common/shopType/pair/carnelian@2x.png": {
    "retina": "/images/hi-res/common/shopType/pair/carnelian@2x.png",
    "normal": "/images/normal/common/shopType/pair/carnelian.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/carnelian@2x.webp",
    "webp": "/images/normal-webp/common/shopType/pair/carnelian.webp"
  },
  "common/shopType/pair/chrome-diopside@2x.png": {
    "retina": "/images/hi-res/common/shopType/pair/chrome-diopside@2x.png",
    "normal": "/images/normal/common/shopType/pair/chrome-diopside.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/chrome-diopside@2x.webp",
    "webp": "/images/normal-webp/common/shopType/pair/chrome-diopside.webp"
  },
  "common/shopType/pair/chrome-tourmaline@2x.png": {
    "retina": "/images/hi-res/common/shopType/pair/chrome-tourmaline@2x.png",
    "normal": "/images/normal/common/shopType/pair/chrome-tourmaline.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/chrome-tourmaline@2x.webp",
    "webp": "/images/normal-webp/common/shopType/pair/chrome-tourmaline.webp"
  },
  "common/shopType/pair/chrysoberyl@2x.png": {
    "retina": "/images/hi-res/common/shopType/pair/chrysoberyl@2x.png",
    "normal": "/images/normal/common/shopType/pair/chrysoberyl.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/chrysoberyl@2x.webp",
    "webp": "/images/normal-webp/common/shopType/pair/chrysoberyl.webp"
  },
  "common/shopType/pair/citrine@2x.png": {
    "retina": "/images/hi-res/common/shopType/pair/citrine@2x.png",
    "normal": "/images/normal/common/shopType/pair/citrine.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/citrine@2x.webp",
    "webp": "/images/normal-webp/common/shopType/pair/citrine.webp"
  },
  "common/shopType/pair/cobalt-spinel@2x.png": {
    "retina": "/images/hi-res/common/shopType/pair/cobalt-spinel@2x.png",
    "normal": "/images/normal/common/shopType/pair/cobalt-spinel.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/cobalt-spinel@2x.webp",
    "webp": "/images/normal-webp/common/shopType/pair/cobalt-spinel.webp"
  },
  "common/shopType/pair/coral@2x.png": {
    "retina": "/images/hi-res/common/shopType/pair/coral@2x.png",
    "normal": "/images/normal/common/shopType/pair/coral.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/coral@2x.webp",
    "webp": "/images/normal-webp/common/shopType/pair/coral.webp"
  },
  "common/shopType/pair/demantoid-garnet@2x.png": {
    "retina": "/images/hi-res/common/shopType/pair/demantoid-garnet@2x.png",
    "normal": "/images/normal/common/shopType/pair/demantoid-garnet.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/demantoid-garnet@2x.webp",
    "webp": "/images/normal-webp/common/shopType/pair/demantoid-garnet.webp"
  },
  "common/shopType/pair/fluorite@2x copy.png": {
    "retina": "/images/hi-res/common/shopType/pair/fluorite@2x copy.png",
    "normal": "/images/normal/common/shopType/pair/fluorite copy.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/fluorite@2x copy.webp",
    "webp": "/images/normal-webp/common/shopType/pair/fluorite copy.webp"
  },
  "common/shopType/pair/fluorite@2x.png": {
    "retina": "/images/hi-res/common/shopType/pair/fluorite@2x.png",
    "normal": "/images/normal/common/shopType/pair/fluorite.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/fluorite@2x.webp",
    "webp": "/images/normal-webp/common/shopType/pair/fluorite.webp"
  },
  "common/shopType/pair/garnet@2x.png": {
    "retina": "/images/hi-res/common/shopType/pair/garnet@2x.png",
    "normal": "/images/normal/common/shopType/pair/garnet.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/garnet@2x.webp",
    "webp": "/images/normal-webp/common/shopType/pair/garnet.webp"
  },
  "common/shopType/pair/grossular-garnet@2x.png": {
    "retina": "/images/hi-res/common/shopType/pair/grossular-garnet@2x.png",
    "normal": "/images/normal/common/shopType/pair/grossular-garnet.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/grossular-garnet@2x.webp",
    "webp": "/images/normal-webp/common/shopType/pair/grossular-garnet.webp"
  },
  "common/shopType/pair/hessonite-garnet@2x.png": {
    "retina": "/images/hi-res/common/shopType/pair/hessonite-garnet@2x.png",
    "normal": "/images/normal/common/shopType/pair/hessonite-garnet.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/hessonite-garnet@2x.webp",
    "webp": "/images/normal-webp/common/shopType/pair/hessonite-garnet.webp"
  },
  "common/shopType/pair/jade@2x.png": {
    "retina": "/images/hi-res/common/shopType/pair/jade@2x.png",
    "normal": "/images/normal/common/shopType/pair/jade.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/jade@2x.webp",
    "webp": "/images/normal-webp/common/shopType/pair/jade.webp"
  },
  "common/shopType/pair/jeremejevite@2x.png": {
    "retina": "/images/hi-res/common/shopType/pair/jeremejevite@2x.png",
    "normal": "/images/normal/common/shopType/pair/jeremejevite.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/jeremejevite@2x.webp",
    "webp": "/images/normal-webp/common/shopType/pair/jeremejevite.webp"
  },
  "common/shopType/pair/kornerupine@2x.png": {
    "retina": "/images/hi-res/common/shopType/pair/kornerupine@2x.png",
    "normal": "/images/normal/common/shopType/pair/kornerupine.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/kornerupine@2x.webp",
    "webp": "/images/normal-webp/common/shopType/pair/kornerupine.webp"
  },
  "common/shopType/pair/kunzite@2x.png": {
    "retina": "/images/hi-res/common/shopType/pair/kunzite@2x.png",
    "normal": "/images/normal/common/shopType/pair/kunzite.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/kunzite@2x.webp",
    "webp": "/images/normal-webp/common/shopType/pair/kunzite.webp"
  },
  "common/shopType/pair/kyanite@2x.png": {
    "retina": "/images/hi-res/common/shopType/pair/kyanite@2x.png",
    "normal": "/images/normal/common/shopType/pair/kyanite.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/kyanite@2x.webp",
    "webp": "/images/normal-webp/common/shopType/pair/kyanite.webp"
  },
  "common/shopType/pair/lapis@2x.png": {
    "retina": "/images/hi-res/common/shopType/pair/lapis@2x.png",
    "normal": "/images/normal/common/shopType/pair/lapis.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/lapis@2x.webp",
    "webp": "/images/normal-webp/common/shopType/pair/lapis.webp"
  },
  "common/shopType/pair/lolite@2x.png": {
    "retina": "/images/hi-res/common/shopType/pair/lolite@2x.png",
    "normal": "/images/normal/common/shopType/pair/lolite.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/lolite@2x.webp",
    "webp": "/images/normal-webp/common/shopType/pair/lolite.webp"
  },
  "common/shopType/pair/madeira-citrine@2x.png": {
    "retina": "/images/hi-res/common/shopType/pair/madeira-citrine@2x.png",
    "normal": "/images/normal/common/shopType/pair/madeira-citrine.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/madeira-citrine@2x.webp",
    "webp": "/images/normal-webp/common/shopType/pair/madeira-citrine.webp"
  },
  "common/shopType/pair/malachite@2x.png": {
    "retina": "/images/hi-res/common/shopType/pair/malachite@2x.png",
    "normal": "/images/normal/common/shopType/pair/malachite.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/malachite@2x.webp",
    "webp": "/images/normal-webp/common/shopType/pair/malachite.webp"
  },
  "common/shopType/pair/malaya-garnet@2x.png": {
    "retina": "/images/hi-res/common/shopType/pair/malaya-garnet@2x.png",
    "normal": "/images/normal/common/shopType/pair/malaya-garnet.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/malaya-garnet@2x.webp",
    "webp": "/images/normal-webp/common/shopType/pair/malaya-garnet.webp"
  },
  "common/shopType/pair/mali-garnet@2x.png": {
    "retina": "/images/hi-res/common/shopType/pair/mali-garnet@2x.png",
    "normal": "/images/normal/common/shopType/pair/mali-garnet.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/mali-garnet@2x.webp",
    "webp": "/images/normal-webp/common/shopType/pair/mali-garnet.webp"
  },
  "common/shopType/pair/moonstone@2x.png": {
    "retina": "/images/hi-res/common/shopType/pair/moonstone@2x.png",
    "normal": "/images/normal/common/shopType/pair/moonstone.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/moonstone@2x.webp",
    "webp": "/images/normal-webp/common/shopType/pair/moonstone.webp"
  },
  "common/shopType/pair/morganite@2x.png": {
    "retina": "/images/hi-res/common/shopType/pair/morganite@2x.png",
    "normal": "/images/normal/common/shopType/pair/morganite.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/morganite@2x.webp",
    "webp": "/images/normal-webp/common/shopType/pair/morganite.webp"
  },
  "common/shopType/pair/onyx@2x.png": {
    "retina": "/images/hi-res/common/shopType/pair/onyx@2x.png",
    "normal": "/images/normal/common/shopType/pair/onyx.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/onyx@2x.webp",
    "webp": "/images/normal-webp/common/shopType/pair/onyx.webp"
  },
  "common/shopType/pair/opal@2x.png": {
    "retina": "/images/hi-res/common/shopType/pair/opal@2x.png",
    "normal": "/images/normal/common/shopType/pair/opal.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/opal@2x.webp",
    "webp": "/images/normal-webp/common/shopType/pair/opal.webp"
  },
  "common/shopType/pair/paraiba-tourmaline@2x.png": {
    "retina": "/images/hi-res/common/shopType/pair/paraiba-tourmaline@2x.png",
    "normal": "/images/normal/common/shopType/pair/paraiba-tourmaline.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/paraiba-tourmaline@2x.webp",
    "webp": "/images/normal-webp/common/shopType/pair/paraiba-tourmaline.webp"
  },
  "common/shopType/pair/pearl@2x.png": {
    "retina": "/images/hi-res/common/shopType/pair/pearl@2x.png",
    "normal": "/images/normal/common/shopType/pair/pearl.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/pearl@2x.webp",
    "webp": "/images/normal-webp/common/shopType/pair/pearl.webp"
  },
  "common/shopType/pair/peridot@2x.png": {
    "retina": "/images/hi-res/common/shopType/pair/peridot@2x.png",
    "normal": "/images/normal/common/shopType/pair/peridot.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/peridot@2x.webp",
    "webp": "/images/normal-webp/common/shopType/pair/peridot.webp"
  },
  "common/shopType/pair/quartz@2x.png": {
    "retina": "/images/hi-res/common/shopType/pair/quartz@2x.png",
    "normal": "/images/normal/common/shopType/pair/quartz.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/quartz@2x.webp",
    "webp": "/images/normal-webp/common/shopType/pair/quartz.webp"
  },
  "common/shopType/pair/rhodochrosite@2x.png": {
    "retina": "/images/hi-res/common/shopType/pair/rhodochrosite@2x.png",
    "normal": "/images/normal/common/shopType/pair/rhodochrosite.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/rhodochrosite@2x.webp",
    "webp": "/images/normal-webp/common/shopType/pair/rhodochrosite.webp"
  },
  "common/shopType/pair/rhodolite-garnet@2x.png": {
    "retina": "/images/hi-res/common/shopType/pair/rhodolite-garnet@2x.png",
    "normal": "/images/normal/common/shopType/pair/rhodolite-garnet.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/rhodolite-garnet@2x.webp",
    "webp": "/images/normal-webp/common/shopType/pair/rhodolite-garnet.webp"
  },
  "common/shopType/pair/rose-quartz@2x.png": {
    "retina": "/images/hi-res/common/shopType/pair/rose-quartz@2x.png",
    "normal": "/images/normal/common/shopType/pair/rose-quartz.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/rose-quartz@2x.webp",
    "webp": "/images/normal-webp/common/shopType/pair/rose-quartz.webp"
  },
  "common/shopType/pair/rubellite-tourmaline@2x.png": {
    "retina": "/images/hi-res/common/shopType/pair/rubellite-tourmaline@2x.png",
    "normal": "/images/normal/common/shopType/pair/rubellite-tourmaline.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/rubellite-tourmaline@2x.webp",
    "webp": "/images/normal-webp/common/shopType/pair/rubellite-tourmaline.webp"
  },
  "common/shopType/pair/rutilated-quartz@2x.png": {
    "retina": "/images/hi-res/common/shopType/pair/rutilated-quartz@2x.png",
    "normal": "/images/normal/common/shopType/pair/rutilated-quartz.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/rutilated-quartz@2x.webp",
    "webp": "/images/normal-webp/common/shopType/pair/rutilated-quartz.webp"
  },
  "common/shopType/pair/scapolite@2x.png": {
    "retina": "/images/hi-res/common/shopType/pair/scapolite@2x.png",
    "normal": "/images/normal/common/shopType/pair/scapolite.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/scapolite@2x.webp",
    "webp": "/images/normal-webp/common/shopType/pair/scapolite.webp"
  },
  "common/shopType/pair/sinhalite@2x.png": {
    "retina": "/images/hi-res/common/shopType/pair/sinhalite@2x.png",
    "normal": "/images/normal/common/shopType/pair/sinhalite.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/sinhalite@2x.webp",
    "webp": "/images/normal-webp/common/shopType/pair/sinhalite.webp"
  },
  "common/shopType/pair/smokey-quartz@2x.png": {
    "retina": "/images/hi-res/common/shopType/pair/smokey-quartz@2x.png",
    "normal": "/images/normal/common/shopType/pair/smokey-quartz.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/smokey-quartz@2x.webp",
    "webp": "/images/normal-webp/common/shopType/pair/smokey-quartz.webp"
  },
  "common/shopType/pair/spessartite-garnet@2x.png": {
    "retina": "/images/hi-res/common/shopType/pair/spessartite-garnet@2x.png",
    "normal": "/images/normal/common/shopType/pair/spessartite-garnet.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/spessartite-garnet@2x.webp",
    "webp": "/images/normal-webp/common/shopType/pair/spessartite-garnet.webp"
  },
  "common/shopType/pair/sphene-titanite@2x.png": {
    "retina": "/images/hi-res/common/shopType/pair/sphene-titanite@2x.png",
    "normal": "/images/normal/common/shopType/pair/sphene-titanite.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/sphene-titanite@2x.webp",
    "webp": "/images/normal-webp/common/shopType/pair/sphene-titanite.webp"
  },
  "common/shopType/pair/spinel@2x.png": {
    "retina": "/images/hi-res/common/shopType/pair/spinel@2x.png",
    "normal": "/images/normal/common/shopType/pair/spinel.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/spinel@2x.webp",
    "webp": "/images/normal-webp/common/shopType/pair/spinel.webp"
  },
  "common/shopType/pair/taaffeite@2x.png": {
    "retina": "/images/hi-res/common/shopType/pair/taaffeite@2x.png",
    "normal": "/images/normal/common/shopType/pair/taaffeite.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/taaffeite@2x.webp",
    "webp": "/images/normal-webp/common/shopType/pair/taaffeite.webp"
  },
  "common/shopType/pair/tanzanite@2x.png": {
    "retina": "/images/hi-res/common/shopType/pair/tanzanite@2x.png",
    "normal": "/images/normal/common/shopType/pair/tanzanite.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/tanzanite@2x.webp",
    "webp": "/images/normal-webp/common/shopType/pair/tanzanite.webp"
  },
  "common/shopType/pair/tiger-eye@2x.png": {
    "retina": "/images/hi-res/common/shopType/pair/tiger-eye@2x.png",
    "normal": "/images/normal/common/shopType/pair/tiger-eye.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/tiger-eye@2x.webp",
    "webp": "/images/normal-webp/common/shopType/pair/tiger-eye.webp"
  },
  "common/shopType/pair/topaz@2x.png": {
    "retina": "/images/hi-res/common/shopType/pair/topaz@2x.png",
    "normal": "/images/normal/common/shopType/pair/topaz.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/topaz@2x.webp",
    "webp": "/images/normal-webp/common/shopType/pair/topaz.webp"
  },
  "common/shopType/pair/tourmaline@2x.png": {
    "retina": "/images/hi-res/common/shopType/pair/tourmaline@2x.png",
    "normal": "/images/normal/common/shopType/pair/tourmaline.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/tourmaline@2x.webp",
    "webp": "/images/normal-webp/common/shopType/pair/tourmaline.webp"
  },
  "common/shopType/pair/tsavorite-garnet@2x.png": {
    "retina": "/images/hi-res/common/shopType/pair/tsavorite-garnet@2x.png",
    "normal": "/images/normal/common/shopType/pair/tsavorite-garnet.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/tsavorite-garnet@2x.webp",
    "webp": "/images/normal-webp/common/shopType/pair/tsavorite-garnet.webp"
  },
  "common/shopType/pair/turquoise@2x.png": {
    "retina": "/images/hi-res/common/shopType/pair/turquoise@2x.png",
    "normal": "/images/normal/common/shopType/pair/turquoise.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/turquoise@2x.webp",
    "webp": "/images/normal-webp/common/shopType/pair/turquoise.webp"
  },
  "common/shopType/pair/zircon@2x.png": {
    "retina": "/images/hi-res/common/shopType/pair/zircon@2x.png",
    "normal": "/images/normal/common/shopType/pair/zircon.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/pair/zircon@2x.webp",
    "webp": "/images/normal-webp/common/shopType/pair/zircon.webp"
  },
  "common/shopType/ring/agate@2x.png": {
    "retina": "/images/hi-res/common/shopType/ring/agate@2x.png",
    "normal": "/images/normal/common/shopType/ring/agate.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/agate@2x.webp",
    "webp": "/images/normal-webp/common/shopType/ring/agate.webp"
  },
  "common/shopType/ring/alexandrite@2x.png": {
    "retina": "/images/hi-res/common/shopType/ring/alexandrite@2x.png",
    "normal": "/images/normal/common/shopType/ring/alexandrite.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/alexandrite@2x.webp",
    "webp": "/images/normal-webp/common/shopType/ring/alexandrite.webp"
  },
  "common/shopType/ring/almandine-garnet@2x.png": {
    "retina": "/images/hi-res/common/shopType/ring/almandine-garnet@2x.png",
    "normal": "/images/normal/common/shopType/ring/almandine-garnet.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/almandine-garnet@2x.webp",
    "webp": "/images/normal-webp/common/shopType/ring/almandine-garnet.webp"
  },
  "common/shopType/ring/amethyst@2x.png": {
    "retina": "/images/hi-res/common/shopType/ring/amethyst@2x.png",
    "normal": "/images/normal/common/shopType/ring/amethyst.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/amethyst@2x.webp",
    "webp": "/images/normal-webp/common/shopType/ring/amethyst.webp"
  },
  "common/shopType/ring/ametrine@2x.png": {
    "retina": "/images/hi-res/common/shopType/ring/ametrine@2x.png",
    "normal": "/images/normal/common/shopType/ring/ametrine.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/ametrine@2x.webp",
    "webp": "/images/normal-webp/common/shopType/ring/ametrine.webp"
  },
  "common/shopType/ring/andalusite@2x.png": {
    "retina": "/images/hi-res/common/shopType/ring/andalusite@2x.png",
    "normal": "/images/normal/common/shopType/ring/andalusite.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/andalusite@2x.webp",
    "webp": "/images/normal-webp/common/shopType/ring/andalusite.webp"
  },
  "common/shopType/ring/andesine@2x.png": {
    "retina": "/images/hi-res/common/shopType/ring/andesine@2x.png",
    "normal": "/images/normal/common/shopType/ring/andesine.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/andesine@2x.webp",
    "webp": "/images/normal-webp/common/shopType/ring/andesine.webp"
  },
  "common/shopType/ring/andradite-garnet@2x.png": {
    "retina": "/images/hi-res/common/shopType/ring/andradite-garnet@2x.png",
    "normal": "/images/normal/common/shopType/ring/andradite-garnet.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/andradite-garnet@2x.webp",
    "webp": "/images/normal-webp/common/shopType/ring/andradite-garnet.webp"
  },
  "common/shopType/ring/apatite@2x copy.png": {
    "retina": "/images/hi-res/common/shopType/ring/apatite@2x copy.png",
    "normal": "/images/normal/common/shopType/ring/apatite copy.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/apatite@2x copy.webp",
    "webp": "/images/normal-webp/common/shopType/ring/apatite copy.webp"
  },
  "common/shopType/ring/apatite@2x.png": {
    "retina": "/images/hi-res/common/shopType/ring/apatite@2x.png",
    "normal": "/images/normal/common/shopType/ring/apatite.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/apatite@2x.webp",
    "webp": "/images/normal-webp/common/shopType/ring/apatite.webp"
  },
  "common/shopType/ring/aquamarine@2x.png": {
    "retina": "/images/hi-res/common/shopType/ring/aquamarine@2x.png",
    "normal": "/images/normal/common/shopType/ring/aquamarine.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/aquamarine@2x.webp",
    "webp": "/images/normal-webp/common/shopType/ring/aquamarine.webp"
  },
  "common/shopType/ring/beryl@2x.png": {
    "retina": "/images/hi-res/common/shopType/ring/beryl@2x.png",
    "normal": "/images/normal/common/shopType/ring/beryl.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/beryl@2x.webp",
    "webp": "/images/normal-webp/common/shopType/ring/beryl.webp"
  },
  "common/shopType/ring/carnelian@2x copy.png": {
    "retina": "/images/hi-res/common/shopType/ring/carnelian@2x copy.png",
    "normal": "/images/normal/common/shopType/ring/carnelian copy.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/carnelian@2x copy.webp",
    "webp": "/images/normal-webp/common/shopType/ring/carnelian copy.webp"
  },
  "common/shopType/ring/carnelian@2x.png": {
    "retina": "/images/hi-res/common/shopType/ring/carnelian@2x.png",
    "normal": "/images/normal/common/shopType/ring/carnelian.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/carnelian@2x.webp",
    "webp": "/images/normal-webp/common/shopType/ring/carnelian.webp"
  },
  "common/shopType/ring/chrome-diopside@2x.png": {
    "retina": "/images/hi-res/common/shopType/ring/chrome-diopside@2x.png",
    "normal": "/images/normal/common/shopType/ring/chrome-diopside.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/chrome-diopside@2x.webp",
    "webp": "/images/normal-webp/common/shopType/ring/chrome-diopside.webp"
  },
  "common/shopType/ring/chrome-tourmaline@2x.png": {
    "retina": "/images/hi-res/common/shopType/ring/chrome-tourmaline@2x.png",
    "normal": "/images/normal/common/shopType/ring/chrome-tourmaline.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/chrome-tourmaline@2x.webp",
    "webp": "/images/normal-webp/common/shopType/ring/chrome-tourmaline.webp"
  },
  "common/shopType/ring/chrysoberyl@2x.png": {
    "retina": "/images/hi-res/common/shopType/ring/chrysoberyl@2x.png",
    "normal": "/images/normal/common/shopType/ring/chrysoberyl.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/chrysoberyl@2x.webp",
    "webp": "/images/normal-webp/common/shopType/ring/chrysoberyl.webp"
  },
  "common/shopType/ring/citrine@2x.png": {
    "retina": "/images/hi-res/common/shopType/ring/citrine@2x.png",
    "normal": "/images/normal/common/shopType/ring/citrine.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/citrine@2x.webp",
    "webp": "/images/normal-webp/common/shopType/ring/citrine.webp"
  },
  "common/shopType/ring/cobalt-spinel@2x.png": {
    "retina": "/images/hi-res/common/shopType/ring/cobalt-spinel@2x.png",
    "normal": "/images/normal/common/shopType/ring/cobalt-spinel.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/cobalt-spinel@2x.webp",
    "webp": "/images/normal-webp/common/shopType/ring/cobalt-spinel.webp"
  },
  "common/shopType/ring/coral@2x.png": {
    "retina": "/images/hi-res/common/shopType/ring/coral@2x.png",
    "normal": "/images/normal/common/shopType/ring/coral.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/coral@2x.webp",
    "webp": "/images/normal-webp/common/shopType/ring/coral.webp"
  },
  "common/shopType/ring/demantoid-garnet@2x.png": {
    "retina": "/images/hi-res/common/shopType/ring/demantoid-garnet@2x.png",
    "normal": "/images/normal/common/shopType/ring/demantoid-garnet.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/demantoid-garnet@2x.webp",
    "webp": "/images/normal-webp/common/shopType/ring/demantoid-garnet.webp"
  },
  "common/shopType/ring/fluorite@2x copy.png": {
    "retina": "/images/hi-res/common/shopType/ring/fluorite@2x copy.png",
    "normal": "/images/normal/common/shopType/ring/fluorite copy.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/fluorite@2x copy.webp",
    "webp": "/images/normal-webp/common/shopType/ring/fluorite copy.webp"
  },
  "common/shopType/ring/fluorite@2x.png": {
    "retina": "/images/hi-res/common/shopType/ring/fluorite@2x.png",
    "normal": "/images/normal/common/shopType/ring/fluorite.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/fluorite@2x.webp",
    "webp": "/images/normal-webp/common/shopType/ring/fluorite.webp"
  },
  "common/shopType/ring/garnet@2x.png": {
    "retina": "/images/hi-res/common/shopType/ring/garnet@2x.png",
    "normal": "/images/normal/common/shopType/ring/garnet.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/garnet@2x.webp",
    "webp": "/images/normal-webp/common/shopType/ring/garnet.webp"
  },
  "common/shopType/ring/grossular-garnet@2x.png": {
    "retina": "/images/hi-res/common/shopType/ring/grossular-garnet@2x.png",
    "normal": "/images/normal/common/shopType/ring/grossular-garnet.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/grossular-garnet@2x.webp",
    "webp": "/images/normal-webp/common/shopType/ring/grossular-garnet.webp"
  },
  "common/shopType/ring/hessonite-garnet@2x.png": {
    "retina": "/images/hi-res/common/shopType/ring/hessonite-garnet@2x.png",
    "normal": "/images/normal/common/shopType/ring/hessonite-garnet.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/hessonite-garnet@2x.webp",
    "webp": "/images/normal-webp/common/shopType/ring/hessonite-garnet.webp"
  },
  "common/shopType/ring/iolite@2x.png": {
    "retina": "/images/hi-res/common/shopType/ring/iolite@2x.png",
    "normal": "/images/normal/common/shopType/ring/iolite.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/iolite@2x.webp",
    "webp": "/images/normal-webp/common/shopType/ring/iolite.webp"
  },
  "common/shopType/ring/jade@2x.png": {
    "retina": "/images/hi-res/common/shopType/ring/jade@2x.png",
    "normal": "/images/normal/common/shopType/ring/jade.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/jade@2x.webp",
    "webp": "/images/normal-webp/common/shopType/ring/jade.webp"
  },
  "common/shopType/ring/jeremejevite@2x.png": {
    "retina": "/images/hi-res/common/shopType/ring/jeremejevite@2x.png",
    "normal": "/images/normal/common/shopType/ring/jeremejevite.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/jeremejevite@2x.webp",
    "webp": "/images/normal-webp/common/shopType/ring/jeremejevite.webp"
  },
  "common/shopType/ring/kornerupine@2x.png": {
    "retina": "/images/hi-res/common/shopType/ring/kornerupine@2x.png",
    "normal": "/images/normal/common/shopType/ring/kornerupine.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/kornerupine@2x.webp",
    "webp": "/images/normal-webp/common/shopType/ring/kornerupine.webp"
  },
  "common/shopType/ring/kunzite@2x.png": {
    "retina": "/images/hi-res/common/shopType/ring/kunzite@2x.png",
    "normal": "/images/normal/common/shopType/ring/kunzite.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/kunzite@2x.webp",
    "webp": "/images/normal-webp/common/shopType/ring/kunzite.webp"
  },
  "common/shopType/ring/kyanite@2x.png": {
    "retina": "/images/hi-res/common/shopType/ring/kyanite@2x.png",
    "normal": "/images/normal/common/shopType/ring/kyanite.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/kyanite@2x.webp",
    "webp": "/images/normal-webp/common/shopType/ring/kyanite.webp"
  },
  "common/shopType/ring/lapis@2x.png": {
    "retina": "/images/hi-res/common/shopType/ring/lapis@2x.png",
    "normal": "/images/normal/common/shopType/ring/lapis.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/lapis@2x.webp",
    "webp": "/images/normal-webp/common/shopType/ring/lapis.webp"
  },
  "common/shopType/ring/madeira-citrine@2x.png": {
    "retina": "/images/hi-res/common/shopType/ring/madeira-citrine@2x.png",
    "normal": "/images/normal/common/shopType/ring/madeira-citrine.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/madeira-citrine@2x.webp",
    "webp": "/images/normal-webp/common/shopType/ring/madeira-citrine.webp"
  },
  "common/shopType/ring/malachite@2x.png": {
    "retina": "/images/hi-res/common/shopType/ring/malachite@2x.png",
    "normal": "/images/normal/common/shopType/ring/malachite.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/malachite@2x.webp",
    "webp": "/images/normal-webp/common/shopType/ring/malachite.webp"
  },
  "common/shopType/ring/malaya-garnet@2x.png": {
    "retina": "/images/hi-res/common/shopType/ring/malaya-garnet@2x.png",
    "normal": "/images/normal/common/shopType/ring/malaya-garnet.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/malaya-garnet@2x.webp",
    "webp": "/images/normal-webp/common/shopType/ring/malaya-garnet.webp"
  },
  "common/shopType/ring/mali-garnet@2x.png": {
    "retina": "/images/hi-res/common/shopType/ring/mali-garnet@2x.png",
    "normal": "/images/normal/common/shopType/ring/mali-garnet.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/mali-garnet@2x.webp",
    "webp": "/images/normal-webp/common/shopType/ring/mali-garnet.webp"
  },
  "common/shopType/ring/moonstone@2x.png": {
    "retina": "/images/hi-res/common/shopType/ring/moonstone@2x.png",
    "normal": "/images/normal/common/shopType/ring/moonstone.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/moonstone@2x.webp",
    "webp": "/images/normal-webp/common/shopType/ring/moonstone.webp"
  },
  "common/shopType/ring/morganite@2x.png": {
    "retina": "/images/hi-res/common/shopType/ring/morganite@2x.png",
    "normal": "/images/normal/common/shopType/ring/morganite.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/morganite@2x.webp",
    "webp": "/images/normal-webp/common/shopType/ring/morganite.webp"
  },
  "common/shopType/ring/onyx@2x.png": {
    "retina": "/images/hi-res/common/shopType/ring/onyx@2x.png",
    "normal": "/images/normal/common/shopType/ring/onyx.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/onyx@2x.webp",
    "webp": "/images/normal-webp/common/shopType/ring/onyx.webp"
  },
  "common/shopType/ring/opal@2x.png": {
    "retina": "/images/hi-res/common/shopType/ring/opal@2x.png",
    "normal": "/images/normal/common/shopType/ring/opal.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/opal@2x.webp",
    "webp": "/images/normal-webp/common/shopType/ring/opal.webp"
  },
  "common/shopType/ring/paraiba-tourmaline@2x.png": {
    "retina": "/images/hi-res/common/shopType/ring/paraiba-tourmaline@2x.png",
    "normal": "/images/normal/common/shopType/ring/paraiba-tourmaline.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/paraiba-tourmaline@2x.webp",
    "webp": "/images/normal-webp/common/shopType/ring/paraiba-tourmaline.webp"
  },
  "common/shopType/ring/pearl@2x.png": {
    "retina": "/images/hi-res/common/shopType/ring/pearl@2x.png",
    "normal": "/images/normal/common/shopType/ring/pearl.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/pearl@2x.webp",
    "webp": "/images/normal-webp/common/shopType/ring/pearl.webp"
  },
  "common/shopType/ring/peridot@2x.png": {
    "retina": "/images/hi-res/common/shopType/ring/peridot@2x.png",
    "normal": "/images/normal/common/shopType/ring/peridot.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/peridot@2x.webp",
    "webp": "/images/normal-webp/common/shopType/ring/peridot.webp"
  },
  "common/shopType/ring/phenakite@2x.png": {
    "retina": "/images/hi-res/common/shopType/ring/phenakite@2x.png",
    "normal": "/images/normal/common/shopType/ring/phenakite.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/phenakite@2x.webp",
    "webp": "/images/normal-webp/common/shopType/ring/phenakite.webp"
  },
  "common/shopType/ring/quartz@2x.png": {
    "retina": "/images/hi-res/common/shopType/ring/quartz@2x.png",
    "normal": "/images/normal/common/shopType/ring/quartz.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/quartz@2x.webp",
    "webp": "/images/normal-webp/common/shopType/ring/quartz.webp"
  },
  "common/shopType/ring/rhodochrosite@2x.png": {
    "retina": "/images/hi-res/common/shopType/ring/rhodochrosite@2x.png",
    "normal": "/images/normal/common/shopType/ring/rhodochrosite.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/rhodochrosite@2x.webp",
    "webp": "/images/normal-webp/common/shopType/ring/rhodochrosite.webp"
  },
  "common/shopType/ring/rhodolite-garnet@2x.png": {
    "retina": "/images/hi-res/common/shopType/ring/rhodolite-garnet@2x.png",
    "normal": "/images/normal/common/shopType/ring/rhodolite-garnet.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/rhodolite-garnet@2x.webp",
    "webp": "/images/normal-webp/common/shopType/ring/rhodolite-garnet.webp"
  },
  "common/shopType/ring/rose-quartz@2x.png": {
    "retina": "/images/hi-res/common/shopType/ring/rose-quartz@2x.png",
    "normal": "/images/normal/common/shopType/ring/rose-quartz.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/rose-quartz@2x.webp",
    "webp": "/images/normal-webp/common/shopType/ring/rose-quartz.webp"
  },
  "common/shopType/ring/rubellite-tourmaline@2x.png": {
    "retina": "/images/hi-res/common/shopType/ring/rubellite-tourmaline@2x.png",
    "normal": "/images/normal/common/shopType/ring/rubellite-tourmaline.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/rubellite-tourmaline@2x.webp",
    "webp": "/images/normal-webp/common/shopType/ring/rubellite-tourmaline.webp"
  },
  "common/shopType/ring/rutilated-quartz@2x.png": {
    "retina": "/images/hi-res/common/shopType/ring/rutilated-quartz@2x.png",
    "normal": "/images/normal/common/shopType/ring/rutilated-quartz.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/rutilated-quartz@2x.webp",
    "webp": "/images/normal-webp/common/shopType/ring/rutilated-quartz.webp"
  },
  "common/shopType/ring/scapolite@2x.png": {
    "retina": "/images/hi-res/common/shopType/ring/scapolite@2x.png",
    "normal": "/images/normal/common/shopType/ring/scapolite.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/scapolite@2x.webp",
    "webp": "/images/normal-webp/common/shopType/ring/scapolite.webp"
  },
  "common/shopType/ring/sinhalite@2x.png": {
    "retina": "/images/hi-res/common/shopType/ring/sinhalite@2x.png",
    "normal": "/images/normal/common/shopType/ring/sinhalite.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/sinhalite@2x.webp",
    "webp": "/images/normal-webp/common/shopType/ring/sinhalite.webp"
  },
  "common/shopType/ring/smokey-quartz@2x.png": {
    "retina": "/images/hi-res/common/shopType/ring/smokey-quartz@2x.png",
    "normal": "/images/normal/common/shopType/ring/smokey-quartz.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/smokey-quartz@2x.webp",
    "webp": "/images/normal-webp/common/shopType/ring/smokey-quartz.webp"
  },
  "common/shopType/ring/spessartite-garnet@2x.png": {
    "retina": "/images/hi-res/common/shopType/ring/spessartite-garnet@2x.png",
    "normal": "/images/normal/common/shopType/ring/spessartite-garnet.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/spessartite-garnet@2x.webp",
    "webp": "/images/normal-webp/common/shopType/ring/spessartite-garnet.webp"
  },
  "common/shopType/ring/sphene-titanite@2x.png": {
    "retina": "/images/hi-res/common/shopType/ring/sphene-titanite@2x.png",
    "normal": "/images/normal/common/shopType/ring/sphene-titanite.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/sphene-titanite@2x.webp",
    "webp": "/images/normal-webp/common/shopType/ring/sphene-titanite.webp"
  },
  "common/shopType/ring/spinel@2x.png": {
    "retina": "/images/hi-res/common/shopType/ring/spinel@2x.png",
    "normal": "/images/normal/common/shopType/ring/spinel.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/spinel@2x.webp",
    "webp": "/images/normal-webp/common/shopType/ring/spinel.webp"
  },
  "common/shopType/ring/taaffeite@2x.png": {
    "retina": "/images/hi-res/common/shopType/ring/taaffeite@2x.png",
    "normal": "/images/normal/common/shopType/ring/taaffeite.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/taaffeite@2x.webp",
    "webp": "/images/normal-webp/common/shopType/ring/taaffeite.webp"
  },
  "common/shopType/ring/tanzanite@2x.png": {
    "retina": "/images/hi-res/common/shopType/ring/tanzanite@2x.png",
    "normal": "/images/normal/common/shopType/ring/tanzanite.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/tanzanite@2x.webp",
    "webp": "/images/normal-webp/common/shopType/ring/tanzanite.webp"
  },
  "common/shopType/ring/tiger-eye@2x.png": {
    "retina": "/images/hi-res/common/shopType/ring/tiger-eye@2x.png",
    "normal": "/images/normal/common/shopType/ring/tiger-eye.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/tiger-eye@2x.webp",
    "webp": "/images/normal-webp/common/shopType/ring/tiger-eye.webp"
  },
  "common/shopType/ring/topaz@2x.png": {
    "retina": "/images/hi-res/common/shopType/ring/topaz@2x.png",
    "normal": "/images/normal/common/shopType/ring/topaz.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/topaz@2x.webp",
    "webp": "/images/normal-webp/common/shopType/ring/topaz.webp"
  },
  "common/shopType/ring/tourmaline@2x.png": {
    "retina": "/images/hi-res/common/shopType/ring/tourmaline@2x.png",
    "normal": "/images/normal/common/shopType/ring/tourmaline.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/tourmaline@2x.webp",
    "webp": "/images/normal-webp/common/shopType/ring/tourmaline.webp"
  },
  "common/shopType/ring/tsavorite-garnet@2x.png": {
    "retina": "/images/hi-res/common/shopType/ring/tsavorite-garnet@2x.png",
    "normal": "/images/normal/common/shopType/ring/tsavorite-garnet.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/tsavorite-garnet@2x.webp",
    "webp": "/images/normal-webp/common/shopType/ring/tsavorite-garnet.webp"
  },
  "common/shopType/ring/turquoise@2x.png": {
    "retina": "/images/hi-res/common/shopType/ring/turquoise@2x.png",
    "normal": "/images/normal/common/shopType/ring/turquoise.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/turquoise@2x.webp",
    "webp": "/images/normal-webp/common/shopType/ring/turquoise.webp"
  },
  "common/shopType/ring/zircon@2x.png": {
    "retina": "/images/hi-res/common/shopType/ring/zircon@2x.png",
    "normal": "/images/normal/common/shopType/ring/zircon.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/ring/zircon@2x.webp",
    "webp": "/images/normal-webp/common/shopType/ring/zircon.webp"
  },
  "common/shopType/stone/agate@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/agate@2x.png",
    "normal": "/images/normal/common/shopType/stone/agate.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/agate@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/agate.webp"
  },
  "common/shopType/stone/alexandrite@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/alexandrite@2x.png",
    "normal": "/images/normal/common/shopType/stone/alexandrite.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/alexandrite@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/alexandrite.webp"
  },
  "common/shopType/stone/almandine-garnet@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/almandine-garnet@2x.png",
    "normal": "/images/normal/common/shopType/stone/almandine-garnet.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/almandine-garnet@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/almandine-garnet.webp"
  },
  "common/shopType/stone/amethyst@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/amethyst@2x.png",
    "normal": "/images/normal/common/shopType/stone/amethyst.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/amethyst@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/amethyst.webp"
  },
  "common/shopType/stone/ametrine@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/ametrine@2x.png",
    "normal": "/images/normal/common/shopType/stone/ametrine.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/ametrine@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/ametrine.webp"
  },
  "common/shopType/stone/andalusite@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/andalusite@2x.png",
    "normal": "/images/normal/common/shopType/stone/andalusite.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/andalusite@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/andalusite.webp"
  },
  "common/shopType/stone/andesine@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/andesine@2x.png",
    "normal": "/images/normal/common/shopType/stone/andesine.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/andesine@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/andesine.webp"
  },
  "common/shopType/stone/andradite-garnet@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/andradite-garnet@2x.png",
    "normal": "/images/normal/common/shopType/stone/andradite-garnet.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/andradite-garnet@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/andradite-garnet.webp"
  },
  "common/shopType/stone/apatite@2x copy.png": {
    "retina": "/images/hi-res/common/shopType/stone/apatite@2x copy.png",
    "normal": "/images/normal/common/shopType/stone/apatite copy.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/apatite@2x copy.webp",
    "webp": "/images/normal-webp/common/shopType/stone/apatite copy.webp"
  },
  "common/shopType/stone/apatite@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/apatite@2x.png",
    "normal": "/images/normal/common/shopType/stone/apatite.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/apatite@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/apatite.webp"
  },
  "common/shopType/stone/aquamarine@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/aquamarine@2x.png",
    "normal": "/images/normal/common/shopType/stone/aquamarine.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/aquamarine@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/aquamarine.webp"
  },
  "common/shopType/stone/beryl@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/beryl@2x.png",
    "normal": "/images/normal/common/shopType/stone/beryl.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/beryl@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/beryl.webp"
  },
  "common/shopType/stone/carnelian@2x copy.png": {
    "retina": "/images/hi-res/common/shopType/stone/carnelian@2x copy.png",
    "normal": "/images/normal/common/shopType/stone/carnelian copy.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/carnelian@2x copy.webp",
    "webp": "/images/normal-webp/common/shopType/stone/carnelian copy.webp"
  },
  "common/shopType/stone/carnelian@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/carnelian@2x.png",
    "normal": "/images/normal/common/shopType/stone/carnelian.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/carnelian@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/carnelian.webp"
  },
  "common/shopType/stone/cats-eye-chrysoberyl@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/cats-eye-chrysoberyl@2x.png",
    "normal": "/images/normal/common/shopType/stone/cats-eye-chrysoberyl.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/cats-eye-chrysoberyl@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/cats-eye-chrysoberyl.webp"
  },
  "common/shopType/stone/chrome-diopside@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/chrome-diopside@2x.png",
    "normal": "/images/normal/common/shopType/stone/chrome-diopside.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/chrome-diopside@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/chrome-diopside.webp"
  },
  "common/shopType/stone/chrome-tourmaline@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/chrome-tourmaline@2x.png",
    "normal": "/images/normal/common/shopType/stone/chrome-tourmaline.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/chrome-tourmaline@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/chrome-tourmaline.webp"
  },
  "common/shopType/stone/chrysoberyl@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/chrysoberyl@2x.png",
    "normal": "/images/normal/common/shopType/stone/chrysoberyl.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/chrysoberyl@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/chrysoberyl.webp"
  },
  "common/shopType/stone/citrine@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/citrine@2x.png",
    "normal": "/images/normal/common/shopType/stone/citrine.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/citrine@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/citrine.webp"
  },
  "common/shopType/stone/cobalt-spinel@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/cobalt-spinel@2x.png",
    "normal": "/images/normal/common/shopType/stone/cobalt-spinel.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/cobalt-spinel@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/cobalt-spinel.webp"
  },
  "common/shopType/stone/coral@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/coral@2x.png",
    "normal": "/images/normal/common/shopType/stone/coral.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/coral@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/coral.webp"
  },
  "common/shopType/stone/demantoid-garnet@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/demantoid-garnet@2x.png",
    "normal": "/images/normal/common/shopType/stone/demantoid-garnet.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/demantoid-garnet@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/demantoid-garnet.webp"
  },
  "common/shopType/stone/fluorite@2x copy.png": {
    "retina": "/images/hi-res/common/shopType/stone/fluorite@2x copy.png",
    "normal": "/images/normal/common/shopType/stone/fluorite copy.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/fluorite@2x copy.webp",
    "webp": "/images/normal-webp/common/shopType/stone/fluorite copy.webp"
  },
  "common/shopType/stone/fluorite@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/fluorite@2x.png",
    "normal": "/images/normal/common/shopType/stone/fluorite.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/fluorite@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/fluorite.webp"
  },
  "common/shopType/stone/garnet@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/garnet@2x.png",
    "normal": "/images/normal/common/shopType/stone/garnet.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/garnet@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/garnet.webp"
  },
  "common/shopType/stone/grossular-garnet@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/grossular-garnet@2x.png",
    "normal": "/images/normal/common/shopType/stone/grossular-garnet.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/grossular-garnet@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/grossular-garnet.webp"
  },
  "common/shopType/stone/hessonite-garnet@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/hessonite-garnet@2x.png",
    "normal": "/images/normal/common/shopType/stone/hessonite-garnet.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/hessonite-garnet@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/hessonite-garnet.webp"
  },
  "common/shopType/stone/iolite@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/iolite@2x.png",
    "normal": "/images/normal/common/shopType/stone/iolite.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/iolite@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/iolite.webp"
  },
  "common/shopType/stone/jade@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/jade@2x.png",
    "normal": "/images/normal/common/shopType/stone/jade.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/jade@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/jade.webp"
  },
  "common/shopType/stone/jeremejevite@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/jeremejevite@2x.png",
    "normal": "/images/normal/common/shopType/stone/jeremejevite.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/jeremejevite@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/jeremejevite.webp"
  },
  "common/shopType/stone/kornerupine@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/kornerupine@2x.png",
    "normal": "/images/normal/common/shopType/stone/kornerupine.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/kornerupine@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/kornerupine.webp"
  },
  "common/shopType/stone/kunzite@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/kunzite@2x.png",
    "normal": "/images/normal/common/shopType/stone/kunzite.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/kunzite@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/kunzite.webp"
  },
  "common/shopType/stone/kyanite@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/kyanite@2x.png",
    "normal": "/images/normal/common/shopType/stone/kyanite.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/kyanite@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/kyanite.webp"
  },
  "common/shopType/stone/lapis@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/lapis@2x.png",
    "normal": "/images/normal/common/shopType/stone/lapis.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/lapis@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/lapis.webp"
  },
  "common/shopType/stone/madeira-citrine@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/madeira-citrine@2x.png",
    "normal": "/images/normal/common/shopType/stone/madeira-citrine.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/madeira-citrine@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/madeira-citrine.webp"
  },
  "common/shopType/stone/malachite@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/malachite@2x.png",
    "normal": "/images/normal/common/shopType/stone/malachite.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/malachite@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/malachite.webp"
  },
  "common/shopType/stone/malaya-garnet@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/malaya-garnet@2x.png",
    "normal": "/images/normal/common/shopType/stone/malaya-garnet.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/malaya-garnet@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/malaya-garnet.webp"
  },
  "common/shopType/stone/mali-garnet@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/mali-garnet@2x.png",
    "normal": "/images/normal/common/shopType/stone/mali-garnet.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/mali-garnet@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/mali-garnet.webp"
  },
  "common/shopType/stone/moonstone@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/moonstone@2x.png",
    "normal": "/images/normal/common/shopType/stone/moonstone.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/moonstone@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/moonstone.webp"
  },
  "common/shopType/stone/morganite@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/morganite@2x.png",
    "normal": "/images/normal/common/shopType/stone/morganite.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/morganite@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/morganite.webp"
  },
  "common/shopType/stone/onyx@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/onyx@2x.png",
    "normal": "/images/normal/common/shopType/stone/onyx.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/onyx@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/onyx.webp"
  },
  "common/shopType/stone/opal@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/opal@2x.png",
    "normal": "/images/normal/common/shopType/stone/opal.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/opal@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/opal.webp"
  },
  "common/shopType/stone/paraiba-tourmaline@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/paraiba-tourmaline@2x.png",
    "normal": "/images/normal/common/shopType/stone/paraiba-tourmaline.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/paraiba-tourmaline@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/paraiba-tourmaline.webp"
  },
  "common/shopType/stone/pearl@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/pearl@2x.png",
    "normal": "/images/normal/common/shopType/stone/pearl.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/pearl@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/pearl.webp"
  },
  "common/shopType/stone/peridot@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/peridot@2x.png",
    "normal": "/images/normal/common/shopType/stone/peridot.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/peridot@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/peridot.webp"
  },
  "common/shopType/stone/phenakite@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/phenakite@2x.png",
    "normal": "/images/normal/common/shopType/stone/phenakite.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/phenakite@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/phenakite.webp"
  },
  "common/shopType/stone/quartz@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/quartz@2x.png",
    "normal": "/images/normal/common/shopType/stone/quartz.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/quartz@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/quartz.webp"
  },
  "common/shopType/stone/rhodochrosite@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/rhodochrosite@2x.png",
    "normal": "/images/normal/common/shopType/stone/rhodochrosite.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/rhodochrosite@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/rhodochrosite.webp"
  },
  "common/shopType/stone/rhodolite-garnet@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/rhodolite-garnet@2x.png",
    "normal": "/images/normal/common/shopType/stone/rhodolite-garnet.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/rhodolite-garnet@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/rhodolite-garnet.webp"
  },
  "common/shopType/stone/rose-quartz@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/rose-quartz@2x.png",
    "normal": "/images/normal/common/shopType/stone/rose-quartz.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/rose-quartz@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/rose-quartz.webp"
  },
  "common/shopType/stone/rubellite-tourmaline@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/rubellite-tourmaline@2x.png",
    "normal": "/images/normal/common/shopType/stone/rubellite-tourmaline.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/rubellite-tourmaline@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/rubellite-tourmaline.webp"
  },
  "common/shopType/stone/rutilated-quartz@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/rutilated-quartz@2x.png",
    "normal": "/images/normal/common/shopType/stone/rutilated-quartz.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/rutilated-quartz@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/rutilated-quartz.webp"
  },
  "common/shopType/stone/scapolite@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/scapolite@2x.png",
    "normal": "/images/normal/common/shopType/stone/scapolite.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/scapolite@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/scapolite.webp"
  },
  "common/shopType/stone/sinhalite@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/sinhalite@2x.png",
    "normal": "/images/normal/common/shopType/stone/sinhalite.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/sinhalite@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/sinhalite.webp"
  },
  "common/shopType/stone/smokey-quartz@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/smokey-quartz@2x.png",
    "normal": "/images/normal/common/shopType/stone/smokey-quartz.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/smokey-quartz@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/smokey-quartz.webp"
  },
  "common/shopType/stone/spessartite-garnet@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/spessartite-garnet@2x.png",
    "normal": "/images/normal/common/shopType/stone/spessartite-garnet.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/spessartite-garnet@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/spessartite-garnet.webp"
  },
  "common/shopType/stone/sphene-titanite@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/sphene-titanite@2x.png",
    "normal": "/images/normal/common/shopType/stone/sphene-titanite.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/sphene-titanite@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/sphene-titanite.webp"
  },
  "common/shopType/stone/spinel@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/spinel@2x.png",
    "normal": "/images/normal/common/shopType/stone/spinel.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/spinel@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/spinel.webp"
  },
  "common/shopType/stone/taaffeite@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/taaffeite@2x.png",
    "normal": "/images/normal/common/shopType/stone/taaffeite.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/taaffeite@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/taaffeite.webp"
  },
  "common/shopType/stone/tanzanite@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/tanzanite@2x.png",
    "normal": "/images/normal/common/shopType/stone/tanzanite.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/tanzanite@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/tanzanite.webp"
  },
  "common/shopType/stone/tiger-eye@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/tiger-eye@2x.png",
    "normal": "/images/normal/common/shopType/stone/tiger-eye.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/tiger-eye@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/tiger-eye.webp"
  },
  "common/shopType/stone/topaz@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/topaz@2x.png",
    "normal": "/images/normal/common/shopType/stone/topaz.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/topaz@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/topaz.webp"
  },
  "common/shopType/stone/tourmaline@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/tourmaline@2x.png",
    "normal": "/images/normal/common/shopType/stone/tourmaline.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/tourmaline@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/tourmaline.webp"
  },
  "common/shopType/stone/tsavorite-garnet@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/tsavorite-garnet@2x.png",
    "normal": "/images/normal/common/shopType/stone/tsavorite-garnet.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/tsavorite-garnet@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/tsavorite-garnet.webp"
  },
  "common/shopType/stone/turquoise@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/turquoise@2x.png",
    "normal": "/images/normal/common/shopType/stone/turquoise.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/turquoise@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/turquoise.webp"
  },
  "common/shopType/stone/zircon@2x.png": {
    "retina": "/images/hi-res/common/shopType/stone/zircon@2x.png",
    "normal": "/images/normal/common/shopType/stone/zircon.png",
    "retinaWebp": "/images/hi-res-webp/common/shopType/stone/zircon@2x.webp",
    "webp": "/images/normal-webp/common/shopType/stone/zircon.webp"
  },
  "customJewelry/ring-setting@2x.jpg": {
    "retina": "/images/hi-res/customJewelry/ring-setting@2x.jpg",
    "normal": "/images/normal/customJewelry/ring-setting.jpg",
    "retinaWebp": "/images/hi-res-webp/customJewelry/ring-setting@2x.webp",
    "webp": "/images/normal-webp/customJewelry/ring-setting.webp"
  },
  "customJewelry/section-one-bg@2x.jpg": {
    "retina": "/images/hi-res/customJewelry/section-one-bg@2x.jpg",
    "normal": "/images/normal/customJewelry/section-one-bg.jpg",
    "retinaWebp": "/images/hi-res-webp/customJewelry/section-one-bg@2x.webp",
    "webp": "/images/normal-webp/customJewelry/section-one-bg.webp"
  },
  "customJewelry/section-one-earrings@2x.png": {
    "retina": "/images/hi-res/customJewelry/section-one-earrings@2x.png",
    "normal": "/images/normal/customJewelry/section-one-earrings.png",
    "retinaWebp": "/images/hi-res-webp/customJewelry/section-one-earrings@2x.webp",
    "webp": "/images/normal-webp/customJewelry/section-one-earrings.webp"
  },
  "customJewelry/section-one-ring-tab@2x.png": {
    "retina": "/images/hi-res/customJewelry/section-one-ring-tab@2x.png",
    "normal": "/images/normal/customJewelry/section-one-ring-tab.png",
    "retinaWebp": "/images/hi-res-webp/customJewelry/section-one-ring-tab@2x.webp",
    "webp": "/images/normal-webp/customJewelry/section-one-ring-tab.webp"
  },
  "customJewelry/section-one-ring@2x.png": {
    "retina": "/images/hi-res/customJewelry/section-one-ring@2x.png",
    "normal": "/images/normal/customJewelry/section-one-ring.png",
    "retinaWebp": "/images/hi-res-webp/customJewelry/section-one-ring@2x.webp",
    "webp": "/images/normal-webp/customJewelry/section-one-ring.webp"
  },
  "customJewelry/section-six-metal-item@2x.png": {
    "retina": "/images/hi-res/customJewelry/section-six-metal-item@2x.png",
    "normal": "/images/normal/customJewelry/section-six-metal-item.png",
    "retinaWebp": "/images/hi-res-webp/customJewelry/section-six-metal-item@2x.webp",
    "webp": "/images/normal-webp/customJewelry/section-six-metal-item.webp"
  },
  "customJewelry/section-six-stone@2x.png": {
    "retina": "/images/hi-res/customJewelry/section-six-stone@2x.png",
    "normal": "/images/normal/customJewelry/section-six-stone.png",
    "retinaWebp": "/images/hi-res-webp/customJewelry/section-six-stone@2x.webp",
    "webp": "/images/normal-webp/customJewelry/section-six-stone.webp"
  },
  "customJewelry/setting-part@2x.png": {
    "retina": "/images/hi-res/customJewelry/setting-part@2x.png",
    "normal": "/images/normal/customJewelry/setting-part.png",
    "retinaWebp": "/images/hi-res-webp/customJewelry/setting-part@2x.webp",
    "webp": "/images/normal-webp/customJewelry/setting-part.webp"
  },
  "customJewelry/stone/1@2x.png": {
    "retina": "/images/hi-res/customJewelry/stone/1@2x.png",
    "normal": "/images/normal/customJewelry/stone/1.png",
    "retinaWebp": "/images/hi-res-webp/customJewelry/stone/1@2x.webp",
    "webp": "/images/normal-webp/customJewelry/stone/1.webp"
  },
  "customJewelry/stone/2@2x.png": {
    "retina": "/images/hi-res/customJewelry/stone/2@2x.png",
    "normal": "/images/normal/customJewelry/stone/2.png",
    "retinaWebp": "/images/hi-res-webp/customJewelry/stone/2@2x.webp",
    "webp": "/images/normal-webp/customJewelry/stone/2.webp"
  },
  "customJewelry/stone/3@2x.png": {
    "retina": "/images/hi-res/customJewelry/stone/3@2x.png",
    "normal": "/images/normal/customJewelry/stone/3.png",
    "retinaWebp": "/images/hi-res-webp/customJewelry/stone/3@2x.webp",
    "webp": "/images/normal-webp/customJewelry/stone/3.webp"
  },
  "customJewelry/stone/4@2x.png": {
    "retina": "/images/hi-res/customJewelry/stone/4@2x.png",
    "normal": "/images/normal/customJewelry/stone/4.png",
    "retinaWebp": "/images/hi-res-webp/customJewelry/stone/4@2x.webp",
    "webp": "/images/normal-webp/customJewelry/stone/4.webp"
  },
  "customJewelry/stone/5@2x.png": {
    "retina": "/images/hi-res/customJewelry/stone/5@2x.png",
    "normal": "/images/normal/customJewelry/stone/5.png",
    "retinaWebp": "/images/hi-res-webp/customJewelry/stone/5@2x.webp",
    "webp": "/images/normal-webp/customJewelry/stone/5.webp"
  },
  "customJewelry/stone/6@2x.png": {
    "retina": "/images/hi-res/customJewelry/stone/6@2x.png",
    "normal": "/images/normal/customJewelry/stone/6.png",
    "retinaWebp": "/images/hi-res-webp/customJewelry/stone/6@2x.webp",
    "webp": "/images/normal-webp/customJewelry/stone/6.webp"
  },
  "customJewelry/stone/7@2x.png": {
    "retina": "/images/hi-res/customJewelry/stone/7@2x.png",
    "normal": "/images/normal/customJewelry/stone/7.png",
    "retinaWebp": "/images/hi-res-webp/customJewelry/stone/7@2x.webp",
    "webp": "/images/normal-webp/customJewelry/stone/7.webp"
  },
  "customJewelry/stone@2x.png": {
    "retina": "/images/hi-res/customJewelry/stone@2x.png",
    "normal": "/images/normal/customJewelry/stone.png",
    "retinaWebp": "/images/hi-res-webp/customJewelry/stone@2x.webp",
    "webp": "/images/normal-webp/customJewelry/stone.webp"
  },
  "customJewelry/stones@2x.png": {
    "retina": "/images/hi-res/customJewelry/stones@2x.png",
    "normal": "/images/normal/customJewelry/stones.png",
    "retinaWebp": "/images/hi-res-webp/customJewelry/stones@2x.webp",
    "webp": "/images/normal-webp/customJewelry/stones.webp"
  },
  "customJewelry/three-steps@2x.png": {
    "retina": "/images/hi-res/customJewelry/three-steps@2x.png",
    "normal": "/images/normal/customJewelry/three-steps.png",
    "retinaWebp": "/images/hi-res-webp/customJewelry/three-steps@2x.webp",
    "webp": "/images/normal-webp/customJewelry/three-steps.webp"
  },
  "designYourOwn/rings@2x.png": {
    "retina": "/images/hi-res/designYourOwn/rings@2x.png",
    "normal": "/images/normal/designYourOwn/rings.png",
    "retinaWebp": "/images/hi-res-webp/designYourOwn/rings@2x.webp",
    "webp": "/images/normal-webp/designYourOwn/rings.webp"
  },
  "designYourOwn/settings@2x.png": {
    "retina": "/images/hi-res/designYourOwn/settings@2x.png",
    "normal": "/images/normal/designYourOwn/settings.png",
    "retinaWebp": "/images/hi-res-webp/designYourOwn/settings@2x.webp",
    "webp": "/images/normal-webp/designYourOwn/settings.webp"
  },
  "designYourOwn/stones@2x.png": {
    "retina": "/images/hi-res/designYourOwn/stones@2x.png",
    "normal": "/images/normal/designYourOwn/stones.png",
    "retinaWebp": "/images/hi-res-webp/designYourOwn/stones@2x.webp",
    "webp": "/images/normal-webp/designYourOwn/stones.webp"
  },
  "designs/about-designer-bg@2x.png": {
    "retina": "/images/hi-res/designs/about-designer-bg@2x.png",
    "normal": "/images/normal/designs/about-designer-bg.png",
    "retinaWebp": "/images/hi-res-webp/designs/about-designer-bg@2x.webp",
    "webp": "/images/normal-webp/designs/about-designer-bg.webp"
  },
  "designs/ampersant@2x.png": {
    "retina": "/images/hi-res/designs/ampersant@2x.png",
    "normal": "/images/normal/designs/ampersant.png",
    "retinaWebp": "/images/hi-res-webp/designs/ampersant@2x.webp",
    "webp": "/images/normal-webp/designs/ampersant.webp"
  },
  "designs/bg-full@2x.jpg": {
    "retina": "/images/hi-res/designs/bg-full@2x.jpg",
    "normal": "/images/normal/designs/bg-full.jpg",
    "retinaWebp": "/images/hi-res-webp/designs/bg-full@2x.webp",
    "webp": "/images/normal-webp/designs/bg-full.webp"
  },
  "designs/bg@2x.jpg": {
    "retina": "/images/hi-res/designs/bg@2x.jpg",
    "normal": "/images/normal/designs/bg.jpg",
    "retinaWebp": "/images/hi-res-webp/designs/bg@2x.webp",
    "webp": "/images/normal-webp/designs/bg.webp"
  },
  "designs/instagram@2x.png": {
    "retina": "/images/hi-res/designs/instagram@2x.png",
    "normal": "/images/normal/designs/instagram.png",
    "retinaWebp": "/images/hi-res-webp/designs/instagram@2x.webp",
    "webp": "/images/normal-webp/designs/instagram.webp"
  },
  "details/bracelets@2x.jpg": {
    "retina": "/images/hi-res/details/bracelets@2x.jpg",
    "normal": "/images/normal/details/bracelets.jpg",
    "retinaWebp": "/images/hi-res-webp/details/bracelets@2x.webp",
    "webp": "/images/normal-webp/details/bracelets.webp"
  },
  "details/dyo-thumb@2x.jpg": {
    "retina": "/images/hi-res/details/dyo-thumb@2x.jpg",
    "normal": "/images/normal/details/dyo-thumb.jpg",
    "retinaWebp": "/images/hi-res-webp/details/dyo-thumb@2x.webp",
    "webp": "/images/normal-webp/details/dyo-thumb.webp"
  },
  "details/ear1@2x.jpg": {
    "retina": "/images/hi-res/details/ear1@2x.jpg",
    "normal": "/images/normal/details/ear1.jpg",
    "retinaWebp": "/images/hi-res-webp/details/ear1@2x.webp",
    "webp": "/images/normal-webp/details/ear1.webp"
  },
  "details/ear2@2x.jpg": {
    "retina": "/images/hi-res/details/ear2@2x.jpg",
    "normal": "/images/normal/details/ear2.jpg",
    "retinaWebp": "/images/hi-res-webp/details/ear2@2x.webp",
    "webp": "/images/normal-webp/details/ear2.webp"
  },
  "details/ear3@2x.jpg": {
    "retina": "/images/hi-res/details/ear3@2x.jpg",
    "normal": "/images/normal/details/ear3.jpg",
    "retinaWebp": "/images/hi-res-webp/details/ear3@2x.webp",
    "webp": "/images/normal-webp/details/ear3.webp"
  },
  "details/earThumbnail@2x.jpg": {
    "retina": "/images/hi-res/details/earThumbnail@2x.jpg",
    "normal": "/images/normal/details/earThumbnail.jpg",
    "retinaWebp": "/images/hi-res-webp/details/earThumbnail@2x.webp",
    "webp": "/images/normal-webp/details/earThumbnail.webp"
  },
  "details/earring@2x.jpg": {
    "retina": "/images/hi-res/details/earring@2x.jpg",
    "normal": "/images/normal/details/earring.jpg",
    "retinaWebp": "/images/hi-res-webp/details/earring@2x.webp",
    "webp": "/images/normal-webp/details/earring.webp"
  },
  "details/flags/flag1@2x.jpg": {
    "retina": "/images/hi-res/details/flags/flag1@2x.jpg",
    "normal": "/images/normal/details/flags/flag1.jpg",
    "retinaWebp": "/images/hi-res-webp/details/flags/flag1@2x.webp",
    "webp": "/images/normal-webp/details/flags/flag1.webp"
  },
  "details/flags/flag2@2x.jpg": {
    "retina": "/images/hi-res/details/flags/flag2@2x.jpg",
    "normal": "/images/normal/details/flags/flag2.jpg",
    "retinaWebp": "/images/hi-res-webp/details/flags/flag2@2x.webp",
    "webp": "/images/normal-webp/details/flags/flag2.webp"
  },
  "details/flags/flag3@2x.jpg": {
    "retina": "/images/hi-res/details/flags/flag3@2x.jpg",
    "normal": "/images/normal/details/flags/flag3.jpg",
    "retinaWebp": "/images/hi-res-webp/details/flags/flag3@2x.webp",
    "webp": "/images/normal-webp/details/flags/flag3.webp"
  },
  "details/flags/flag4@2x.jpg": {
    "retina": "/images/hi-res/details/flags/flag4@2x.jpg",
    "normal": "/images/normal/details/flags/flag4.jpg",
    "retinaWebp": "/images/hi-res-webp/details/flags/flag4@2x.webp",
    "webp": "/images/normal-webp/details/flags/flag4.webp"
  },
  "details/flags/flag5@2x.jpg": {
    "retina": "/images/hi-res/details/flags/flag5@2x.jpg",
    "normal": "/images/normal/details/flags/flag5.jpg",
    "retinaWebp": "/images/hi-res-webp/details/flags/flag5@2x.webp",
    "webp": "/images/normal-webp/details/flags/flag5.webp"
  },
  "details/flags/flag6@2x.jpg": {
    "retina": "/images/hi-res/details/flags/flag6@2x.jpg",
    "normal": "/images/normal/details/flags/flag6.jpg",
    "retinaWebp": "/images/hi-res-webp/details/flags/flag6@2x.webp",
    "webp": "/images/normal-webp/details/flags/flag6.webp"
  },
  "details/hand1@2x.jpg": {
    "retina": "/images/hi-res/details/hand1@2x.jpg",
    "normal": "/images/normal/details/hand1.jpg",
    "retinaWebp": "/images/hi-res-webp/details/hand1@2x.webp",
    "webp": "/images/normal-webp/details/hand1.webp"
  },
  "details/hand2@2x.jpg": {
    "retina": "/images/hi-res/details/hand2@2x.jpg",
    "normal": "/images/normal/details/hand2.jpg",
    "retinaWebp": "/images/hi-res-webp/details/hand2@2x.webp",
    "webp": "/images/normal-webp/details/hand2.webp"
  },
  "details/hand3@2x.jpg": {
    "retina": "/images/hi-res/details/hand3@2x.jpg",
    "normal": "/images/normal/details/hand3.jpg",
    "retinaWebp": "/images/hi-res-webp/details/hand3@2x.webp",
    "webp": "/images/normal-webp/details/hand3.webp"
  },
  "details/handThumbnail@2x.jpg": {
    "retina": "/images/hi-res/details/handThumbnail@2x.jpg",
    "normal": "/images/normal/details/handThumbnail.jpg",
    "retinaWebp": "/images/hi-res-webp/details/handThumbnail@2x.webp",
    "webp": "/images/normal-webp/details/handThumbnail.webp"
  },
  "details/iphoneThumb@2x.jpg": {
    "retina": "/images/hi-res/details/iphoneThumb@2x.jpg",
    "normal": "/images/normal/details/iphoneThumb.jpg",
    "retinaWebp": "/images/hi-res-webp/details/iphoneThumb@2x.webp",
    "webp": "/images/normal-webp/details/iphoneThumb.webp"
  },
  "details/jewelry-bracelets@2x.png": {
    "retina": "/images/hi-res/details/jewelry-bracelets@2x.png",
    "normal": "/images/normal/details/jewelry-bracelets.png",
    "retinaWebp": "/images/hi-res-webp/details/jewelry-bracelets@2x.webp",
    "webp": "/images/normal-webp/details/jewelry-bracelets.webp"
  },
  "details/jewelry-brooches@2x.png": {
    "retina": "/images/hi-res/details/jewelry-brooches@2x.png",
    "normal": "/images/normal/details/jewelry-brooches.png",
    "retinaWebp": "/images/hi-res-webp/details/jewelry-brooches@2x.webp",
    "webp": "/images/normal-webp/details/jewelry-brooches.webp"
  },
  "details/jewelry-cufflinks@2x.png": {
    "retina": "/images/hi-res/details/jewelry-cufflinks@2x.png",
    "normal": "/images/normal/details/jewelry-cufflinks.png",
    "retinaWebp": "/images/hi-res-webp/details/jewelry-cufflinks@2x.webp",
    "webp": "/images/normal-webp/details/jewelry-cufflinks.webp"
  },
  "details/jewelry-earrings@2x.png": {
    "retina": "/images/hi-res/details/jewelry-earrings@2x.png",
    "normal": "/images/normal/details/jewelry-earrings.png",
    "retinaWebp": "/images/hi-res-webp/details/jewelry-earrings@2x.webp",
    "webp": "/images/normal-webp/details/jewelry-earrings.webp"
  },
  "details/jewelry-mensRings@2x.png": {
    "retina": "/images/hi-res/details/jewelry-mensRings@2x.png",
    "normal": "/images/normal/details/jewelry-mensRings.png",
    "retinaWebp": "/images/hi-res-webp/details/jewelry-mensRings@2x.webp",
    "webp": "/images/normal-webp/details/jewelry-mensRings.webp"
  },
  "details/jewelry-necklacesPendants@2x.png": {
    "retina": "/images/hi-res/details/jewelry-necklacesPendants@2x.png",
    "normal": "/images/normal/details/jewelry-necklacesPendants.png",
    "retinaWebp": "/images/hi-res-webp/details/jewelry-necklacesPendants@2x.webp",
    "webp": "/images/normal-webp/details/jewelry-necklacesPendants.webp"
  },
  "details/jewelry-rings@2x.png": {
    "retina": "/images/hi-res/details/jewelry-rings@2x.png",
    "normal": "/images/normal/details/jewelry-rings.png",
    "retinaWebp": "/images/hi-res-webp/details/jewelry-rings@2x.webp",
    "webp": "/images/normal-webp/details/jewelry-rings.webp"
  },
  "details/metal-finish/crossSatin@2x.jpg": {
    "retina": "/images/hi-res/details/metal-finish/crossSatin@2x.jpg",
    "normal": "/images/normal/details/metal-finish/crossSatin.jpg",
    "retinaWebp": "/images/hi-res-webp/details/metal-finish/crossSatin@2x.webp",
    "webp": "/images/normal-webp/details/metal-finish/crossSatin.webp"
  },
  "details/metal-finish/crossSatinFull@2x.jpg": {
    "retina": "/images/hi-res/details/metal-finish/crossSatinFull@2x.jpg",
    "normal": "/images/normal/details/metal-finish/crossSatinFull.jpg",
    "retinaWebp": "/images/hi-res-webp/details/metal-finish/crossSatinFull@2x.webp",
    "webp": "/images/normal-webp/details/metal-finish/crossSatinFull.webp"
  },
  "details/metal-finish/hammer@2x.jpg": {
    "retina": "/images/hi-res/details/metal-finish/hammer@2x.jpg",
    "normal": "/images/normal/details/metal-finish/hammer.jpg",
    "retinaWebp": "/images/hi-res-webp/details/metal-finish/hammer@2x.webp",
    "webp": "/images/normal-webp/details/metal-finish/hammer.webp"
  },
  "details/metal-finish/hammerFull@2x.jpg": {
    "retina": "/images/hi-res/details/metal-finish/hammerFull@2x.jpg",
    "normal": "/images/normal/details/metal-finish/hammerFull.jpg",
    "retinaWebp": "/images/hi-res-webp/details/metal-finish/hammerFull@2x.webp",
    "webp": "/images/normal-webp/details/metal-finish/hammerFull.webp"
  },
  "details/metal-finish/highPolish@2x.jpg": {
    "retina": "/images/hi-res/details/metal-finish/highPolish@2x.jpg",
    "normal": "/images/normal/details/metal-finish/highPolish.jpg",
    "retinaWebp": "/images/hi-res-webp/details/metal-finish/highPolish@2x.webp",
    "webp": "/images/normal-webp/details/metal-finish/highPolish.webp"
  },
  "details/metal-finish/highPolishFull@2x.jpg": {
    "retina": "/images/hi-res/details/metal-finish/highPolishFull@2x.jpg",
    "normal": "/images/normal/details/metal-finish/highPolishFull.jpg",
    "retinaWebp": "/images/hi-res-webp/details/metal-finish/highPolishFull@2x.webp",
    "webp": "/images/normal-webp/details/metal-finish/highPolishFull.webp"
  },
  "details/metal-finish/metal-finishes@2x.jpg": {
    "retina": "/images/hi-res/details/metal-finish/metal-finishes@2x.jpg",
    "normal": "/images/normal/details/metal-finish/metal-finishes.jpg",
    "retinaWebp": "/images/hi-res-webp/details/metal-finish/metal-finishes@2x.webp",
    "webp": "/images/normal-webp/details/metal-finish/metal-finishes.webp"
  },
  "details/metal-finish/sandBlast@2x.jpg": {
    "retina": "/images/hi-res/details/metal-finish/sandBlast@2x.jpg",
    "normal": "/images/normal/details/metal-finish/sandBlast.jpg",
    "retinaWebp": "/images/hi-res-webp/details/metal-finish/sandBlast@2x.webp",
    "webp": "/images/normal-webp/details/metal-finish/sandBlast.webp"
  },
  "details/metal-finish/sandBlastFull@2x.jpg": {
    "retina": "/images/hi-res/details/metal-finish/sandBlastFull@2x.jpg",
    "normal": "/images/normal/details/metal-finish/sandBlastFull.jpg",
    "retinaWebp": "/images/hi-res-webp/details/metal-finish/sandBlastFull@2x.webp",
    "webp": "/images/normal-webp/details/metal-finish/sandBlastFull.webp"
  },
  "details/metal-finish/satin@2x.jpg": {
    "retina": "/images/hi-res/details/metal-finish/satin@2x.jpg",
    "normal": "/images/normal/details/metal-finish/satin.jpg",
    "retinaWebp": "/images/hi-res-webp/details/metal-finish/satin@2x.webp",
    "webp": "/images/normal-webp/details/metal-finish/satin.webp"
  },
  "details/metal-finish/satinFinish@2x.jpg": {
    "retina": "/images/hi-res/details/metal-finish/satinFinish@2x.jpg",
    "normal": "/images/normal/details/metal-finish/satinFinish.jpg",
    "retinaWebp": "/images/hi-res-webp/details/metal-finish/satinFinish@2x.webp",
    "webp": "/images/normal-webp/details/metal-finish/satinFinish.webp"
  },
  "details/metal-finish/satinFinishFull@2x.jpg": {
    "retina": "/images/hi-res/details/metal-finish/satinFinishFull@2x.jpg",
    "normal": "/images/normal/details/metal-finish/satinFinishFull.jpg",
    "retinaWebp": "/images/hi-res-webp/details/metal-finish/satinFinishFull@2x.webp",
    "webp": "/images/normal-webp/details/metal-finish/satinFinishFull.webp"
  },
  "details/metal-finish/satinFull@2x.jpg": {
    "retina": "/images/hi-res/details/metal-finish/satinFull@2x.jpg",
    "normal": "/images/normal/details/metal-finish/satinFull.jpg",
    "retinaWebp": "/images/hi-res-webp/details/metal-finish/satinFull@2x.webp",
    "webp": "/images/normal-webp/details/metal-finish/satinFull.webp"
  },
  "details/metal-finish/stoneFinish@2x.jpg": {
    "retina": "/images/hi-res/details/metal-finish/stoneFinish@2x.jpg",
    "normal": "/images/normal/details/metal-finish/stoneFinish.jpg",
    "retinaWebp": "/images/hi-res-webp/details/metal-finish/stoneFinish@2x.webp",
    "webp": "/images/normal-webp/details/metal-finish/stoneFinish.webp"
  },
  "details/metal-finish/stoneFinishFull@2x.jpg": {
    "retina": "/images/hi-res/details/metal-finish/stoneFinishFull@2x.jpg",
    "normal": "/images/normal/details/metal-finish/stoneFinishFull.jpg",
    "retinaWebp": "/images/hi-res-webp/details/metal-finish/stoneFinishFull@2x.webp",
    "webp": "/images/normal-webp/details/metal-finish/stoneFinishFull.webp"
  },
  "details/metal-finish/wireMatt@2x.jpg": {
    "retina": "/images/hi-res/details/metal-finish/wireMatt@2x.jpg",
    "normal": "/images/normal/details/metal-finish/wireMatt.jpg",
    "retinaWebp": "/images/hi-res-webp/details/metal-finish/wireMatt@2x.webp",
    "webp": "/images/normal-webp/details/metal-finish/wireMatt.webp"
  },
  "details/metal-finish/wireMattFull@2x.jpg": {
    "retina": "/images/hi-res/details/metal-finish/wireMattFull@2x.jpg",
    "normal": "/images/normal/details/metal-finish/wireMattFull.jpg",
    "retinaWebp": "/images/hi-res-webp/details/metal-finish/wireMattFull@2x.webp",
    "webp": "/images/normal-webp/details/metal-finish/wireMattFull.webp"
  },
  "details/nec-dust-cover@2x.jpg": {
    "retina": "/images/hi-res/details/nec-dust-cover@2x.jpg",
    "normal": "/images/normal/details/nec-dust-cover.jpg",
    "retinaWebp": "/images/hi-res-webp/details/nec-dust-cover@2x.webp",
    "webp": "/images/normal-webp/details/nec-dust-cover.webp"
  },
  "details/neck1@2x.jpg": {
    "retina": "/images/hi-res/details/neck1@2x.jpg",
    "normal": "/images/normal/details/neck1.jpg",
    "retinaWebp": "/images/hi-res-webp/details/neck1@2x.webp",
    "webp": "/images/normal-webp/details/neck1.webp"
  },
  "details/neck2@2x.jpg": {
    "retina": "/images/hi-res/details/neck2@2x.jpg",
    "normal": "/images/normal/details/neck2.jpg",
    "retinaWebp": "/images/hi-res-webp/details/neck2@2x.webp",
    "webp": "/images/normal-webp/details/neck2.webp"
  },
  "details/neck3@2x.jpg": {
    "retina": "/images/hi-res/details/neck3@2x.jpg",
    "normal": "/images/normal/details/neck3.jpg",
    "retinaWebp": "/images/hi-res-webp/details/neck3@2x.webp",
    "webp": "/images/normal-webp/details/neck3.webp"
  },
  "details/neckThumbnail@2x.jpg": {
    "retina": "/images/hi-res/details/neckThumbnail@2x.jpg",
    "normal": "/images/normal/details/neckThumbnail.jpg",
    "retinaWebp": "/images/hi-res-webp/details/neckThumbnail@2x.webp",
    "webp": "/images/normal-webp/details/neckThumbnail.webp"
  },
  "details/necklaces@2x.jpg": {
    "retina": "/images/hi-res/details/necklaces@2x.jpg",
    "normal": "/images/normal/details/necklaces.jpg",
    "retinaWebp": "/images/hi-res-webp/details/necklaces@2x.webp",
    "webp": "/images/normal-webp/details/necklaces.webp"
  },
  "details/nrc_logo@2x.png": {
    "retina": "/images/hi-res/details/nrc_logo@2x.png",
    "normal": "/images/normal/details/nrc_logo.png",
    "retinaWebp": "/images/hi-res-webp/details/nrc_logo@2x.webp",
    "webp": "/images/normal-webp/details/nrc_logo.webp"
  },
  "details/origin-video@2x.jpg": {
    "retina": "/images/hi-res/details/origin-video@2x.jpg",
    "normal": "/images/normal/details/origin-video.jpg",
    "retinaWebp": "/images/hi-res-webp/details/origin-video@2x.webp",
    "webp": "/images/normal-webp/details/origin-video.webp"
  },
  "details/our-process@2x.jpg": {
    "retina": "/images/hi-res/details/our-process@2x.jpg",
    "normal": "/images/normal/details/our-process.jpg",
    "retinaWebp": "/images/hi-res-webp/details/our-process@2x.webp",
    "webp": "/images/normal-webp/details/our-process.webp"
  },
  "details/pendant@2x.jpg": {
    "retina": "/images/hi-res/details/pendant@2x.jpg",
    "normal": "/images/normal/details/pendant.jpg",
    "retinaWebp": "/images/hi-res-webp/details/pendant@2x.webp",
    "webp": "/images/normal-webp/details/pendant.webp"
  },
  "details/play@2x.png": {
    "retina": "/images/hi-res/details/play@2x.png",
    "normal": "/images/normal/details/play.png",
    "retinaWebp": "/images/hi-res-webp/details/play@2x.webp",
    "webp": "/images/normal-webp/details/play.webp"
  },
  "details/ring@2x.jpg": {
    "retina": "/images/hi-res/details/ring@2x.jpg",
    "normal": "/images/normal/details/ring.jpg",
    "retinaWebp": "/images/hi-res-webp/details/ring@2x.webp",
    "webp": "/images/normal-webp/details/ring.webp"
  },
  "details/sarin@2x.jpg": {
    "retina": "/images/hi-res/details/sarin@2x.jpg",
    "normal": "/images/normal/details/sarin.jpg",
    "retinaWebp": "/images/hi-res-webp/details/sarin@2x.webp",
    "webp": "/images/normal-webp/details/sarin.webp"
  },
  "details/sarinMobile@2x.jpg": {
    "retina": "/images/hi-res/details/sarinMobile@2x.jpg",
    "normal": "/images/normal/details/sarinMobile.jpg",
    "retinaWebp": "/images/hi-res-webp/details/sarinMobile@2x.webp",
    "webp": "/images/normal-webp/details/sarinMobile.webp"
  },
  "details/sarinThumb@2x.jpg": {
    "retina": "/images/hi-res/details/sarinThumb@2x.jpg",
    "normal": "/images/normal/details/sarinThumb.jpg",
    "retinaWebp": "/images/hi-res-webp/details/sarinThumb@2x.webp",
    "webp": "/images/normal-webp/details/sarinThumb.webp"
  },
  "details/settingBracelet@2x.jpg": {
    "retina": "/images/hi-res/details/settingBracelet@2x.jpg",
    "normal": "/images/normal/details/settingBracelet.jpg",
    "retinaWebp": "/images/hi-res-webp/details/settingBracelet@2x.webp",
    "webp": "/images/normal-webp/details/settingBracelet.webp"
  },
  "details/settingEarring@2x.jpg": {
    "retina": "/images/hi-res/details/settingEarring@2x.jpg",
    "normal": "/images/normal/details/settingEarring.jpg",
    "retinaWebp": "/images/hi-res-webp/details/settingEarring@2x.webp",
    "webp": "/images/normal-webp/details/settingEarring.webp"
  },
  "details/settingNecklace@2x.jpg": {
    "retina": "/images/hi-res/details/settingNecklace@2x.jpg",
    "normal": "/images/normal/details/settingNecklace.jpg",
    "retinaWebp": "/images/hi-res-webp/details/settingNecklace@2x.webp",
    "webp": "/images/normal-webp/details/settingNecklace.webp"
  },
  "details/settingPendant@2x.jpg": {
    "retina": "/images/hi-res/details/settingPendant@2x.jpg",
    "normal": "/images/normal/details/settingPendant.jpg",
    "retinaWebp": "/images/hi-res-webp/details/settingPendant@2x.webp",
    "webp": "/images/normal-webp/details/settingPendant.webp"
  },
  "details/settingRing@2x.jpg": {
    "retina": "/images/hi-res/details/settingRing@2x.jpg",
    "normal": "/images/normal/details/settingRing.jpg",
    "retinaWebp": "/images/hi-res-webp/details/settingRing@2x.webp",
    "webp": "/images/normal-webp/details/settingRing.webp"
  },
  "details/settings@2x.png": {
    "retina": "/images/hi-res/details/settings@2x.png",
    "normal": "/images/normal/details/settings.png",
    "retinaWebp": "/images/hi-res-webp/details/settings@2x.webp",
    "webp": "/images/normal-webp/details/settings.webp"
  },
  "details/shape-illustrations@2x.jpg": {
    "retina": "/images/hi-res/details/shape-illustrations@2x.jpg",
    "normal": "/images/normal/details/shape-illustrations.jpg",
    "retinaWebp": "/images/hi-res-webp/details/shape-illustrations@2x.webp",
    "webp": "/images/normal-webp/details/shape-illustrations.webp"
  },
  "details/stones@2x.jpg": {
    "retina": "/images/hi-res/details/stones@2x.jpg",
    "normal": "/images/normal/details/stones.jpg",
    "retinaWebp": "/images/hi-res-webp/details/stones@2x.webp",
    "webp": "/images/normal-webp/details/stones.webp"
  },
  "details/video360@2x.jpg": {
    "retina": "/images/hi-res/details/video360@2x.jpg",
    "normal": "/images/normal/details/video360.jpg",
    "retinaWebp": "/images/hi-res-webp/details/video360@2x.webp",
    "webp": "/images/normal-webp/details/video360.webp"
  },
  "details/wax-model/wax-model-setting-bracelet@2x.png": {
    "retina": "/images/hi-res/details/wax-model/wax-model-setting-bracelet@2x.png",
    "normal": "/images/normal/details/wax-model/wax-model-setting-bracelet.png",
    "retinaWebp": "/images/hi-res-webp/details/wax-model/wax-model-setting-bracelet@2x.webp",
    "webp": "/images/normal-webp/details/wax-model/wax-model-setting-bracelet.webp"
  },
  "details/wax-model/wax-model-setting-earring@2x.png": {
    "retina": "/images/hi-res/details/wax-model/wax-model-setting-earring@2x.png",
    "normal": "/images/normal/details/wax-model/wax-model-setting-earring.png",
    "retinaWebp": "/images/hi-res-webp/details/wax-model/wax-model-setting-earring@2x.webp",
    "webp": "/images/normal-webp/details/wax-model/wax-model-setting-earring.webp"
  },
  "details/wax-model/wax-model-setting-pendant@2x.png": {
    "retina": "/images/hi-res/details/wax-model/wax-model-setting-pendant@2x.png",
    "normal": "/images/normal/details/wax-model/wax-model-setting-pendant.png",
    "retinaWebp": "/images/hi-res-webp/details/wax-model/wax-model-setting-pendant@2x.webp",
    "webp": "/images/normal-webp/details/wax-model/wax-model-setting-pendant.webp"
  },
  "details/wax-model/wax-model-setting-ring@2x.png": {
    "retina": "/images/hi-res/details/wax-model/wax-model-setting-ring@2x.png",
    "normal": "/images/normal/details/wax-model/wax-model-setting-ring.png",
    "retinaWebp": "/images/hi-res-webp/details/wax-model/wax-model-setting-ring@2x.webp",
    "webp": "/images/normal-webp/details/wax-model/wax-model-setting-ring.webp"
  },
  "details/wax-model-popup-setting-bracelet@2x.jpg": {
    "retina": "/images/hi-res/details/wax-model-popup-setting-bracelet@2x.jpg",
    "normal": "/images/normal/details/wax-model-popup-setting-bracelet.jpg",
    "retinaWebp": "/images/hi-res-webp/details/wax-model-popup-setting-bracelet@2x.webp",
    "webp": "/images/normal-webp/details/wax-model-popup-setting-bracelet.webp"
  },
  "details/wax-model-popup-setting-earring@2x.jpg": {
    "retina": "/images/hi-res/details/wax-model-popup-setting-earring@2x.jpg",
    "normal": "/images/normal/details/wax-model-popup-setting-earring.jpg",
    "retinaWebp": "/images/hi-res-webp/details/wax-model-popup-setting-earring@2x.webp",
    "webp": "/images/normal-webp/details/wax-model-popup-setting-earring.webp"
  },
  "details/wax-model-popup-setting-necklace@2x.jpg": {
    "retina": "/images/hi-res/details/wax-model-popup-setting-necklace@2x.jpg",
    "normal": "/images/normal/details/wax-model-popup-setting-necklace.jpg",
    "retinaWebp": "/images/hi-res-webp/details/wax-model-popup-setting-necklace@2x.webp",
    "webp": "/images/normal-webp/details/wax-model-popup-setting-necklace.webp"
  },
  "details/wax-model-popup-setting-pendant@2x.jpg": {
    "retina": "/images/hi-res/details/wax-model-popup-setting-pendant@2x.jpg",
    "normal": "/images/normal/details/wax-model-popup-setting-pendant.jpg",
    "retinaWebp": "/images/hi-res-webp/details/wax-model-popup-setting-pendant@2x.webp",
    "webp": "/images/normal-webp/details/wax-model-popup-setting-pendant.webp"
  },
  "details/wax-model-popup-setting-ring@2x.jpg": {
    "retina": "/images/hi-res/details/wax-model-popup-setting-ring@2x.jpg",
    "normal": "/images/normal/details/wax-model-popup-setting-ring.jpg",
    "retinaWebp": "/images/hi-res-webp/details/wax-model-popup-setting-ring@2x.webp",
    "webp": "/images/normal-webp/details/wax-model-popup-setting-ring.webp"
  },
  "details/wax-model-setting-bracelet@2x.jpg": {
    "retina": "/images/hi-res/details/wax-model-setting-bracelet@2x.jpg",
    "normal": "/images/normal/details/wax-model-setting-bracelet.jpg",
    "retinaWebp": "/images/hi-res-webp/details/wax-model-setting-bracelet@2x.webp",
    "webp": "/images/normal-webp/details/wax-model-setting-bracelet.webp"
  },
  "details/wax-model-setting-earring@2x.jpg": {
    "retina": "/images/hi-res/details/wax-model-setting-earring@2x.jpg",
    "normal": "/images/normal/details/wax-model-setting-earring.jpg",
    "retinaWebp": "/images/hi-res-webp/details/wax-model-setting-earring@2x.webp",
    "webp": "/images/normal-webp/details/wax-model-setting-earring.webp"
  },
  "details/wax-model-setting-necklace@2x.jpg": {
    "retina": "/images/hi-res/details/wax-model-setting-necklace@2x.jpg",
    "normal": "/images/normal/details/wax-model-setting-necklace.jpg",
    "retinaWebp": "/images/hi-res-webp/details/wax-model-setting-necklace@2x.webp",
    "webp": "/images/normal-webp/details/wax-model-setting-necklace.webp"
  },
  "details/wax-model-setting-pendant@2x.jpg": {
    "retina": "/images/hi-res/details/wax-model-setting-pendant@2x.jpg",
    "normal": "/images/normal/details/wax-model-setting-pendant.jpg",
    "retinaWebp": "/images/hi-res-webp/details/wax-model-setting-pendant@2x.webp",
    "webp": "/images/normal-webp/details/wax-model-setting-pendant.webp"
  },
  "details/wax-model-setting-ring@2x.jpg": {
    "retina": "/images/hi-res/details/wax-model-setting-ring@2x.jpg",
    "normal": "/images/normal/details/wax-model-setting-ring.jpg",
    "retinaWebp": "/images/hi-res-webp/details/wax-model-setting-ring@2x.webp",
    "webp": "/images/normal-webp/details/wax-model-setting-ring.webp"
  },
  "details/wax-setting-bracelet@2x.jpg": {
    "retina": "/images/hi-res/details/wax-setting-bracelet@2x.jpg",
    "normal": "/images/normal/details/wax-setting-bracelet.jpg",
    "retinaWebp": "/images/hi-res-webp/details/wax-setting-bracelet@2x.webp",
    "webp": "/images/normal-webp/details/wax-setting-bracelet.webp"
  },
  "details/wax-setting-earring@2x.jpg": {
    "retina": "/images/hi-res/details/wax-setting-earring@2x.jpg",
    "normal": "/images/normal/details/wax-setting-earring.jpg",
    "retinaWebp": "/images/hi-res-webp/details/wax-setting-earring@2x.webp",
    "webp": "/images/normal-webp/details/wax-setting-earring.webp"
  },
  "details/wax-setting-necklace@2x.jpg": {
    "retina": "/images/hi-res/details/wax-setting-necklace@2x.jpg",
    "normal": "/images/normal/details/wax-setting-necklace.jpg",
    "retinaWebp": "/images/hi-res-webp/details/wax-setting-necklace@2x.webp",
    "webp": "/images/normal-webp/details/wax-setting-necklace.webp"
  },
  "details/wax-setting-pendant@2x.jpg": {
    "retina": "/images/hi-res/details/wax-setting-pendant@2x.jpg",
    "normal": "/images/normal/details/wax-setting-pendant.jpg",
    "retinaWebp": "/images/hi-res-webp/details/wax-setting-pendant@2x.webp",
    "webp": "/images/normal-webp/details/wax-setting-pendant.webp"
  },
  "details/wax-setting-ring@2x.jpg": {
    "retina": "/images/hi-res/details/wax-setting-ring@2x.jpg",
    "normal": "/images/normal/details/wax-setting-ring.jpg",
    "retinaWebp": "/images/hi-res-webp/details/wax-setting-ring@2x.webp",
    "webp": "/images/normal-webp/details/wax-setting-ring.webp"
  },
  "details/wax@2x.jpg": {
    "retina": "/images/hi-res/details/wax@2x.jpg",
    "normal": "/images/normal/details/wax.jpg",
    "retinaWebp": "/images/hi-res-webp/details/wax@2x.webp",
    "webp": "/images/normal-webp/details/wax.webp"
  },
  "details/wax_bak@2x.png": {
    "retina": "/images/hi-res/details/wax_bak@2x.png",
    "normal": "/images/normal/details/wax_bak.png",
    "retinaWebp": "/images/hi-res-webp/details/wax_bak@2x.webp",
    "webp": "/images/normal-webp/details/wax_bak.webp"
  },
  "education/gemstone-in-stone@2x.jpg": {
    "retina": "/images/hi-res/education/gemstone-in-stone@2x.jpg",
    "normal": "/images/normal/education/gemstone-in-stone.jpg",
    "retinaWebp": "/images/hi-res-webp/education/gemstone-in-stone@2x.webp",
    "webp": "/images/normal-webp/education/gemstone-in-stone.webp"
  },
  "education/rubies@2x.png": {
    "retina": "/images/hi-res/education/rubies@2x.png",
    "normal": "/images/normal/education/rubies.png",
    "retinaWebp": "/images/hi-res-webp/education/rubies@2x.webp",
    "webp": "/images/normal-webp/education/rubies.webp"
  },
  "education/video@2x.jpg": {
    "retina": "/images/hi-res/education/video@2x.jpg",
    "normal": "/images/normal/education/video.jpg",
    "retinaWebp": "/images/hi-res-webp/education/video@2x.webp",
    "webp": "/images/normal-webp/education/video.webp"
  },
  "extend/bg@2x.jpg": {
    "retina": "/images/hi-res/extend/bg@2x.jpg",
    "normal": "/images/normal/extend/bg.jpg",
    "retinaWebp": "/images/hi-res-webp/extend/bg@2x.webp",
    "webp": "/images/normal-webp/extend/bg.webp"
  },
  "extend/bg_mob@2x.jpg": {
    "retina": "/images/hi-res/extend/bg_mob@2x.jpg",
    "normal": "/images/normal/extend/bg_mob.jpg",
    "retinaWebp": "/images/hi-res-webp/extend/bg_mob@2x.webp",
    "webp": "/images/normal-webp/extend/bg_mob.webp"
  },
  "extend/bg_tab@2x.jpg": {
    "retina": "/images/hi-res/extend/bg_tab@2x.jpg",
    "normal": "/images/normal/extend/bg_tab.jpg",
    "retinaWebp": "/images/hi-res-webp/extend/bg_tab@2x.webp",
    "webp": "/images/normal-webp/extend/bg_tab.webp"
  },
  "extend/logo/company_logo@2x.png": {
    "retina": "/images/hi-res/extend/logo/company_logo@2x.png",
    "normal": "/images/normal/extend/logo/company_logo.png",
    "retinaWebp": "/images/hi-res-webp/extend/logo/company_logo@2x.webp",
    "webp": "/images/normal-webp/extend/logo/company_logo.webp"
  },
  "extend/various/chat_screenshot@2x.png": {
    "retina": "/images/hi-res/extend/various/chat_screenshot@2x.png",
    "normal": "/images/normal/extend/various/chat_screenshot.png",
    "retinaWebp": "/images/hi-res-webp/extend/various/chat_screenshot@2x.webp",
    "webp": "/images/normal-webp/extend/various/chat_screenshot.webp"
  },
  "extend/various/extend_154@2x.png": {
    "retina": "/images/hi-res/extend/various/extend_154@2x.png",
    "normal": "/images/normal/extend/various/extend_154.png",
    "retinaWebp": "/images/hi-res-webp/extend/various/extend_154@2x.webp",
    "webp": "/images/normal-webp/extend/various/extend_154.webp"
  },
  "extend/various/extend_86@2x.png": {
    "retina": "/images/hi-res/extend/various/extend_86@2x.png",
    "normal": "/images/normal/extend/various/extend_86.png",
    "retinaWebp": "/images/hi-res-webp/extend/various/extend_86@2x.webp",
    "webp": "/images/normal-webp/extend/various/extend_86.webp"
  },
  "extend/various/extend_88@2x.png": {
    "retina": "/images/hi-res/extend/various/extend_88@2x.png",
    "normal": "/images/normal/extend/various/extend_88.png",
    "retinaWebp": "/images/hi-res-webp/extend/various/extend_88@2x.webp",
    "webp": "/images/normal-webp/extend/various/extend_88.webp"
  },
  "extend/various/screen_shot@2x.png": {
    "retina": "/images/hi-res/extend/various/screen_shot@2x.png",
    "normal": "/images/normal/extend/various/screen_shot.png",
    "retinaWebp": "/images/hi-res-webp/extend/various/screen_shot@2x.webp",
    "webp": "/images/normal-webp/extend/various/screen_shot.webp"
  },
  "extend/various/screen_shot_mob@2x.png": {
    "retina": "/images/hi-res/extend/various/screen_shot_mob@2x.png",
    "normal": "/images/normal/extend/various/screen_shot_mob.png",
    "retinaWebp": "/images/hi-res-webp/extend/various/screen_shot_mob@2x.webp",
    "webp": "/images/normal-webp/extend/various/screen_shot_mob.webp"
  },
  "filters/platinum@2x.jpg": {
    "retina": "/images/hi-res/filters/platinum@2x.jpg",
    "normal": "/images/normal/filters/platinum.jpg",
    "retinaWebp": "/images/hi-res-webp/filters/platinum@2x.webp",
    "webp": "/images/normal-webp/filters/platinum.webp"
  },
  "filters/platinumYellow@2x.jpg": {
    "retina": "/images/hi-res/filters/platinumYellow@2x.jpg",
    "normal": "/images/normal/filters/platinumYellow.jpg",
    "retinaWebp": "/images/hi-res-webp/filters/platinumYellow@2x.webp",
    "webp": "/images/normal-webp/filters/platinumYellow.webp"
  },
  "filters/roseGold@2x.jpg": {
    "retina": "/images/hi-res/filters/roseGold@2x.jpg",
    "normal": "/images/normal/filters/roseGold.jpg",
    "retinaWebp": "/images/hi-res-webp/filters/roseGold@2x.webp",
    "webp": "/images/normal-webp/filters/roseGold.webp"
  },
  "filters/roseWhite@2x.jpg": {
    "retina": "/images/hi-res/filters/roseWhite@2x.jpg",
    "normal": "/images/normal/filters/roseWhite.jpg",
    "retinaWebp": "/images/hi-res-webp/filters/roseWhite@2x.webp",
    "webp": "/images/normal-webp/filters/roseWhite.webp"
  },
  "filters/silver@2x.jpg": {
    "retina": "/images/hi-res/filters/silver@2x.jpg",
    "normal": "/images/normal/filters/silver.jpg",
    "retinaWebp": "/images/hi-res-webp/filters/silver@2x.webp",
    "webp": "/images/normal-webp/filters/silver.webp"
  },
  "filters/whiteGold@2x.jpg": {
    "retina": "/images/hi-res/filters/whiteGold@2x.jpg",
    "normal": "/images/normal/filters/whiteGold.jpg",
    "retinaWebp": "/images/hi-res-webp/filters/whiteGold@2x.webp",
    "webp": "/images/normal-webp/filters/whiteGold.webp"
  },
  "filters/whiteRose@2x.jpg": {
    "retina": "/images/hi-res/filters/whiteRose@2x.jpg",
    "normal": "/images/normal/filters/whiteRose.jpg",
    "retinaWebp": "/images/hi-res-webp/filters/whiteRose@2x.webp",
    "webp": "/images/normal-webp/filters/whiteRose.webp"
  },
  "filters/whiteYellow@2x.jpg": {
    "retina": "/images/hi-res/filters/whiteYellow@2x.jpg",
    "normal": "/images/normal/filters/whiteYellow.jpg",
    "retinaWebp": "/images/hi-res-webp/filters/whiteYellow@2x.webp",
    "webp": "/images/normal-webp/filters/whiteYellow.webp"
  },
  "filters/yellowGold@2x.jpg": {
    "retina": "/images/hi-res/filters/yellowGold@2x.jpg",
    "normal": "/images/normal/filters/yellowGold.jpg",
    "retinaWebp": "/images/hi-res-webp/filters/yellowGold@2x.webp",
    "webp": "/images/normal-webp/filters/yellowGold.webp"
  },
  "filters/yellowRose@2x.jpg": {
    "retina": "/images/hi-res/filters/yellowRose@2x.jpg",
    "normal": "/images/normal/filters/yellowRose.jpg",
    "retinaWebp": "/images/hi-res-webp/filters/yellowRose@2x.webp",
    "webp": "/images/normal-webp/filters/yellowRose.webp"
  },
  "filters/yellowWhite@2x.jpg": {
    "retina": "/images/hi-res/filters/yellowWhite@2x.jpg",
    "normal": "/images/normal/filters/yellowWhite.jpg",
    "retinaWebp": "/images/hi-res-webp/filters/yellowWhite@2x.webp",
    "webp": "/images/normal-webp/filters/yellowWhite.webp"
  },
  "financing/bg-fullest-width@2x.jpg": {
    "retina": "/images/hi-res/financing/bg-fullest-width@2x.jpg",
    "normal": "/images/normal/financing/bg-fullest-width.jpg",
    "retinaWebp": "/images/hi-res-webp/financing/bg-fullest-width@2x.webp",
    "webp": "/images/normal-webp/financing/bg-fullest-width.webp"
  },
  "financing/bg-mob@2x.jpg": {
    "retina": "/images/hi-res/financing/bg-mob@2x.jpg",
    "normal": "/images/normal/financing/bg-mob.jpg",
    "retinaWebp": "/images/hi-res-webp/financing/bg-mob@2x.webp",
    "webp": "/images/normal-webp/financing/bg-mob.webp"
  },
  "financing/bg@2x.jpg": {
    "retina": "/images/hi-res/financing/bg@2x.jpg",
    "normal": "/images/normal/financing/bg.jpg",
    "retinaWebp": "/images/hi-res-webp/financing/bg@2x.webp",
    "webp": "/images/normal-webp/financing/bg.webp"
  },
  "financing/logo/logo-citi-pay@2x.png": {
    "retina": "/images/hi-res/financing/logo/logo-citi-pay@2x.png",
    "normal": "/images/normal/financing/logo/logo-citi-pay.png",
    "retinaWebp": "/images/hi-res-webp/financing/logo/logo-citi-pay@2x.webp",
    "webp": "/images/normal-webp/financing/logo/logo-citi-pay.webp"
  },
  "financing/ring@2x.png": {
    "retina": "/images/hi-res/financing/ring@2x.png",
    "normal": "/images/normal/financing/ring.png",
    "retinaWebp": "/images/hi-res-webp/financing/ring@2x.webp",
    "webp": "/images/normal-webp/financing/ring.webp"
  },
  "financing/rings-gemstone@2x.jpg": {
    "retina": "/images/hi-res/financing/rings-gemstone@2x.jpg",
    "normal": "/images/normal/financing/rings-gemstone.jpg",
    "retinaWebp": "/images/hi-res-webp/financing/rings-gemstone@2x.webp",
    "webp": "/images/normal-webp/financing/rings-gemstone.webp"
  },
  "full-logo@2x.jpg": {
    "retina": "/images/hi-res/full-logo@2x.jpg",
    "normal": "/images/normal/full-logo.jpg",
    "retinaWebp": "/images/hi-res-webp/full-logo@2x.webp",
    "webp": "/images/normal-webp/full-logo.webp"
  },
  "hero/woman@2x.jpg": {
    "retina": "/images/hi-res/hero/woman@2x.jpg",
    "normal": "/images/normal/hero/woman.jpg",
    "retinaWebp": "/images/hi-res-webp/hero/woman@2x.webp",
    "webp": "/images/normal-webp/hero/woman.webp"
  },
  "homePage/charity/children@2x.jpg": {
    "retina": "/images/hi-res/homePage/charity/children@2x.jpg",
    "normal": "/images/normal/homePage/charity/children.jpg",
    "retinaWebp": "/images/hi-res-webp/homePage/charity/children@2x.webp",
    "webp": "/images/normal-webp/homePage/charity/children.webp"
  },
  "homePage/mining/bg-poster@2x.jpg": {
    "retina": "/images/hi-res/homePage/mining/bg-poster@2x.jpg",
    "normal": "/images/normal/homePage/mining/bg-poster.jpg",
    "retinaWebp": "/images/hi-res-webp/homePage/mining/bg-poster@2x.webp",
    "webp": "/images/normal-webp/homePage/mining/bg-poster.webp"
  },
  "homePage/mining/video-1@2x.jpg": {
    "retina": "/images/hi-res/homePage/mining/video-1@2x.jpg",
    "normal": "/images/normal/homePage/mining/video-1.jpg",
    "retinaWebp": "/images/hi-res-webp/homePage/mining/video-1@2x.webp",
    "webp": "/images/normal-webp/homePage/mining/video-1.webp"
  },
  "homePage/mining/video-2@2x.jpg": {
    "retina": "/images/hi-res/homePage/mining/video-2@2x.jpg",
    "normal": "/images/normal/homePage/mining/video-2.jpg",
    "retinaWebp": "/images/hi-res-webp/homePage/mining/video-2@2x.webp",
    "webp": "/images/normal-webp/homePage/mining/video-2.webp"
  },
  "homePage/mining/video-3@2x.jpg": {
    "retina": "/images/hi-res/homePage/mining/video-3@2x.jpg",
    "normal": "/images/normal/homePage/mining/video-3.jpg",
    "retinaWebp": "/images/hi-res-webp/homePage/mining/video-3@2x.webp",
    "webp": "/images/normal-webp/homePage/mining/video-3.webp"
  },
  "homePage/ourHistory/left@2x.png": {
    "retina": "/images/hi-res/homePage/ourHistory/left@2x.png",
    "normal": "/images/normal/homePage/ourHistory/left.png",
    "retinaWebp": "/images/hi-res-webp/homePage/ourHistory/left@2x.webp",
    "webp": "/images/normal-webp/homePage/ourHistory/left.webp"
  },
  "homePage/ourHistory/right@2x.png": {
    "retina": "/images/hi-res/homePage/ourHistory/right@2x.png",
    "normal": "/images/normal/homePage/ourHistory/right.png",
    "retinaWebp": "/images/hi-res-webp/homePage/ourHistory/right@2x.webp",
    "webp": "/images/normal-webp/homePage/ourHistory/right.webp"
  },
  "homePage/shopWithPeace/bg@2x.jpg": {
    "retina": "/images/hi-res/homePage/shopWithPeace/bg@2x.jpg",
    "normal": "/images/normal/homePage/shopWithPeace/bg.jpg",
    "retinaWebp": "/images/hi-res-webp/homePage/shopWithPeace/bg@2x.webp",
    "webp": "/images/normal-webp/homePage/shopWithPeace/bg.webp"
  },
  "homePage/showroom/center@2x.jpg": {
    "retina": "/images/hi-res/homePage/showroom/center@2x.jpg",
    "normal": "/images/normal/homePage/showroom/center.jpg",
    "retinaWebp": "/images/hi-res-webp/homePage/showroom/center@2x.webp",
    "webp": "/images/normal-webp/homePage/showroom/center.webp"
  },
  "homePage/showroom/left@2x.jpg": {
    "retina": "/images/hi-res/homePage/showroom/left@2x.jpg",
    "normal": "/images/normal/homePage/showroom/left.jpg",
    "retinaWebp": "/images/hi-res-webp/homePage/showroom/left@2x.webp",
    "webp": "/images/normal-webp/homePage/showroom/left.webp"
  },
  "homePage/showroom/right@2x.jpg": {
    "retina": "/images/hi-res/homePage/showroom/right@2x.jpg",
    "normal": "/images/normal/homePage/showroom/right.jpg",
    "retinaWebp": "/images/hi-res-webp/homePage/showroom/right@2x.webp",
    "webp": "/images/normal-webp/homePage/showroom/right.webp"
  },
  "landingPage/home/hero/center-home@2x.jpg": {
    "retina": "/images/hi-res/landingPage/home/hero/center-home@2x.jpg",
    "normal": "/images/normal/landingPage/home/hero/center-home.jpg",
    "retinaWebp": "/images/hi-res-webp/landingPage/home/hero/center-home@2x.webp",
    "webp": "/images/normal-webp/landingPage/home/hero/center-home.webp"
  },
  "landingPage/home/hero/left-home@2x.jpg": {
    "retina": "/images/hi-res/landingPage/home/hero/left-home@2x.jpg",
    "normal": "/images/normal/landingPage/home/hero/left-home.jpg",
    "retinaWebp": "/images/hi-res-webp/landingPage/home/hero/left-home@2x.webp",
    "webp": "/images/normal-webp/landingPage/home/hero/left-home.webp"
  },
  "landingPage/home/hero/right-home@2x.jpg": {
    "retina": "/images/hi-res/landingPage/home/hero/right-home@2x.jpg",
    "normal": "/images/normal/landingPage/home/hero/right-home.jpg",
    "retinaWebp": "/images/hi-res-webp/landingPage/home/hero/right-home@2x.webp",
    "webp": "/images/normal-webp/landingPage/home/hero/right-home.webp"
  },
  "landingPage/home/hero/woman@2x.jpg": {
    "retina": "/images/hi-res/landingPage/home/hero/woman@2x.jpg",
    "normal": "/images/normal/landingPage/home/hero/woman.jpg",
    "retinaWebp": "/images/hi-res-webp/landingPage/home/hero/woman@2x.webp",
    "webp": "/images/normal-webp/landingPage/home/hero/woman.webp"
  },
  "landingPage/pair/designYourOwn/right-photo@2x.jpg": {
    "retina": "/images/hi-res/landingPage/pair/designYourOwn/right-photo@2x.jpg",
    "normal": "/images/normal/landingPage/pair/designYourOwn/right-photo.jpg",
    "retinaWebp": "/images/hi-res-webp/landingPage/pair/designYourOwn/right-photo@2x.webp",
    "webp": "/images/normal-webp/landingPage/pair/designYourOwn/right-photo.webp"
  },
  "landingPage/pair/hero/center-pair@2x.jpg": {
    "retina": "/images/hi-res/landingPage/pair/hero/center-pair@2x.jpg",
    "normal": "/images/normal/landingPage/pair/hero/center-pair.jpg",
    "retinaWebp": "/images/hi-res-webp/landingPage/pair/hero/center-pair@2x.webp",
    "webp": "/images/normal-webp/landingPage/pair/hero/center-pair.webp"
  },
  "landingPage/pair/hero/left-pair@2x.jpg": {
    "retina": "/images/hi-res/landingPage/pair/hero/left-pair@2x.jpg",
    "normal": "/images/normal/landingPage/pair/hero/left-pair.jpg",
    "retinaWebp": "/images/hi-res-webp/landingPage/pair/hero/left-pair@2x.webp",
    "webp": "/images/normal-webp/landingPage/pair/hero/left-pair.webp"
  },
  "landingPage/pair/hero/right-pair@2x.jpg": {
    "retina": "/images/hi-res/landingPage/pair/hero/right-pair@2x.jpg",
    "normal": "/images/normal/landingPage/pair/hero/right-pair.jpg",
    "retinaWebp": "/images/hi-res-webp/landingPage/pair/hero/right-pair@2x.webp",
    "webp": "/images/normal-webp/landingPage/pair/hero/right-pair.webp"
  },
  "landingPage/pair/hero/woman@2x.jpg": {
    "retina": "/images/hi-res/landingPage/pair/hero/woman@2x.jpg",
    "normal": "/images/normal/landingPage/pair/hero/woman.jpg",
    "retinaWebp": "/images/hi-res-webp/landingPage/pair/hero/woman@2x.webp",
    "webp": "/images/normal-webp/landingPage/pair/hero/woman.webp"
  },
  "landingPage/ring/hero/center-ring@2x.jpg": {
    "retina": "/images/hi-res/landingPage/ring/hero/center-ring@2x.jpg",
    "normal": "/images/normal/landingPage/ring/hero/center-ring.jpg",
    "retinaWebp": "/images/hi-res-webp/landingPage/ring/hero/center-ring@2x.webp",
    "webp": "/images/normal-webp/landingPage/ring/hero/center-ring.webp"
  },
  "landingPage/ring/hero/left-ring@2x.jpg": {
    "retina": "/images/hi-res/landingPage/ring/hero/left-ring@2x.jpg",
    "normal": "/images/normal/landingPage/ring/hero/left-ring.jpg",
    "retinaWebp": "/images/hi-res-webp/landingPage/ring/hero/left-ring@2x.webp",
    "webp": "/images/normal-webp/landingPage/ring/hero/left-ring.webp"
  },
  "landingPage/ring/hero/right-ring@2x.jpg": {
    "retina": "/images/hi-res/landingPage/ring/hero/right-ring@2x.jpg",
    "normal": "/images/normal/landingPage/ring/hero/right-ring.jpg",
    "retinaWebp": "/images/hi-res-webp/landingPage/ring/hero/right-ring@2x.webp",
    "webp": "/images/normal-webp/landingPage/ring/hero/right-ring.webp"
  },
  "landingPage/ring/hero/woman@2x.jpg": {
    "retina": "/images/hi-res/landingPage/ring/hero/woman@2x.jpg",
    "normal": "/images/normal/landingPage/ring/hero/woman.jpg",
    "retinaWebp": "/images/hi-res-webp/landingPage/ring/hero/woman@2x.webp",
    "webp": "/images/normal-webp/landingPage/ring/hero/woman.webp"
  },
  "landingPage/showroom/showroom@2x.jpg": {
    "retina": "/images/hi-res/landingPage/showroom/showroom@2x.jpg",
    "normal": "/images/normal/landingPage/showroom/showroom.jpg",
    "retinaWebp": "/images/hi-res-webp/landingPage/showroom/showroom@2x.webp",
    "webp": "/images/normal-webp/landingPage/showroom/showroom.webp"
  },
  "landingPage/stone/designYourOwn/right-photo@2x.jpg": {
    "retina": "/images/hi-res/landingPage/stone/designYourOwn/right-photo@2x.jpg",
    "normal": "/images/normal/landingPage/stone/designYourOwn/right-photo.jpg",
    "retinaWebp": "/images/hi-res-webp/landingPage/stone/designYourOwn/right-photo@2x.webp",
    "webp": "/images/normal-webp/landingPage/stone/designYourOwn/right-photo.webp"
  },
  "landingPage/stone/hero/center-stone@2x.jpg": {
    "retina": "/images/hi-res/landingPage/stone/hero/center-stone@2x.jpg",
    "normal": "/images/normal/landingPage/stone/hero/center-stone.jpg",
    "retinaWebp": "/images/hi-res-webp/landingPage/stone/hero/center-stone@2x.webp",
    "webp": "/images/normal-webp/landingPage/stone/hero/center-stone.webp"
  },
  "landingPage/stone/hero/left-stone@2x.jpg": {
    "retina": "/images/hi-res/landingPage/stone/hero/left-stone@2x.jpg",
    "normal": "/images/normal/landingPage/stone/hero/left-stone.jpg",
    "retinaWebp": "/images/hi-res-webp/landingPage/stone/hero/left-stone@2x.webp",
    "webp": "/images/normal-webp/landingPage/stone/hero/left-stone.webp"
  },
  "landingPage/stone/hero/right-stone@2x.jpg": {
    "retina": "/images/hi-res/landingPage/stone/hero/right-stone@2x.jpg",
    "normal": "/images/normal/landingPage/stone/hero/right-stone.jpg",
    "retinaWebp": "/images/hi-res-webp/landingPage/stone/hero/right-stone@2x.webp",
    "webp": "/images/normal-webp/landingPage/stone/hero/right-stone.webp"
  },
  "landingPage/stone/hero/woman@2x.jpg": {
    "retina": "/images/hi-res/landingPage/stone/hero/woman@2x.jpg",
    "normal": "/images/normal/landingPage/stone/hero/woman.jpg",
    "retinaWebp": "/images/hi-res-webp/landingPage/stone/hero/woman@2x.webp",
    "webp": "/images/normal-webp/landingPage/stone/hero/woman.webp"
  },
  "layout/logo-bbb@2x.jpg": {
    "retina": "/images/hi-res/layout/logo-bbb@2x.jpg",
    "normal": "/images/normal/layout/logo-bbb.jpg",
    "retinaWebp": "/images/hi-res-webp/layout/logo-bbb@2x.webp",
    "webp": "/images/normal-webp/layout/logo-bbb.webp"
  },
  "layout/logo-verfied-reviews@2x.jpg": {
    "retina": "/images/hi-res/layout/logo-verfied-reviews@2x.jpg",
    "normal": "/images/normal/layout/logo-verfied-reviews.jpg",
    "retinaWebp": "/images/hi-res-webp/layout/logo-verfied-reviews@2x.webp",
    "webp": "/images/normal-webp/layout/logo-verfied-reviews.webp"
  },
  "layout/logo@2x.jpg": {
    "retina": "/images/hi-res/layout/logo@2x.jpg",
    "normal": "/images/normal/layout/logo.jpg",
    "retinaWebp": "/images/hi-res-webp/layout/logo@2x.webp",
    "webp": "/images/normal-webp/layout/logo.webp"
  },
  "logo@2x.jpg": {
    "retina": "/images/hi-res/logo@2x.jpg",
    "normal": "/images/normal/logo.jpg",
    "retinaWebp": "/images/hi-res-webp/logo@2x.webp",
    "webp": "/images/normal-webp/logo.webp"
  },
  "logos/allSites/emerald@2x.jpg": {
    "retina": "/images/hi-res/logos/allSites/emerald@2x.jpg",
    "normal": "/images/normal/logos/allSites/emerald.jpg",
    "retinaWebp": "/images/hi-res-webp/logos/allSites/emerald@2x.webp",
    "webp": "/images/normal-webp/logos/allSites/emerald.webp"
  },
  "logos/allSites/gemstone@2x.jpg": {
    "retina": "/images/hi-res/logos/allSites/gemstone@2x.jpg",
    "normal": "/images/normal/logos/allSites/gemstone.jpg",
    "retinaWebp": "/images/hi-res-webp/logos/allSites/gemstone@2x.webp",
    "webp": "/images/normal-webp/logos/allSites/gemstone.webp"
  },
  "logos/allSites/ruby@2x.jpg": {
    "retina": "/images/hi-res/logos/allSites/ruby@2x.jpg",
    "normal": "/images/normal/logos/allSites/ruby.jpg",
    "retinaWebp": "/images/hi-res-webp/logos/allSites/ruby@2x.webp",
    "webp": "/images/normal-webp/logos/allSites/ruby.webp"
  },
  "logos/allSites/sapphire@2x.jpg": {
    "retina": "/images/hi-res/logos/allSites/sapphire@2x.jpg",
    "normal": "/images/normal/logos/allSites/sapphire.jpg",
    "retinaWebp": "/images/hi-res-webp/logos/allSites/sapphire@2x.webp",
    "webp": "/images/normal-webp/logos/allSites/sapphire.webp"
  },
  "logos/full-logo@2x.jpg": {
    "retina": "/images/hi-res/logos/full-logo@2x.jpg",
    "normal": "/images/normal/logos/full-logo.jpg",
    "retinaWebp": "/images/hi-res-webp/logos/full-logo@2x.webp",
    "webp": "/images/normal-webp/logos/full-logo.webp"
  },
  "logos/laptop-logo@2x.jpg": {
    "retina": "/images/hi-res/logos/laptop-logo@2x.jpg",
    "normal": "/images/normal/logos/laptop-logo.jpg",
    "retinaWebp": "/images/hi-res-webp/logos/laptop-logo@2x.webp",
    "webp": "/images/normal-webp/logos/laptop-logo.webp"
  },
  "logos/mob-logo@2x.jpg": {
    "retina": "/images/hi-res/logos/mob-logo@2x.jpg",
    "normal": "/images/normal/logos/mob-logo.jpg",
    "retinaWebp": "/images/hi-res-webp/logos/mob-logo@2x.webp",
    "webp": "/images/normal-webp/logos/mob-logo.webp"
  },
  "mainMenu/colors/bi-color@2x.jpg": {
    "retina": "/images/hi-res/mainMenu/colors/bi-color@2x.jpg",
    "normal": "/images/normal/mainMenu/colors/bi-color.jpg",
    "retinaWebp": "/images/hi-res-webp/mainMenu/colors/bi-color@2x.webp",
    "webp": "/images/normal-webp/mainMenu/colors/bi-color.webp"
  },
  "mainMenu/colors/black@2x.jpg": {
    "retina": "/images/hi-res/mainMenu/colors/black@2x.jpg",
    "normal": "/images/normal/mainMenu/colors/black.jpg",
    "retinaWebp": "/images/hi-res-webp/mainMenu/colors/black@2x.webp",
    "webp": "/images/normal-webp/mainMenu/colors/black.webp"
  },
  "mainMenu/colors/blue@2x.jpg": {
    "retina": "/images/hi-res/mainMenu/colors/blue@2x.jpg",
    "normal": "/images/normal/mainMenu/colors/blue.jpg",
    "retinaWebp": "/images/hi-res-webp/mainMenu/colors/blue@2x.webp",
    "webp": "/images/normal-webp/mainMenu/colors/blue.webp"
  },
  "mainMenu/colors/brown@2x.jpg": {
    "retina": "/images/hi-res/mainMenu/colors/brown@2x.jpg",
    "normal": "/images/normal/mainMenu/colors/brown.jpg",
    "retinaWebp": "/images/hi-res-webp/mainMenu/colors/brown@2x.webp",
    "webp": "/images/normal-webp/mainMenu/colors/brown.webp"
  },
  "mainMenu/colors/color-change@2x.jpg": {
    "retina": "/images/hi-res/mainMenu/colors/color-change@2x.jpg",
    "normal": "/images/normal/mainMenu/colors/color-change.jpg",
    "retinaWebp": "/images/hi-res-webp/mainMenu/colors/color-change@2x.webp",
    "webp": "/images/normal-webp/mainMenu/colors/color-change.webp"
  },
  "mainMenu/colors/colorless@2x.jpg": {
    "retina": "/images/hi-res/mainMenu/colors/colorless@2x.jpg",
    "normal": "/images/normal/mainMenu/colors/colorless.jpg",
    "retinaWebp": "/images/hi-res-webp/mainMenu/colors/colorless@2x.webp",
    "webp": "/images/normal-webp/mainMenu/colors/colorless.webp"
  },
  "mainMenu/colors/golden@2x.jpg": {
    "retina": "/images/hi-res/mainMenu/colors/golden@2x.jpg",
    "normal": "/images/normal/mainMenu/colors/golden.jpg",
    "retinaWebp": "/images/hi-res-webp/mainMenu/colors/golden@2x.webp",
    "webp": "/images/normal-webp/mainMenu/colors/golden.webp"
  },
  "mainMenu/colors/gray@2x.jpg": {
    "retina": "/images/hi-res/mainMenu/colors/gray@2x.jpg",
    "normal": "/images/normal/mainMenu/colors/gray.jpg",
    "retinaWebp": "/images/hi-res-webp/mainMenu/colors/gray@2x.webp",
    "webp": "/images/normal-webp/mainMenu/colors/gray.webp"
  },
  "mainMenu/colors/green@2x.jpg": {
    "retina": "/images/hi-res/mainMenu/colors/green@2x.jpg",
    "normal": "/images/normal/mainMenu/colors/green.jpg",
    "retinaWebp": "/images/hi-res-webp/mainMenu/colors/green@2x.webp",
    "webp": "/images/normal-webp/mainMenu/colors/green.webp"
  },
  "mainMenu/colors/grey@2x.jpg": {
    "retina": "/images/hi-res/mainMenu/colors/grey@2x.jpg",
    "normal": "/images/normal/mainMenu/colors/grey.jpg",
    "retinaWebp": "/images/hi-res-webp/mainMenu/colors/grey@2x.webp",
    "webp": "/images/normal-webp/mainMenu/colors/grey.webp"
  },
  "mainMenu/colors/multi-color@2x.jpg": {
    "retina": "/images/hi-res/mainMenu/colors/multi-color@2x.jpg",
    "normal": "/images/normal/mainMenu/colors/multi-color.jpg",
    "retinaWebp": "/images/hi-res-webp/mainMenu/colors/multi-color@2x.webp",
    "webp": "/images/normal-webp/mainMenu/colors/multi-color.webp"
  },
  "mainMenu/colors/orange@2x.jpg": {
    "retina": "/images/hi-res/mainMenu/colors/orange@2x.jpg",
    "normal": "/images/normal/mainMenu/colors/orange.jpg",
    "retinaWebp": "/images/hi-res-webp/mainMenu/colors/orange@2x.webp",
    "webp": "/images/normal-webp/mainMenu/colors/orange.webp"
  },
  "mainMenu/colors/pink@2x.jpg": {
    "retina": "/images/hi-res/mainMenu/colors/pink@2x.jpg",
    "normal": "/images/normal/mainMenu/colors/pink.jpg",
    "retinaWebp": "/images/hi-res-webp/mainMenu/colors/pink@2x.webp",
    "webp": "/images/normal-webp/mainMenu/colors/pink.webp"
  },
  "mainMenu/colors/purple@2x.jpg": {
    "retina": "/images/hi-res/mainMenu/colors/purple@2x.jpg",
    "normal": "/images/normal/mainMenu/colors/purple.jpg",
    "retinaWebp": "/images/hi-res-webp/mainMenu/colors/purple@2x.webp",
    "webp": "/images/normal-webp/mainMenu/colors/purple.webp"
  },
  "mainMenu/colors/rainbow@2x.jpg": {
    "retina": "/images/hi-res/mainMenu/colors/rainbow@2x.jpg",
    "normal": "/images/normal/mainMenu/colors/rainbow.jpg",
    "retinaWebp": "/images/hi-res-webp/mainMenu/colors/rainbow@2x.webp",
    "webp": "/images/normal-webp/mainMenu/colors/rainbow.webp"
  },
  "mainMenu/colors/red@2x.jpg": {
    "retina": "/images/hi-res/mainMenu/colors/red@2x.jpg",
    "normal": "/images/normal/mainMenu/colors/red.jpg",
    "retinaWebp": "/images/hi-res-webp/mainMenu/colors/red@2x.webp",
    "webp": "/images/normal-webp/mainMenu/colors/red.webp"
  },
  "mainMenu/colors/white@2x.jpg": {
    "retina": "/images/hi-res/mainMenu/colors/white@2x.jpg",
    "normal": "/images/normal/mainMenu/colors/white.jpg",
    "retinaWebp": "/images/hi-res-webp/mainMenu/colors/white@2x.webp",
    "webp": "/images/normal-webp/mainMenu/colors/white.webp"
  },
  "mainMenu/colors/yellow@2x.jpg": {
    "retina": "/images/hi-res/mainMenu/colors/yellow@2x.jpg",
    "normal": "/images/normal/mainMenu/colors/yellow.jpg",
    "retinaWebp": "/images/hi-res-webp/mainMenu/colors/yellow@2x.webp",
    "webp": "/images/normal-webp/mainMenu/colors/yellow.webp"
  },
  "mainMenu/jewelry/bracelets@2x.png": {
    "retina": "/images/hi-res/mainMenu/jewelry/bracelets@2x.png",
    "normal": "/images/normal/mainMenu/jewelry/bracelets.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/jewelry/bracelets@2x.webp",
    "webp": "/images/normal-webp/mainMenu/jewelry/bracelets.webp"
  },
  "mainMenu/jewelry/brooches@2x.png": {
    "retina": "/images/hi-res/mainMenu/jewelry/brooches@2x.png",
    "normal": "/images/normal/mainMenu/jewelry/brooches.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/jewelry/brooches@2x.webp",
    "webp": "/images/normal-webp/mainMenu/jewelry/brooches.webp"
  },
  "mainMenu/jewelry/cufflinks@2x.png": {
    "retina": "/images/hi-res/mainMenu/jewelry/cufflinks@2x.png",
    "normal": "/images/normal/mainMenu/jewelry/cufflinks.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/jewelry/cufflinks@2x.webp",
    "webp": "/images/normal-webp/mainMenu/jewelry/cufflinks.webp"
  },
  "mainMenu/jewelry/earrings@2x.png": {
    "retina": "/images/hi-res/mainMenu/jewelry/earrings@2x.png",
    "normal": "/images/normal/mainMenu/jewelry/earrings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/jewelry/earrings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/jewelry/earrings.webp"
  },
  "mainMenu/jewelry/mensRings@2x.png": {
    "retina": "/images/hi-res/mainMenu/jewelry/mensRings@2x.png",
    "normal": "/images/normal/mainMenu/jewelry/mensRings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/jewelry/mensRings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/jewelry/mensRings.webp"
  },
  "mainMenu/jewelry/necklacesPendants@2x.png": {
    "retina": "/images/hi-res/mainMenu/jewelry/necklacesPendants@2x.png",
    "normal": "/images/normal/mainMenu/jewelry/necklacesPendants.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/jewelry/necklacesPendants@2x.webp",
    "webp": "/images/normal-webp/mainMenu/jewelry/necklacesPendants.webp"
  },
  "mainMenu/jewelry/rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/jewelry/rings@2x.png",
    "normal": "/images/normal/mainMenu/jewelry/rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/jewelry/rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/jewelry/rings.webp"
  },
  "mainMenu/pairs/agate-pairs@2x.png": {
    "retina": "/images/hi-res/mainMenu/pairs/agate-pairs@2x.png",
    "normal": "/images/normal/mainMenu/pairs/agate-pairs.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/agate-pairs@2x.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/agate-pairs.webp"
  },
  "mainMenu/pairs/alexandrite-pairs@2x.png": {
    "retina": "/images/hi-res/mainMenu/pairs/alexandrite-pairs@2x.png",
    "normal": "/images/normal/mainMenu/pairs/alexandrite-pairs.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/alexandrite-pairs@2x.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/alexandrite-pairs.webp"
  },
  "mainMenu/pairs/almandine-garnet-pairs@2x.png": {
    "retina": "/images/hi-res/mainMenu/pairs/almandine-garnet-pairs@2x.png",
    "normal": "/images/normal/mainMenu/pairs/almandine-garnet-pairs.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/almandine-garnet-pairs@2x.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/almandine-garnet-pairs.webp"
  },
  "mainMenu/pairs/amethyst-pairs@2x.png": {
    "retina": "/images/hi-res/mainMenu/pairs/amethyst-pairs@2x.png",
    "normal": "/images/normal/mainMenu/pairs/amethyst-pairs.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/amethyst-pairs@2x.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/amethyst-pairs.webp"
  },
  "mainMenu/pairs/ametrine-pairs@2x.png": {
    "retina": "/images/hi-res/mainMenu/pairs/ametrine-pairs@2x.png",
    "normal": "/images/normal/mainMenu/pairs/ametrine-pairs.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/ametrine-pairs@2x.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/ametrine-pairs.webp"
  },
  "mainMenu/pairs/andalusite-pairs@2x.png": {
    "retina": "/images/hi-res/mainMenu/pairs/andalusite-pairs@2x.png",
    "normal": "/images/normal/mainMenu/pairs/andalusite-pairs.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/andalusite-pairs@2x.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/andalusite-pairs.webp"
  },
  "mainMenu/pairs/andesine-pairs@2x.png": {
    "retina": "/images/hi-res/mainMenu/pairs/andesine-pairs@2x.png",
    "normal": "/images/normal/mainMenu/pairs/andesine-pairs.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/andesine-pairs@2x.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/andesine-pairs.webp"
  },
  "mainMenu/pairs/andradite-garnet-pairs@2x.png": {
    "retina": "/images/hi-res/mainMenu/pairs/andradite-garnet-pairs@2x.png",
    "normal": "/images/normal/mainMenu/pairs/andradite-garnet-pairs.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/andradite-garnet-pairs@2x.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/andradite-garnet-pairs.webp"
  },
  "mainMenu/pairs/apatite-pairs@2x copy.png": {
    "retina": "/images/hi-res/mainMenu/pairs/apatite-pairs@2x copy.png",
    "normal": "/images/normal/mainMenu/pairs/apatite-pairs copy.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/apatite-pairs@2x copy.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/apatite-pairs copy.webp"
  },
  "mainMenu/pairs/apatite-pairs@2x.png": {
    "retina": "/images/hi-res/mainMenu/pairs/apatite-pairs@2x.png",
    "normal": "/images/normal/mainMenu/pairs/apatite-pairs.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/apatite-pairs@2x.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/apatite-pairs.webp"
  },
  "mainMenu/pairs/aquamarine-pairs@2x.png": {
    "retina": "/images/hi-res/mainMenu/pairs/aquamarine-pairs@2x.png",
    "normal": "/images/normal/mainMenu/pairs/aquamarine-pairs.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/aquamarine-pairs@2x.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/aquamarine-pairs.webp"
  },
  "mainMenu/pairs/beryl-pairs@2x.png": {
    "retina": "/images/hi-res/mainMenu/pairs/beryl-pairs@2x.png",
    "normal": "/images/normal/mainMenu/pairs/beryl-pairs.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/beryl-pairs@2x.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/beryl-pairs.webp"
  },
  "mainMenu/pairs/carnelian-pairs@2x copy.png": {
    "retina": "/images/hi-res/mainMenu/pairs/carnelian-pairs@2x copy.png",
    "normal": "/images/normal/mainMenu/pairs/carnelian-pairs copy.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/carnelian-pairs@2x copy.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/carnelian-pairs copy.webp"
  },
  "mainMenu/pairs/carnelian-pairs@2x.png": {
    "retina": "/images/hi-res/mainMenu/pairs/carnelian-pairs@2x.png",
    "normal": "/images/normal/mainMenu/pairs/carnelian-pairs.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/carnelian-pairs@2x.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/carnelian-pairs.webp"
  },
  "mainMenu/pairs/chrome-diopside-pairs@2x.png": {
    "retina": "/images/hi-res/mainMenu/pairs/chrome-diopside-pairs@2x.png",
    "normal": "/images/normal/mainMenu/pairs/chrome-diopside-pairs.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/chrome-diopside-pairs@2x.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/chrome-diopside-pairs.webp"
  },
  "mainMenu/pairs/chrome-tourmaline-pairs@2x.png": {
    "retina": "/images/hi-res/mainMenu/pairs/chrome-tourmaline-pairs@2x.png",
    "normal": "/images/normal/mainMenu/pairs/chrome-tourmaline-pairs.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/chrome-tourmaline-pairs@2x.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/chrome-tourmaline-pairs.webp"
  },
  "mainMenu/pairs/chrysoberyl-pairs@2x.png": {
    "retina": "/images/hi-res/mainMenu/pairs/chrysoberyl-pairs@2x.png",
    "normal": "/images/normal/mainMenu/pairs/chrysoberyl-pairs.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/chrysoberyl-pairs@2x.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/chrysoberyl-pairs.webp"
  },
  "mainMenu/pairs/citrine-pairs@2x.png": {
    "retina": "/images/hi-res/mainMenu/pairs/citrine-pairs@2x.png",
    "normal": "/images/normal/mainMenu/pairs/citrine-pairs.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/citrine-pairs@2x.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/citrine-pairs.webp"
  },
  "mainMenu/pairs/cobalt-spinel-pairs@2x.png": {
    "retina": "/images/hi-res/mainMenu/pairs/cobalt-spinel-pairs@2x.png",
    "normal": "/images/normal/mainMenu/pairs/cobalt-spinel-pairs.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/cobalt-spinel-pairs@2x.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/cobalt-spinel-pairs.webp"
  },
  "mainMenu/pairs/coral-pairs@2x.png": {
    "retina": "/images/hi-res/mainMenu/pairs/coral-pairs@2x.png",
    "normal": "/images/normal/mainMenu/pairs/coral-pairs.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/coral-pairs@2x.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/coral-pairs.webp"
  },
  "mainMenu/pairs/demantoid-garnet-pairs@2x.png": {
    "retina": "/images/hi-res/mainMenu/pairs/demantoid-garnet-pairs@2x.png",
    "normal": "/images/normal/mainMenu/pairs/demantoid-garnet-pairs.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/demantoid-garnet-pairs@2x.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/demantoid-garnet-pairs.webp"
  },
  "mainMenu/pairs/fluorite-pairs@2x copy.png": {
    "retina": "/images/hi-res/mainMenu/pairs/fluorite-pairs@2x copy.png",
    "normal": "/images/normal/mainMenu/pairs/fluorite-pairs copy.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/fluorite-pairs@2x copy.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/fluorite-pairs copy.webp"
  },
  "mainMenu/pairs/fluorite-pairs@2x.png": {
    "retina": "/images/hi-res/mainMenu/pairs/fluorite-pairs@2x.png",
    "normal": "/images/normal/mainMenu/pairs/fluorite-pairs.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/fluorite-pairs@2x.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/fluorite-pairs.webp"
  },
  "mainMenu/pairs/garnet-pairs@2x.png": {
    "retina": "/images/hi-res/mainMenu/pairs/garnet-pairs@2x.png",
    "normal": "/images/normal/mainMenu/pairs/garnet-pairs.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/garnet-pairs@2x.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/garnet-pairs.webp"
  },
  "mainMenu/pairs/grossular-garnet-pairs@2x.png": {
    "retina": "/images/hi-res/mainMenu/pairs/grossular-garnet-pairs@2x.png",
    "normal": "/images/normal/mainMenu/pairs/grossular-garnet-pairs.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/grossular-garnet-pairs@2x.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/grossular-garnet-pairs.webp"
  },
  "mainMenu/pairs/hessonite-garnet-pairs@2x.png": {
    "retina": "/images/hi-res/mainMenu/pairs/hessonite-garnet-pairs@2x.png",
    "normal": "/images/normal/mainMenu/pairs/hessonite-garnet-pairs.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/hessonite-garnet-pairs@2x.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/hessonite-garnet-pairs.webp"
  },
  "mainMenu/pairs/jade-pairs@2x.png": {
    "retina": "/images/hi-res/mainMenu/pairs/jade-pairs@2x.png",
    "normal": "/images/normal/mainMenu/pairs/jade-pairs.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/jade-pairs@2x.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/jade-pairs.webp"
  },
  "mainMenu/pairs/jeremejevite-pairs@2x.png": {
    "retina": "/images/hi-res/mainMenu/pairs/jeremejevite-pairs@2x.png",
    "normal": "/images/normal/mainMenu/pairs/jeremejevite-pairs.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/jeremejevite-pairs@2x.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/jeremejevite-pairs.webp"
  },
  "mainMenu/pairs/kornerupine-pairs@2x.png": {
    "retina": "/images/hi-res/mainMenu/pairs/kornerupine-pairs@2x.png",
    "normal": "/images/normal/mainMenu/pairs/kornerupine-pairs.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/kornerupine-pairs@2x.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/kornerupine-pairs.webp"
  },
  "mainMenu/pairs/kunzite-pairs@2x.png": {
    "retina": "/images/hi-res/mainMenu/pairs/kunzite-pairs@2x.png",
    "normal": "/images/normal/mainMenu/pairs/kunzite-pairs.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/kunzite-pairs@2x.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/kunzite-pairs.webp"
  },
  "mainMenu/pairs/kyanite-pairs@2x.png": {
    "retina": "/images/hi-res/mainMenu/pairs/kyanite-pairs@2x.png",
    "normal": "/images/normal/mainMenu/pairs/kyanite-pairs.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/kyanite-pairs@2x.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/kyanite-pairs.webp"
  },
  "mainMenu/pairs/lapis-pairs@2x.png": {
    "retina": "/images/hi-res/mainMenu/pairs/lapis-pairs@2x.png",
    "normal": "/images/normal/mainMenu/pairs/lapis-pairs.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/lapis-pairs@2x.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/lapis-pairs.webp"
  },
  "mainMenu/pairs/lolite-pairs@2x.png": {
    "retina": "/images/hi-res/mainMenu/pairs/lolite-pairs@2x.png",
    "normal": "/images/normal/mainMenu/pairs/lolite-pairs.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/lolite-pairs@2x.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/lolite-pairs.webp"
  },
  "mainMenu/pairs/madeira-citrine-pairs@2x.png": {
    "retina": "/images/hi-res/mainMenu/pairs/madeira-citrine-pairs@2x.png",
    "normal": "/images/normal/mainMenu/pairs/madeira-citrine-pairs.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/madeira-citrine-pairs@2x.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/madeira-citrine-pairs.webp"
  },
  "mainMenu/pairs/malachite-pairs@2x.png": {
    "retina": "/images/hi-res/mainMenu/pairs/malachite-pairs@2x.png",
    "normal": "/images/normal/mainMenu/pairs/malachite-pairs.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/malachite-pairs@2x.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/malachite-pairs.webp"
  },
  "mainMenu/pairs/malaya-garnet-pairs@2x.png": {
    "retina": "/images/hi-res/mainMenu/pairs/malaya-garnet-pairs@2x.png",
    "normal": "/images/normal/mainMenu/pairs/malaya-garnet-pairs.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/malaya-garnet-pairs@2x.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/malaya-garnet-pairs.webp"
  },
  "mainMenu/pairs/mali-garnet-pairs@2x.png": {
    "retina": "/images/hi-res/mainMenu/pairs/mali-garnet-pairs@2x.png",
    "normal": "/images/normal/mainMenu/pairs/mali-garnet-pairs.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/mali-garnet-pairs@2x.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/mali-garnet-pairs.webp"
  },
  "mainMenu/pairs/moonstone-pairs@2x.png": {
    "retina": "/images/hi-res/mainMenu/pairs/moonstone-pairs@2x.png",
    "normal": "/images/normal/mainMenu/pairs/moonstone-pairs.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/moonstone-pairs@2x.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/moonstone-pairs.webp"
  },
  "mainMenu/pairs/morganite-pairs@2x.png": {
    "retina": "/images/hi-res/mainMenu/pairs/morganite-pairs@2x.png",
    "normal": "/images/normal/mainMenu/pairs/morganite-pairs.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/morganite-pairs@2x.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/morganite-pairs.webp"
  },
  "mainMenu/pairs/onyx-pairs@2x.png": {
    "retina": "/images/hi-res/mainMenu/pairs/onyx-pairs@2x.png",
    "normal": "/images/normal/mainMenu/pairs/onyx-pairs.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/onyx-pairs@2x.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/onyx-pairs.webp"
  },
  "mainMenu/pairs/opal-pairs@2x.png": {
    "retina": "/images/hi-res/mainMenu/pairs/opal-pairs@2x.png",
    "normal": "/images/normal/mainMenu/pairs/opal-pairs.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/opal-pairs@2x.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/opal-pairs.webp"
  },
  "mainMenu/pairs/paraiba-tourmaline-pairs@2x.png": {
    "retina": "/images/hi-res/mainMenu/pairs/paraiba-tourmaline-pairs@2x.png",
    "normal": "/images/normal/mainMenu/pairs/paraiba-tourmaline-pairs.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/paraiba-tourmaline-pairs@2x.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/paraiba-tourmaline-pairs.webp"
  },
  "mainMenu/pairs/pearl-pairs@2x.png": {
    "retina": "/images/hi-res/mainMenu/pairs/pearl-pairs@2x.png",
    "normal": "/images/normal/mainMenu/pairs/pearl-pairs.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/pearl-pairs@2x.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/pearl-pairs.webp"
  },
  "mainMenu/pairs/peridot-pairs@2x.png": {
    "retina": "/images/hi-res/mainMenu/pairs/peridot-pairs@2x.png",
    "normal": "/images/normal/mainMenu/pairs/peridot-pairs.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/peridot-pairs@2x.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/peridot-pairs.webp"
  },
  "mainMenu/pairs/quartz-pairs@2x.png": {
    "retina": "/images/hi-res/mainMenu/pairs/quartz-pairs@2x.png",
    "normal": "/images/normal/mainMenu/pairs/quartz-pairs.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/quartz-pairs@2x.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/quartz-pairs.webp"
  },
  "mainMenu/pairs/rhodochrosite-pairs@2x.png": {
    "retina": "/images/hi-res/mainMenu/pairs/rhodochrosite-pairs@2x.png",
    "normal": "/images/normal/mainMenu/pairs/rhodochrosite-pairs.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/rhodochrosite-pairs@2x.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/rhodochrosite-pairs.webp"
  },
  "mainMenu/pairs/rhodolite-garnet-pairs@2x.png": {
    "retina": "/images/hi-res/mainMenu/pairs/rhodolite-garnet-pairs@2x.png",
    "normal": "/images/normal/mainMenu/pairs/rhodolite-garnet-pairs.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/rhodolite-garnet-pairs@2x.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/rhodolite-garnet-pairs.webp"
  },
  "mainMenu/pairs/rose-quartz-pairs@2x.png": {
    "retina": "/images/hi-res/mainMenu/pairs/rose-quartz-pairs@2x.png",
    "normal": "/images/normal/mainMenu/pairs/rose-quartz-pairs.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/rose-quartz-pairs@2x.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/rose-quartz-pairs.webp"
  },
  "mainMenu/pairs/rubellite-tourmaline-pairs@2x.png": {
    "retina": "/images/hi-res/mainMenu/pairs/rubellite-tourmaline-pairs@2x.png",
    "normal": "/images/normal/mainMenu/pairs/rubellite-tourmaline-pairs.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/rubellite-tourmaline-pairs@2x.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/rubellite-tourmaline-pairs.webp"
  },
  "mainMenu/pairs/rutilated-quartz-pairs@2x.png": {
    "retina": "/images/hi-res/mainMenu/pairs/rutilated-quartz-pairs@2x.png",
    "normal": "/images/normal/mainMenu/pairs/rutilated-quartz-pairs.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/rutilated-quartz-pairs@2x.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/rutilated-quartz-pairs.webp"
  },
  "mainMenu/pairs/scapolite-pairs@2x.png": {
    "retina": "/images/hi-res/mainMenu/pairs/scapolite-pairs@2x.png",
    "normal": "/images/normal/mainMenu/pairs/scapolite-pairs.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/scapolite-pairs@2x.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/scapolite-pairs.webp"
  },
  "mainMenu/pairs/sinhalite-pairs@2x.png": {
    "retina": "/images/hi-res/mainMenu/pairs/sinhalite-pairs@2x.png",
    "normal": "/images/normal/mainMenu/pairs/sinhalite-pairs.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/sinhalite-pairs@2x.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/sinhalite-pairs.webp"
  },
  "mainMenu/pairs/smokey-quartz-pairs@2x.png": {
    "retina": "/images/hi-res/mainMenu/pairs/smokey-quartz-pairs@2x.png",
    "normal": "/images/normal/mainMenu/pairs/smokey-quartz-pairs.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/smokey-quartz-pairs@2x.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/smokey-quartz-pairs.webp"
  },
  "mainMenu/pairs/spessartite-garnet-pairs@2x.png": {
    "retina": "/images/hi-res/mainMenu/pairs/spessartite-garnet-pairs@2x.png",
    "normal": "/images/normal/mainMenu/pairs/spessartite-garnet-pairs.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/spessartite-garnet-pairs@2x.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/spessartite-garnet-pairs.webp"
  },
  "mainMenu/pairs/sphene-titanite-pairs@2x.png": {
    "retina": "/images/hi-res/mainMenu/pairs/sphene-titanite-pairs@2x.png",
    "normal": "/images/normal/mainMenu/pairs/sphene-titanite-pairs.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/sphene-titanite-pairs@2x.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/sphene-titanite-pairs.webp"
  },
  "mainMenu/pairs/spinel-pairs@2x.png": {
    "retina": "/images/hi-res/mainMenu/pairs/spinel-pairs@2x.png",
    "normal": "/images/normal/mainMenu/pairs/spinel-pairs.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/spinel-pairs@2x.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/spinel-pairs.webp"
  },
  "mainMenu/pairs/taaffeite-pairs@2x.png": {
    "retina": "/images/hi-res/mainMenu/pairs/taaffeite-pairs@2x.png",
    "normal": "/images/normal/mainMenu/pairs/taaffeite-pairs.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/taaffeite-pairs@2x.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/taaffeite-pairs.webp"
  },
  "mainMenu/pairs/tanzanite-pairs@2x.png": {
    "retina": "/images/hi-res/mainMenu/pairs/tanzanite-pairs@2x.png",
    "normal": "/images/normal/mainMenu/pairs/tanzanite-pairs.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/tanzanite-pairs@2x.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/tanzanite-pairs.webp"
  },
  "mainMenu/pairs/tiger-eye-pairs@2x.png": {
    "retina": "/images/hi-res/mainMenu/pairs/tiger-eye-pairs@2x.png",
    "normal": "/images/normal/mainMenu/pairs/tiger-eye-pairs.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/tiger-eye-pairs@2x.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/tiger-eye-pairs.webp"
  },
  "mainMenu/pairs/topaz-pairs@2x.png": {
    "retina": "/images/hi-res/mainMenu/pairs/topaz-pairs@2x.png",
    "normal": "/images/normal/mainMenu/pairs/topaz-pairs.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/topaz-pairs@2x.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/topaz-pairs.webp"
  },
  "mainMenu/pairs/tourmaline-pairs@2x.png": {
    "retina": "/images/hi-res/mainMenu/pairs/tourmaline-pairs@2x.png",
    "normal": "/images/normal/mainMenu/pairs/tourmaline-pairs.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/tourmaline-pairs@2x.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/tourmaline-pairs.webp"
  },
  "mainMenu/pairs/tsavorite-garnet-pairs@2x.png": {
    "retina": "/images/hi-res/mainMenu/pairs/tsavorite-garnet-pairs@2x.png",
    "normal": "/images/normal/mainMenu/pairs/tsavorite-garnet-pairs.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/tsavorite-garnet-pairs@2x.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/tsavorite-garnet-pairs.webp"
  },
  "mainMenu/pairs/turquoise-pairs@2x.png": {
    "retina": "/images/hi-res/mainMenu/pairs/turquoise-pairs@2x.png",
    "normal": "/images/normal/mainMenu/pairs/turquoise-pairs.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/turquoise-pairs@2x.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/turquoise-pairs.webp"
  },
  "mainMenu/pairs/zircon-pairs@2x.png": {
    "retina": "/images/hi-res/mainMenu/pairs/zircon-pairs@2x.png",
    "normal": "/images/normal/mainMenu/pairs/zircon-pairs.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/pairs/zircon-pairs@2x.webp",
    "webp": "/images/normal-webp/mainMenu/pairs/zircon-pairs.webp"
  },
  "mainMenu/rings/agate-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/rings/agate-rings@2x.png",
    "normal": "/images/normal/mainMenu/rings/agate-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/agate-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/rings/agate-rings.webp"
  },
  "mainMenu/rings/alexandrite-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/rings/alexandrite-rings@2x.png",
    "normal": "/images/normal/mainMenu/rings/alexandrite-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/alexandrite-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/rings/alexandrite-rings.webp"
  },
  "mainMenu/rings/almandine-garnet-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/rings/almandine-garnet-rings@2x.png",
    "normal": "/images/normal/mainMenu/rings/almandine-garnet-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/almandine-garnet-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/rings/almandine-garnet-rings.webp"
  },
  "mainMenu/rings/amethyst-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/rings/amethyst-rings@2x.png",
    "normal": "/images/normal/mainMenu/rings/amethyst-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/amethyst-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/rings/amethyst-rings.webp"
  },
  "mainMenu/rings/ametrine-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/rings/ametrine-rings@2x.png",
    "normal": "/images/normal/mainMenu/rings/ametrine-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/ametrine-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/rings/ametrine-rings.webp"
  },
  "mainMenu/rings/andalusite-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/rings/andalusite-rings@2x.png",
    "normal": "/images/normal/mainMenu/rings/andalusite-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/andalusite-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/rings/andalusite-rings.webp"
  },
  "mainMenu/rings/andesine-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/rings/andesine-rings@2x.png",
    "normal": "/images/normal/mainMenu/rings/andesine-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/andesine-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/rings/andesine-rings.webp"
  },
  "mainMenu/rings/andradite-garnet-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/rings/andradite-garnet-rings@2x.png",
    "normal": "/images/normal/mainMenu/rings/andradite-garnet-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/andradite-garnet-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/rings/andradite-garnet-rings.webp"
  },
  "mainMenu/rings/apatite-rings@2x copy.png": {
    "retina": "/images/hi-res/mainMenu/rings/apatite-rings@2x copy.png",
    "normal": "/images/normal/mainMenu/rings/apatite-rings copy.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/apatite-rings@2x copy.webp",
    "webp": "/images/normal-webp/mainMenu/rings/apatite-rings copy.webp"
  },
  "mainMenu/rings/apatite-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/rings/apatite-rings@2x.png",
    "normal": "/images/normal/mainMenu/rings/apatite-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/apatite-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/rings/apatite-rings.webp"
  },
  "mainMenu/rings/aquamarine-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/rings/aquamarine-rings@2x.png",
    "normal": "/images/normal/mainMenu/rings/aquamarine-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/aquamarine-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/rings/aquamarine-rings.webp"
  },
  "mainMenu/rings/beryl-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/rings/beryl-rings@2x.png",
    "normal": "/images/normal/mainMenu/rings/beryl-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/beryl-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/rings/beryl-rings.webp"
  },
  "mainMenu/rings/carnelian-rings@2x copy.png": {
    "retina": "/images/hi-res/mainMenu/rings/carnelian-rings@2x copy.png",
    "normal": "/images/normal/mainMenu/rings/carnelian-rings copy.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/carnelian-rings@2x copy.webp",
    "webp": "/images/normal-webp/mainMenu/rings/carnelian-rings copy.webp"
  },
  "mainMenu/rings/carnelian-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/rings/carnelian-rings@2x.png",
    "normal": "/images/normal/mainMenu/rings/carnelian-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/carnelian-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/rings/carnelian-rings.webp"
  },
  "mainMenu/rings/chrome-diopside-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/rings/chrome-diopside-rings@2x.png",
    "normal": "/images/normal/mainMenu/rings/chrome-diopside-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/chrome-diopside-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/rings/chrome-diopside-rings.webp"
  },
  "mainMenu/rings/chrome-tourmaline-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/rings/chrome-tourmaline-rings@2x.png",
    "normal": "/images/normal/mainMenu/rings/chrome-tourmaline-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/chrome-tourmaline-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/rings/chrome-tourmaline-rings.webp"
  },
  "mainMenu/rings/chrysoberyl-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/rings/chrysoberyl-rings@2x.png",
    "normal": "/images/normal/mainMenu/rings/chrysoberyl-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/chrysoberyl-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/rings/chrysoberyl-rings.webp"
  },
  "mainMenu/rings/citrine-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/rings/citrine-rings@2x.png",
    "normal": "/images/normal/mainMenu/rings/citrine-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/citrine-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/rings/citrine-rings.webp"
  },
  "mainMenu/rings/cobalt-spinel-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/rings/cobalt-spinel-rings@2x.png",
    "normal": "/images/normal/mainMenu/rings/cobalt-spinel-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/cobalt-spinel-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/rings/cobalt-spinel-rings.webp"
  },
  "mainMenu/rings/coral-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/rings/coral-rings@2x.png",
    "normal": "/images/normal/mainMenu/rings/coral-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/coral-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/rings/coral-rings.webp"
  },
  "mainMenu/rings/demantoid-garnet-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/rings/demantoid-garnet-rings@2x.png",
    "normal": "/images/normal/mainMenu/rings/demantoid-garnet-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/demantoid-garnet-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/rings/demantoid-garnet-rings.webp"
  },
  "mainMenu/rings/fluorite-rings@2x copy.png": {
    "retina": "/images/hi-res/mainMenu/rings/fluorite-rings@2x copy.png",
    "normal": "/images/normal/mainMenu/rings/fluorite-rings copy.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/fluorite-rings@2x copy.webp",
    "webp": "/images/normal-webp/mainMenu/rings/fluorite-rings copy.webp"
  },
  "mainMenu/rings/fluorite-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/rings/fluorite-rings@2x.png",
    "normal": "/images/normal/mainMenu/rings/fluorite-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/fluorite-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/rings/fluorite-rings.webp"
  },
  "mainMenu/rings/garnet-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/rings/garnet-rings@2x.png",
    "normal": "/images/normal/mainMenu/rings/garnet-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/garnet-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/rings/garnet-rings.webp"
  },
  "mainMenu/rings/grossular-garnet-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/rings/grossular-garnet-rings@2x.png",
    "normal": "/images/normal/mainMenu/rings/grossular-garnet-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/grossular-garnet-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/rings/grossular-garnet-rings.webp"
  },
  "mainMenu/rings/hessonite-garnet-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/rings/hessonite-garnet-rings@2x.png",
    "normal": "/images/normal/mainMenu/rings/hessonite-garnet-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/hessonite-garnet-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/rings/hessonite-garnet-rings.webp"
  },
  "mainMenu/rings/iolite-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/rings/iolite-rings@2x.png",
    "normal": "/images/normal/mainMenu/rings/iolite-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/iolite-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/rings/iolite-rings.webp"
  },
  "mainMenu/rings/jade-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/rings/jade-rings@2x.png",
    "normal": "/images/normal/mainMenu/rings/jade-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/jade-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/rings/jade-rings.webp"
  },
  "mainMenu/rings/jeremejevite-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/rings/jeremejevite-rings@2x.png",
    "normal": "/images/normal/mainMenu/rings/jeremejevite-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/jeremejevite-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/rings/jeremejevite-rings.webp"
  },
  "mainMenu/rings/kornerupine-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/rings/kornerupine-rings@2x.png",
    "normal": "/images/normal/mainMenu/rings/kornerupine-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/kornerupine-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/rings/kornerupine-rings.webp"
  },
  "mainMenu/rings/kunzite-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/rings/kunzite-rings@2x.png",
    "normal": "/images/normal/mainMenu/rings/kunzite-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/kunzite-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/rings/kunzite-rings.webp"
  },
  "mainMenu/rings/kyanite-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/rings/kyanite-rings@2x.png",
    "normal": "/images/normal/mainMenu/rings/kyanite-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/kyanite-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/rings/kyanite-rings.webp"
  },
  "mainMenu/rings/lapis-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/rings/lapis-rings@2x.png",
    "normal": "/images/normal/mainMenu/rings/lapis-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/lapis-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/rings/lapis-rings.webp"
  },
  "mainMenu/rings/madeira-citrine-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/rings/madeira-citrine-rings@2x.png",
    "normal": "/images/normal/mainMenu/rings/madeira-citrine-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/madeira-citrine-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/rings/madeira-citrine-rings.webp"
  },
  "mainMenu/rings/malachite-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/rings/malachite-rings@2x.png",
    "normal": "/images/normal/mainMenu/rings/malachite-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/malachite-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/rings/malachite-rings.webp"
  },
  "mainMenu/rings/malaya-garnet-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/rings/malaya-garnet-rings@2x.png",
    "normal": "/images/normal/mainMenu/rings/malaya-garnet-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/malaya-garnet-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/rings/malaya-garnet-rings.webp"
  },
  "mainMenu/rings/mali-garnet-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/rings/mali-garnet-rings@2x.png",
    "normal": "/images/normal/mainMenu/rings/mali-garnet-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/mali-garnet-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/rings/mali-garnet-rings.webp"
  },
  "mainMenu/rings/moonstone-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/rings/moonstone-rings@2x.png",
    "normal": "/images/normal/mainMenu/rings/moonstone-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/moonstone-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/rings/moonstone-rings.webp"
  },
  "mainMenu/rings/morganite-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/rings/morganite-rings@2x.png",
    "normal": "/images/normal/mainMenu/rings/morganite-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/morganite-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/rings/morganite-rings.webp"
  },
  "mainMenu/rings/onyx-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/rings/onyx-rings@2x.png",
    "normal": "/images/normal/mainMenu/rings/onyx-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/onyx-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/rings/onyx-rings.webp"
  },
  "mainMenu/rings/opal-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/rings/opal-rings@2x.png",
    "normal": "/images/normal/mainMenu/rings/opal-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/opal-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/rings/opal-rings.webp"
  },
  "mainMenu/rings/paraiba-tourmaline-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/rings/paraiba-tourmaline-rings@2x.png",
    "normal": "/images/normal/mainMenu/rings/paraiba-tourmaline-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/paraiba-tourmaline-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/rings/paraiba-tourmaline-rings.webp"
  },
  "mainMenu/rings/pearl-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/rings/pearl-rings@2x.png",
    "normal": "/images/normal/mainMenu/rings/pearl-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/pearl-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/rings/pearl-rings.webp"
  },
  "mainMenu/rings/peridot-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/rings/peridot-rings@2x.png",
    "normal": "/images/normal/mainMenu/rings/peridot-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/peridot-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/rings/peridot-rings.webp"
  },
  "mainMenu/rings/phenakite-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/rings/phenakite-rings@2x.png",
    "normal": "/images/normal/mainMenu/rings/phenakite-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/phenakite-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/rings/phenakite-rings.webp"
  },
  "mainMenu/rings/quartz-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/rings/quartz-rings@2x.png",
    "normal": "/images/normal/mainMenu/rings/quartz-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/quartz-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/rings/quartz-rings.webp"
  },
  "mainMenu/rings/rhodochrosite-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/rings/rhodochrosite-rings@2x.png",
    "normal": "/images/normal/mainMenu/rings/rhodochrosite-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/rhodochrosite-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/rings/rhodochrosite-rings.webp"
  },
  "mainMenu/rings/rhodolite-garnet-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/rings/rhodolite-garnet-rings@2x.png",
    "normal": "/images/normal/mainMenu/rings/rhodolite-garnet-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/rhodolite-garnet-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/rings/rhodolite-garnet-rings.webp"
  },
  "mainMenu/rings/rose-quartz-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/rings/rose-quartz-rings@2x.png",
    "normal": "/images/normal/mainMenu/rings/rose-quartz-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/rose-quartz-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/rings/rose-quartz-rings.webp"
  },
  "mainMenu/rings/rubellite-tourmaline-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/rings/rubellite-tourmaline-rings@2x.png",
    "normal": "/images/normal/mainMenu/rings/rubellite-tourmaline-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/rubellite-tourmaline-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/rings/rubellite-tourmaline-rings.webp"
  },
  "mainMenu/rings/rutilated-quartz-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/rings/rutilated-quartz-rings@2x.png",
    "normal": "/images/normal/mainMenu/rings/rutilated-quartz-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/rutilated-quartz-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/rings/rutilated-quartz-rings.webp"
  },
  "mainMenu/rings/scapolite-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/rings/scapolite-rings@2x.png",
    "normal": "/images/normal/mainMenu/rings/scapolite-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/scapolite-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/rings/scapolite-rings.webp"
  },
  "mainMenu/rings/sinhalite-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/rings/sinhalite-rings@2x.png",
    "normal": "/images/normal/mainMenu/rings/sinhalite-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/sinhalite-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/rings/sinhalite-rings.webp"
  },
  "mainMenu/rings/smokey-quartz-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/rings/smokey-quartz-rings@2x.png",
    "normal": "/images/normal/mainMenu/rings/smokey-quartz-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/smokey-quartz-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/rings/smokey-quartz-rings.webp"
  },
  "mainMenu/rings/spessartite-garnet-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/rings/spessartite-garnet-rings@2x.png",
    "normal": "/images/normal/mainMenu/rings/spessartite-garnet-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/spessartite-garnet-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/rings/spessartite-garnet-rings.webp"
  },
  "mainMenu/rings/sphene-titanite-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/rings/sphene-titanite-rings@2x.png",
    "normal": "/images/normal/mainMenu/rings/sphene-titanite-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/sphene-titanite-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/rings/sphene-titanite-rings.webp"
  },
  "mainMenu/rings/spinel-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/rings/spinel-rings@2x.png",
    "normal": "/images/normal/mainMenu/rings/spinel-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/spinel-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/rings/spinel-rings.webp"
  },
  "mainMenu/rings/taaffeite-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/rings/taaffeite-rings@2x.png",
    "normal": "/images/normal/mainMenu/rings/taaffeite-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/taaffeite-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/rings/taaffeite-rings.webp"
  },
  "mainMenu/rings/tanzanite-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/rings/tanzanite-rings@2x.png",
    "normal": "/images/normal/mainMenu/rings/tanzanite-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/tanzanite-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/rings/tanzanite-rings.webp"
  },
  "mainMenu/rings/tiger-eye-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/rings/tiger-eye-rings@2x.png",
    "normal": "/images/normal/mainMenu/rings/tiger-eye-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/tiger-eye-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/rings/tiger-eye-rings.webp"
  },
  "mainMenu/rings/topaz-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/rings/topaz-rings@2x.png",
    "normal": "/images/normal/mainMenu/rings/topaz-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/topaz-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/rings/topaz-rings.webp"
  },
  "mainMenu/rings/tourmaline-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/rings/tourmaline-rings@2x.png",
    "normal": "/images/normal/mainMenu/rings/tourmaline-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/tourmaline-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/rings/tourmaline-rings.webp"
  },
  "mainMenu/rings/tsavorite-garnet-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/rings/tsavorite-garnet-rings@2x.png",
    "normal": "/images/normal/mainMenu/rings/tsavorite-garnet-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/tsavorite-garnet-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/rings/tsavorite-garnet-rings.webp"
  },
  "mainMenu/rings/turquoise-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/rings/turquoise-rings@2x.png",
    "normal": "/images/normal/mainMenu/rings/turquoise-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/turquoise-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/rings/turquoise-rings.webp"
  },
  "mainMenu/rings/zircon-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/rings/zircon-rings@2x.png",
    "normal": "/images/normal/mainMenu/rings/zircon-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/rings/zircon-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/rings/zircon-rings.webp"
  },
  "mainMenu/settings/bracelets@2x.png": {
    "retina": "/images/hi-res/mainMenu/settings/bracelets@2x.png",
    "normal": "/images/normal/mainMenu/settings/bracelets.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/settings/bracelets@2x.webp",
    "webp": "/images/normal-webp/mainMenu/settings/bracelets.webp"
  },
  "mainMenu/settings/dyo-jewelry-mobile@2x.jpg": {
    "retina": "/images/hi-res/mainMenu/settings/dyo-jewelry-mobile@2x.jpg",
    "normal": "/images/normal/mainMenu/settings/dyo-jewelry-mobile.jpg",
    "retinaWebp": "/images/hi-res-webp/mainMenu/settings/dyo-jewelry-mobile@2x.webp",
    "webp": "/images/normal-webp/mainMenu/settings/dyo-jewelry-mobile.webp"
  },
  "mainMenu/settings/dyo-jewelry@2x.jpg": {
    "retina": "/images/hi-res/mainMenu/settings/dyo-jewelry@2x.jpg",
    "normal": "/images/normal/mainMenu/settings/dyo-jewelry.jpg",
    "retinaWebp": "/images/hi-res-webp/mainMenu/settings/dyo-jewelry@2x.webp",
    "webp": "/images/normal-webp/mainMenu/settings/dyo-jewelry.webp"
  },
  "mainMenu/settings/earrings@2x.png": {
    "retina": "/images/hi-res/mainMenu/settings/earrings@2x.png",
    "normal": "/images/normal/mainMenu/settings/earrings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/settings/earrings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/settings/earrings.webp"
  },
  "mainMenu/settings/mensRings@2x.png": {
    "retina": "/images/hi-res/mainMenu/settings/mensRings@2x.png",
    "normal": "/images/normal/mainMenu/settings/mensRings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/settings/mensRings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/settings/mensRings.webp"
  },
  "mainMenu/settings/necklacesPendants@2x.png": {
    "retina": "/images/hi-res/mainMenu/settings/necklacesPendants@2x.png",
    "normal": "/images/normal/mainMenu/settings/necklacesPendants.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/settings/necklacesPendants@2x.webp",
    "webp": "/images/normal-webp/mainMenu/settings/necklacesPendants.webp"
  },
  "mainMenu/settings/rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/settings/rings@2x.png",
    "normal": "/images/normal/mainMenu/settings/rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/settings/rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/settings/rings.webp"
  },
  "mainMenu/settingsType/bracelets@2x.png": {
    "retina": "/images/hi-res/mainMenu/settingsType/bracelets@2x.png",
    "normal": "/images/normal/mainMenu/settingsType/bracelets.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/settingsType/bracelets@2x.webp",
    "webp": "/images/normal-webp/mainMenu/settingsType/bracelets.webp"
  },
  "mainMenu/settingsType/earrings@2x.png": {
    "retina": "/images/hi-res/mainMenu/settingsType/earrings@2x.png",
    "normal": "/images/normal/mainMenu/settingsType/earrings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/settingsType/earrings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/settingsType/earrings.webp"
  },
  "mainMenu/settingsType/mens-rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/settingsType/mens-rings@2x.png",
    "normal": "/images/normal/mainMenu/settingsType/mens-rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/settingsType/mens-rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/settingsType/mens-rings.webp"
  },
  "mainMenu/settingsType/necklaces-pendants@2x.png": {
    "retina": "/images/hi-res/mainMenu/settingsType/necklaces-pendants@2x.png",
    "normal": "/images/normal/mainMenu/settingsType/necklaces-pendants.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/settingsType/necklaces-pendants@2x.webp",
    "webp": "/images/normal-webp/mainMenu/settingsType/necklaces-pendants.webp"
  },
  "mainMenu/settingsType/rings@2x.png": {
    "retina": "/images/hi-res/mainMenu/settingsType/rings@2x.png",
    "normal": "/images/normal/mainMenu/settingsType/rings.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/settingsType/rings@2x.webp",
    "webp": "/images/normal-webp/mainMenu/settingsType/rings.webp"
  },
  "mainMenu/stoneColor/bi-color@2x.png": {
    "retina": "/images/hi-res/mainMenu/stoneColor/bi-color@2x.png",
    "normal": "/images/normal/mainMenu/stoneColor/bi-color.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stoneColor/bi-color@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stoneColor/bi-color.webp"
  },
  "mainMenu/stoneColor/black@2x.png": {
    "retina": "/images/hi-res/mainMenu/stoneColor/black@2x.png",
    "normal": "/images/normal/mainMenu/stoneColor/black.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stoneColor/black@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stoneColor/black.webp"
  },
  "mainMenu/stoneColor/blue@2x.png": {
    "retina": "/images/hi-res/mainMenu/stoneColor/blue@2x.png",
    "normal": "/images/normal/mainMenu/stoneColor/blue.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stoneColor/blue@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stoneColor/blue.webp"
  },
  "mainMenu/stoneColor/brown@2x.png": {
    "retina": "/images/hi-res/mainMenu/stoneColor/brown@2x.png",
    "normal": "/images/normal/mainMenu/stoneColor/brown.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stoneColor/brown@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stoneColor/brown.webp"
  },
  "mainMenu/stoneColor/color-change@2x.png": {
    "retina": "/images/hi-res/mainMenu/stoneColor/color-change@2x.png",
    "normal": "/images/normal/mainMenu/stoneColor/color-change.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stoneColor/color-change@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stoneColor/color-change.webp"
  },
  "mainMenu/stoneColor/gray@2x.png": {
    "retina": "/images/hi-res/mainMenu/stoneColor/gray@2x.png",
    "normal": "/images/normal/mainMenu/stoneColor/gray.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stoneColor/gray@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stoneColor/gray.webp"
  },
  "mainMenu/stoneColor/green@2x.png": {
    "retina": "/images/hi-res/mainMenu/stoneColor/green@2x.png",
    "normal": "/images/normal/mainMenu/stoneColor/green.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stoneColor/green@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stoneColor/green.webp"
  },
  "mainMenu/stoneColor/multi-color@2x.png": {
    "retina": "/images/hi-res/mainMenu/stoneColor/multi-color@2x.png",
    "normal": "/images/normal/mainMenu/stoneColor/multi-color.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stoneColor/multi-color@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stoneColor/multi-color.webp"
  },
  "mainMenu/stoneColor/orange@2x.png": {
    "retina": "/images/hi-res/mainMenu/stoneColor/orange@2x.png",
    "normal": "/images/normal/mainMenu/stoneColor/orange.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stoneColor/orange@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stoneColor/orange.webp"
  },
  "mainMenu/stoneColor/pink@2x.png": {
    "retina": "/images/hi-res/mainMenu/stoneColor/pink@2x.png",
    "normal": "/images/normal/mainMenu/stoneColor/pink.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stoneColor/pink@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stoneColor/pink.webp"
  },
  "mainMenu/stoneColor/purple@2x.png": {
    "retina": "/images/hi-res/mainMenu/stoneColor/purple@2x.png",
    "normal": "/images/normal/mainMenu/stoneColor/purple.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stoneColor/purple@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stoneColor/purple.webp"
  },
  "mainMenu/stoneColor/red@2x.png": {
    "retina": "/images/hi-res/mainMenu/stoneColor/red@2x.png",
    "normal": "/images/normal/mainMenu/stoneColor/red.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stoneColor/red@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stoneColor/red.webp"
  },
  "mainMenu/stoneColor/white@2x.png": {
    "retina": "/images/hi-res/mainMenu/stoneColor/white@2x.png",
    "normal": "/images/normal/mainMenu/stoneColor/white.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stoneColor/white@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stoneColor/white.webp"
  },
  "mainMenu/stoneColor/yellow@2x.png": {
    "retina": "/images/hi-res/mainMenu/stoneColor/yellow@2x.png",
    "normal": "/images/normal/mainMenu/stoneColor/yellow.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stoneColor/yellow@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stoneColor/yellow.webp"
  },
  "mainMenu/stones/agate@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/agate@2x.png",
    "normal": "/images/normal/mainMenu/stones/agate.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/agate@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/agate.webp"
  },
  "mainMenu/stones/alexandrite@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/alexandrite@2x.png",
    "normal": "/images/normal/mainMenu/stones/alexandrite.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/alexandrite@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/alexandrite.webp"
  },
  "mainMenu/stones/almandine-garnet@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/almandine-garnet@2x.png",
    "normal": "/images/normal/mainMenu/stones/almandine-garnet.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/almandine-garnet@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/almandine-garnet.webp"
  },
  "mainMenu/stones/amethyst@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/amethyst@2x.png",
    "normal": "/images/normal/mainMenu/stones/amethyst.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/amethyst@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/amethyst.webp"
  },
  "mainMenu/stones/ametrine@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/ametrine@2x.png",
    "normal": "/images/normal/mainMenu/stones/ametrine.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/ametrine@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/ametrine.webp"
  },
  "mainMenu/stones/andalusite@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/andalusite@2x.png",
    "normal": "/images/normal/mainMenu/stones/andalusite.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/andalusite@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/andalusite.webp"
  },
  "mainMenu/stones/andesine@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/andesine@2x.png",
    "normal": "/images/normal/mainMenu/stones/andesine.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/andesine@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/andesine.webp"
  },
  "mainMenu/stones/andradite-garnet@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/andradite-garnet@2x.png",
    "normal": "/images/normal/mainMenu/stones/andradite-garnet.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/andradite-garnet@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/andradite-garnet.webp"
  },
  "mainMenu/stones/apatite@2x copy.png": {
    "retina": "/images/hi-res/mainMenu/stones/apatite@2x copy.png",
    "normal": "/images/normal/mainMenu/stones/apatite copy.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/apatite@2x copy.webp",
    "webp": "/images/normal-webp/mainMenu/stones/apatite copy.webp"
  },
  "mainMenu/stones/apatite@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/apatite@2x.png",
    "normal": "/images/normal/mainMenu/stones/apatite.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/apatite@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/apatite.webp"
  },
  "mainMenu/stones/aquamarine@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/aquamarine@2x.png",
    "normal": "/images/normal/mainMenu/stones/aquamarine.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/aquamarine@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/aquamarine.webp"
  },
  "mainMenu/stones/beryl@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/beryl@2x.png",
    "normal": "/images/normal/mainMenu/stones/beryl.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/beryl@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/beryl.webp"
  },
  "mainMenu/stones/carnelian@2x copy.png": {
    "retina": "/images/hi-res/mainMenu/stones/carnelian@2x copy.png",
    "normal": "/images/normal/mainMenu/stones/carnelian copy.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/carnelian@2x copy.webp",
    "webp": "/images/normal-webp/mainMenu/stones/carnelian copy.webp"
  },
  "mainMenu/stones/carnelian@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/carnelian@2x.png",
    "normal": "/images/normal/mainMenu/stones/carnelian.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/carnelian@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/carnelian.webp"
  },
  "mainMenu/stones/cats-eye-chrysoberyl@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/cats-eye-chrysoberyl@2x.png",
    "normal": "/images/normal/mainMenu/stones/cats-eye-chrysoberyl.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/cats-eye-chrysoberyl@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/cats-eye-chrysoberyl.webp"
  },
  "mainMenu/stones/chrome-diopside@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/chrome-diopside@2x.png",
    "normal": "/images/normal/mainMenu/stones/chrome-diopside.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/chrome-diopside@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/chrome-diopside.webp"
  },
  "mainMenu/stones/chrome-tourmaline@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/chrome-tourmaline@2x.png",
    "normal": "/images/normal/mainMenu/stones/chrome-tourmaline.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/chrome-tourmaline@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/chrome-tourmaline.webp"
  },
  "mainMenu/stones/chrysoberyl@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/chrysoberyl@2x.png",
    "normal": "/images/normal/mainMenu/stones/chrysoberyl.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/chrysoberyl@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/chrysoberyl.webp"
  },
  "mainMenu/stones/citrine@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/citrine@2x.png",
    "normal": "/images/normal/mainMenu/stones/citrine.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/citrine@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/citrine.webp"
  },
  "mainMenu/stones/cobalt-spinel@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/cobalt-spinel@2x.png",
    "normal": "/images/normal/mainMenu/stones/cobalt-spinel.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/cobalt-spinel@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/cobalt-spinel.webp"
  },
  "mainMenu/stones/coral@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/coral@2x.png",
    "normal": "/images/normal/mainMenu/stones/coral.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/coral@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/coral.webp"
  },
  "mainMenu/stones/demantoid-garnet@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/demantoid-garnet@2x.png",
    "normal": "/images/normal/mainMenu/stones/demantoid-garnet.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/demantoid-garnet@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/demantoid-garnet.webp"
  },
  "mainMenu/stones/fluorite@2x copy.png": {
    "retina": "/images/hi-res/mainMenu/stones/fluorite@2x copy.png",
    "normal": "/images/normal/mainMenu/stones/fluorite copy.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/fluorite@2x copy.webp",
    "webp": "/images/normal-webp/mainMenu/stones/fluorite copy.webp"
  },
  "mainMenu/stones/fluorite@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/fluorite@2x.png",
    "normal": "/images/normal/mainMenu/stones/fluorite.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/fluorite@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/fluorite.webp"
  },
  "mainMenu/stones/garnet@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/garnet@2x.png",
    "normal": "/images/normal/mainMenu/stones/garnet.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/garnet@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/garnet.webp"
  },
  "mainMenu/stones/grossular-garnet@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/grossular-garnet@2x.png",
    "normal": "/images/normal/mainMenu/stones/grossular-garnet.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/grossular-garnet@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/grossular-garnet.webp"
  },
  "mainMenu/stones/hessonite-garnet@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/hessonite-garnet@2x.png",
    "normal": "/images/normal/mainMenu/stones/hessonite-garnet.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/hessonite-garnet@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/hessonite-garnet.webp"
  },
  "mainMenu/stones/iolite@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/iolite@2x.png",
    "normal": "/images/normal/mainMenu/stones/iolite.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/iolite@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/iolite.webp"
  },
  "mainMenu/stones/jade@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/jade@2x.png",
    "normal": "/images/normal/mainMenu/stones/jade.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/jade@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/jade.webp"
  },
  "mainMenu/stones/jeremejevite@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/jeremejevite@2x.png",
    "normal": "/images/normal/mainMenu/stones/jeremejevite.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/jeremejevite@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/jeremejevite.webp"
  },
  "mainMenu/stones/kornerupine@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/kornerupine@2x.png",
    "normal": "/images/normal/mainMenu/stones/kornerupine.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/kornerupine@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/kornerupine.webp"
  },
  "mainMenu/stones/kunzite@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/kunzite@2x.png",
    "normal": "/images/normal/mainMenu/stones/kunzite.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/kunzite@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/kunzite.webp"
  },
  "mainMenu/stones/kyanite@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/kyanite@2x.png",
    "normal": "/images/normal/mainMenu/stones/kyanite.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/kyanite@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/kyanite.webp"
  },
  "mainMenu/stones/lapis@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/lapis@2x.png",
    "normal": "/images/normal/mainMenu/stones/lapis.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/lapis@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/lapis.webp"
  },
  "mainMenu/stones/madeira-citrine@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/madeira-citrine@2x.png",
    "normal": "/images/normal/mainMenu/stones/madeira-citrine.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/madeira-citrine@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/madeira-citrine.webp"
  },
  "mainMenu/stones/malachite@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/malachite@2x.png",
    "normal": "/images/normal/mainMenu/stones/malachite.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/malachite@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/malachite.webp"
  },
  "mainMenu/stones/malaya-garnet@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/malaya-garnet@2x.png",
    "normal": "/images/normal/mainMenu/stones/malaya-garnet.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/malaya-garnet@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/malaya-garnet.webp"
  },
  "mainMenu/stones/mali-garnet@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/mali-garnet@2x.png",
    "normal": "/images/normal/mainMenu/stones/mali-garnet.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/mali-garnet@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/mali-garnet.webp"
  },
  "mainMenu/stones/moonstone@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/moonstone@2x.png",
    "normal": "/images/normal/mainMenu/stones/moonstone.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/moonstone@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/moonstone.webp"
  },
  "mainMenu/stones/morganite@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/morganite@2x.png",
    "normal": "/images/normal/mainMenu/stones/morganite.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/morganite@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/morganite.webp"
  },
  "mainMenu/stones/onyx@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/onyx@2x.png",
    "normal": "/images/normal/mainMenu/stones/onyx.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/onyx@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/onyx.webp"
  },
  "mainMenu/stones/opal@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/opal@2x.png",
    "normal": "/images/normal/mainMenu/stones/opal.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/opal@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/opal.webp"
  },
  "mainMenu/stones/paraiba-tourmaline@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/paraiba-tourmaline@2x.png",
    "normal": "/images/normal/mainMenu/stones/paraiba-tourmaline.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/paraiba-tourmaline@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/paraiba-tourmaline.webp"
  },
  "mainMenu/stones/pearl@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/pearl@2x.png",
    "normal": "/images/normal/mainMenu/stones/pearl.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/pearl@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/pearl.webp"
  },
  "mainMenu/stones/peridot@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/peridot@2x.png",
    "normal": "/images/normal/mainMenu/stones/peridot.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/peridot@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/peridot.webp"
  },
  "mainMenu/stones/phenakite@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/phenakite@2x.png",
    "normal": "/images/normal/mainMenu/stones/phenakite.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/phenakite@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/phenakite.webp"
  },
  "mainMenu/stones/quartz@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/quartz@2x.png",
    "normal": "/images/normal/mainMenu/stones/quartz.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/quartz@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/quartz.webp"
  },
  "mainMenu/stones/rhodochrosite@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/rhodochrosite@2x.png",
    "normal": "/images/normal/mainMenu/stones/rhodochrosite.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/rhodochrosite@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/rhodochrosite.webp"
  },
  "mainMenu/stones/rhodolite-garnet@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/rhodolite-garnet@2x.png",
    "normal": "/images/normal/mainMenu/stones/rhodolite-garnet.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/rhodolite-garnet@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/rhodolite-garnet.webp"
  },
  "mainMenu/stones/rose-quartz@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/rose-quartz@2x.png",
    "normal": "/images/normal/mainMenu/stones/rose-quartz.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/rose-quartz@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/rose-quartz.webp"
  },
  "mainMenu/stones/rubellite-tourmaline@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/rubellite-tourmaline@2x.png",
    "normal": "/images/normal/mainMenu/stones/rubellite-tourmaline.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/rubellite-tourmaline@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/rubellite-tourmaline.webp"
  },
  "mainMenu/stones/rutilated-quartz@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/rutilated-quartz@2x.png",
    "normal": "/images/normal/mainMenu/stones/rutilated-quartz.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/rutilated-quartz@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/rutilated-quartz.webp"
  },
  "mainMenu/stones/scapolite@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/scapolite@2x.png",
    "normal": "/images/normal/mainMenu/stones/scapolite.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/scapolite@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/scapolite.webp"
  },
  "mainMenu/stones/sinhalite@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/sinhalite@2x.png",
    "normal": "/images/normal/mainMenu/stones/sinhalite.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/sinhalite@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/sinhalite.webp"
  },
  "mainMenu/stones/smokey-quartz@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/smokey-quartz@2x.png",
    "normal": "/images/normal/mainMenu/stones/smokey-quartz.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/smokey-quartz@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/smokey-quartz.webp"
  },
  "mainMenu/stones/spessartite-garnet@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/spessartite-garnet@2x.png",
    "normal": "/images/normal/mainMenu/stones/spessartite-garnet.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/spessartite-garnet@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/spessartite-garnet.webp"
  },
  "mainMenu/stones/sphene-titanite@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/sphene-titanite@2x.png",
    "normal": "/images/normal/mainMenu/stones/sphene-titanite.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/sphene-titanite@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/sphene-titanite.webp"
  },
  "mainMenu/stones/spinel@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/spinel@2x.png",
    "normal": "/images/normal/mainMenu/stones/spinel.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/spinel@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/spinel.webp"
  },
  "mainMenu/stones/taaffeite@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/taaffeite@2x.png",
    "normal": "/images/normal/mainMenu/stones/taaffeite.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/taaffeite@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/taaffeite.webp"
  },
  "mainMenu/stones/tanzanite@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/tanzanite@2x.png",
    "normal": "/images/normal/mainMenu/stones/tanzanite.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/tanzanite@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/tanzanite.webp"
  },
  "mainMenu/stones/tiger-eye@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/tiger-eye@2x.png",
    "normal": "/images/normal/mainMenu/stones/tiger-eye.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/tiger-eye@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/tiger-eye.webp"
  },
  "mainMenu/stones/topaz@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/topaz@2x.png",
    "normal": "/images/normal/mainMenu/stones/topaz.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/topaz@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/topaz.webp"
  },
  "mainMenu/stones/tourmaline@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/tourmaline@2x.png",
    "normal": "/images/normal/mainMenu/stones/tourmaline.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/tourmaline@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/tourmaline.webp"
  },
  "mainMenu/stones/tsavorite-garnet@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/tsavorite-garnet@2x.png",
    "normal": "/images/normal/mainMenu/stones/tsavorite-garnet.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/tsavorite-garnet@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/tsavorite-garnet.webp"
  },
  "mainMenu/stones/turquoise@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/turquoise@2x.png",
    "normal": "/images/normal/mainMenu/stones/turquoise.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/turquoise@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/turquoise.webp"
  },
  "mainMenu/stones/zircon@2x.png": {
    "retina": "/images/hi-res/mainMenu/stones/zircon@2x.png",
    "normal": "/images/normal/mainMenu/stones/zircon.png",
    "retinaWebp": "/images/hi-res-webp/mainMenu/stones/zircon@2x.webp",
    "webp": "/images/normal-webp/mainMenu/stones/zircon.webp"
  },
  "mobile-logo@2x.jpg": {
    "retina": "/images/hi-res/mobile-logo@2x.jpg",
    "normal": "/images/normal/mobile-logo.jpg",
    "retinaWebp": "/images/hi-res-webp/mobile-logo@2x.webp",
    "webp": "/images/normal-webp/mobile-logo.webp"
  },
  "not-available@2x.jpg": {
    "retina": "/images/hi-res/not-available@2x.jpg",
    "normal": "/images/normal/not-available.jpg",
    "retinaWebp": "/images/hi-res-webp/not-available@2x.webp",
    "webp": "/images/normal-webp/not-available.webp"
  },
  "ourStory/bg@2x.jpg": {
    "retina": "/images/hi-res/ourStory/bg@2x.jpg",
    "normal": "/images/normal/ourStory/bg.jpg",
    "retinaWebp": "/images/hi-res-webp/ourStory/bg@2x.webp",
    "webp": "/images/normal-webp/ourStory/bg.webp"
  },
  "ourStory/slide1@2x.jpg": {
    "retina": "/images/hi-res/ourStory/slide1@2x.jpg",
    "normal": "/images/normal/ourStory/slide1.jpg",
    "retinaWebp": "/images/hi-res-webp/ourStory/slide1@2x.webp",
    "webp": "/images/normal-webp/ourStory/slide1.webp"
  },
  "packaging/bg-reverse@2x.jpg": {
    "retina": "/images/hi-res/packaging/bg-reverse@2x.jpg",
    "normal": "/images/normal/packaging/bg-reverse.jpg",
    "retinaWebp": "/images/hi-res-webp/packaging/bg-reverse@2x.webp",
    "webp": "/images/normal-webp/packaging/bg-reverse.webp"
  },
  "packaging/bg@2x.jpg": {
    "retina": "/images/hi-res/packaging/bg@2x.jpg",
    "normal": "/images/normal/packaging/bg.jpg",
    "retinaWebp": "/images/hi-res-webp/packaging/bg@2x.webp",
    "webp": "/images/normal-webp/packaging/bg.webp"
  },
  "packaging/large/package10@2x.jpg": {
    "retina": "/images/hi-res/packaging/large/package10@2x.jpg",
    "normal": "/images/normal/packaging/large/package10.jpg",
    "retinaWebp": "/images/hi-res-webp/packaging/large/package10@2x.webp",
    "webp": "/images/normal-webp/packaging/large/package10.webp"
  },
  "packaging/large/package11@2x.jpg": {
    "retina": "/images/hi-res/packaging/large/package11@2x.jpg",
    "normal": "/images/normal/packaging/large/package11.jpg",
    "retinaWebp": "/images/hi-res-webp/packaging/large/package11@2x.webp",
    "webp": "/images/normal-webp/packaging/large/package11.webp"
  },
  "packaging/large/package12@2x.jpg": {
    "retina": "/images/hi-res/packaging/large/package12@2x.jpg",
    "normal": "/images/normal/packaging/large/package12.jpg",
    "retinaWebp": "/images/hi-res-webp/packaging/large/package12@2x.webp",
    "webp": "/images/normal-webp/packaging/large/package12.webp"
  },
  "packaging/large/package13@2x.jpg": {
    "retina": "/images/hi-res/packaging/large/package13@2x.jpg",
    "normal": "/images/normal/packaging/large/package13.jpg",
    "retinaWebp": "/images/hi-res-webp/packaging/large/package13@2x.webp",
    "webp": "/images/normal-webp/packaging/large/package13.webp"
  },
  "packaging/large/package1@2x.jpg": {
    "retina": "/images/hi-res/packaging/large/package1@2x.jpg",
    "normal": "/images/normal/packaging/large/package1.jpg",
    "retinaWebp": "/images/hi-res-webp/packaging/large/package1@2x.webp",
    "webp": "/images/normal-webp/packaging/large/package1.webp"
  },
  "packaging/large/package2@2x.jpg": {
    "retina": "/images/hi-res/packaging/large/package2@2x.jpg",
    "normal": "/images/normal/packaging/large/package2.jpg",
    "retinaWebp": "/images/hi-res-webp/packaging/large/package2@2x.webp",
    "webp": "/images/normal-webp/packaging/large/package2.webp"
  },
  "packaging/large/package3@2x.jpg": {
    "retina": "/images/hi-res/packaging/large/package3@2x.jpg",
    "normal": "/images/normal/packaging/large/package3.jpg",
    "retinaWebp": "/images/hi-res-webp/packaging/large/package3@2x.webp",
    "webp": "/images/normal-webp/packaging/large/package3.webp"
  },
  "packaging/large/package4@2x.jpg": {
    "retina": "/images/hi-res/packaging/large/package4@2x.jpg",
    "normal": "/images/normal/packaging/large/package4.jpg",
    "retinaWebp": "/images/hi-res-webp/packaging/large/package4@2x.webp",
    "webp": "/images/normal-webp/packaging/large/package4.webp"
  },
  "packaging/large/package5@2x.jpg": {
    "retina": "/images/hi-res/packaging/large/package5@2x.jpg",
    "normal": "/images/normal/packaging/large/package5.jpg",
    "retinaWebp": "/images/hi-res-webp/packaging/large/package5@2x.webp",
    "webp": "/images/normal-webp/packaging/large/package5.webp"
  },
  "packaging/large/package6@2x.jpg": {
    "retina": "/images/hi-res/packaging/large/package6@2x.jpg",
    "normal": "/images/normal/packaging/large/package6.jpg",
    "retinaWebp": "/images/hi-res-webp/packaging/large/package6@2x.webp",
    "webp": "/images/normal-webp/packaging/large/package6.webp"
  },
  "packaging/large/package7@2x.jpg": {
    "retina": "/images/hi-res/packaging/large/package7@2x.jpg",
    "normal": "/images/normal/packaging/large/package7.jpg",
    "retinaWebp": "/images/hi-res-webp/packaging/large/package7@2x.webp",
    "webp": "/images/normal-webp/packaging/large/package7.webp"
  },
  "packaging/large/package8@2x.jpg": {
    "retina": "/images/hi-res/packaging/large/package8@2x.jpg",
    "normal": "/images/normal/packaging/large/package8.jpg",
    "retinaWebp": "/images/hi-res-webp/packaging/large/package8@2x.webp",
    "webp": "/images/normal-webp/packaging/large/package8.webp"
  },
  "packaging/large/package9@2x.jpg": {
    "retina": "/images/hi-res/packaging/large/package9@2x.jpg",
    "normal": "/images/normal/packaging/large/package9.jpg",
    "retinaWebp": "/images/hi-res-webp/packaging/large/package9@2x.webp",
    "webp": "/images/normal-webp/packaging/large/package9.webp"
  },
  "packaging/package0@2x.jpg": {
    "retina": "/images/hi-res/packaging/package0@2x.jpg",
    "normal": "/images/normal/packaging/package0.jpg",
    "retinaWebp": "/images/hi-res-webp/packaging/package0@2x.webp",
    "webp": "/images/normal-webp/packaging/package0.webp"
  },
  "packaging/package10@2x.jpg": {
    "retina": "/images/hi-res/packaging/package10@2x.jpg",
    "normal": "/images/normal/packaging/package10.jpg",
    "retinaWebp": "/images/hi-res-webp/packaging/package10@2x.webp",
    "webp": "/images/normal-webp/packaging/package10.webp"
  },
  "packaging/package11@2x.jpg": {
    "retina": "/images/hi-res/packaging/package11@2x.jpg",
    "normal": "/images/normal/packaging/package11.jpg",
    "retinaWebp": "/images/hi-res-webp/packaging/package11@2x.webp",
    "webp": "/images/normal-webp/packaging/package11.webp"
  },
  "packaging/package12@2x.jpg": {
    "retina": "/images/hi-res/packaging/package12@2x.jpg",
    "normal": "/images/normal/packaging/package12.jpg",
    "retinaWebp": "/images/hi-res-webp/packaging/package12@2x.webp",
    "webp": "/images/normal-webp/packaging/package12.webp"
  },
  "packaging/package13@2x.jpg": {
    "retina": "/images/hi-res/packaging/package13@2x.jpg",
    "normal": "/images/normal/packaging/package13.jpg",
    "retinaWebp": "/images/hi-res-webp/packaging/package13@2x.webp",
    "webp": "/images/normal-webp/packaging/package13.webp"
  },
  "packaging/package1@2x.jpg": {
    "retina": "/images/hi-res/packaging/package1@2x.jpg",
    "normal": "/images/normal/packaging/package1.jpg",
    "retinaWebp": "/images/hi-res-webp/packaging/package1@2x.webp",
    "webp": "/images/normal-webp/packaging/package1.webp"
  },
  "packaging/package2@2x.jpg": {
    "retina": "/images/hi-res/packaging/package2@2x.jpg",
    "normal": "/images/normal/packaging/package2.jpg",
    "retinaWebp": "/images/hi-res-webp/packaging/package2@2x.webp",
    "webp": "/images/normal-webp/packaging/package2.webp"
  },
  "packaging/package3@2x.jpg": {
    "retina": "/images/hi-res/packaging/package3@2x.jpg",
    "normal": "/images/normal/packaging/package3.jpg",
    "retinaWebp": "/images/hi-res-webp/packaging/package3@2x.webp",
    "webp": "/images/normal-webp/packaging/package3.webp"
  },
  "packaging/package4@2x.jpg": {
    "retina": "/images/hi-res/packaging/package4@2x.jpg",
    "normal": "/images/normal/packaging/package4.jpg",
    "retinaWebp": "/images/hi-res-webp/packaging/package4@2x.webp",
    "webp": "/images/normal-webp/packaging/package4.webp"
  },
  "packaging/package5@2x.jpg": {
    "retina": "/images/hi-res/packaging/package5@2x.jpg",
    "normal": "/images/normal/packaging/package5.jpg",
    "retinaWebp": "/images/hi-res-webp/packaging/package5@2x.webp",
    "webp": "/images/normal-webp/packaging/package5.webp"
  },
  "packaging/package6@2x.jpg": {
    "retina": "/images/hi-res/packaging/package6@2x.jpg",
    "normal": "/images/normal/packaging/package6.jpg",
    "retinaWebp": "/images/hi-res-webp/packaging/package6@2x.webp",
    "webp": "/images/normal-webp/packaging/package6.webp"
  },
  "packaging/package7@2x.jpg": {
    "retina": "/images/hi-res/packaging/package7@2x.jpg",
    "normal": "/images/normal/packaging/package7.jpg",
    "retinaWebp": "/images/hi-res-webp/packaging/package7@2x.webp",
    "webp": "/images/normal-webp/packaging/package7.webp"
  },
  "packaging/package8@2x.jpg": {
    "retina": "/images/hi-res/packaging/package8@2x.jpg",
    "normal": "/images/normal/packaging/package8.jpg",
    "retinaWebp": "/images/hi-res-webp/packaging/package8@2x.webp",
    "webp": "/images/normal-webp/packaging/package8.webp"
  },
  "packaging/package9@2x.jpg": {
    "retina": "/images/hi-res/packaging/package9@2x.jpg",
    "normal": "/images/normal/packaging/package9.jpg",
    "retinaWebp": "/images/hi-res-webp/packaging/package9@2x.webp",
    "webp": "/images/normal-webp/packaging/package9.webp"
  },
  "packaging/playButton@2x.png": {
    "retina": "/images/hi-res/packaging/playButton@2x.png",
    "normal": "/images/normal/packaging/playButton.png",
    "retinaWebp": "/images/hi-res-webp/packaging/playButton@2x.webp",
    "webp": "/images/normal-webp/packaging/playButton.webp"
  },
  "showroom/address@2x.jpg": {
    "retina": "/images/hi-res/showroom/address@2x.jpg",
    "normal": "/images/normal/showroom/address.jpg",
    "retinaWebp": "/images/hi-res-webp/showroom/address@2x.webp",
    "webp": "/images/normal-webp/showroom/address.webp"
  },
  "showroom/background/showroom_full-01@2x.jpg": {
    "retina": "/images/hi-res/showroom/background/showroom_full-01@2x.jpg",
    "normal": "/images/normal/showroom/background/showroom_full-01.jpg",
    "retinaWebp": "/images/hi-res-webp/showroom/background/showroom_full-01@2x.webp",
    "webp": "/images/normal-webp/showroom/background/showroom_full-01.webp"
  },
  "showroom/background/showroom_full-14@2x.jpg": {
    "retina": "/images/hi-res/showroom/background/showroom_full-14@2x.jpg",
    "normal": "/images/normal/showroom/background/showroom_full-14.jpg",
    "retinaWebp": "/images/hi-res-webp/showroom/background/showroom_full-14@2x.webp",
    "webp": "/images/normal-webp/showroom/background/showroom_full-14.webp"
  },
  "showroom/background/showroom_full-20@2x.jpg": {
    "retina": "/images/hi-res/showroom/background/showroom_full-20@2x.jpg",
    "normal": "/images/normal/showroom/background/showroom_full-20.jpg",
    "retinaWebp": "/images/hi-res-webp/showroom/background/showroom_full-20@2x.webp",
    "webp": "/images/normal-webp/showroom/background/showroom_full-20.webp"
  },
  "showroom/background/showroom_full-24@2x.jpg": {
    "retina": "/images/hi-res/showroom/background/showroom_full-24@2x.jpg",
    "normal": "/images/normal/showroom/background/showroom_full-24.jpg",
    "retinaWebp": "/images/hi-res-webp/showroom/background/showroom_full-24@2x.webp",
    "webp": "/images/normal-webp/showroom/background/showroom_full-24.webp"
  },
  "showroom/bg@2x.jpg": {
    "retina": "/images/hi-res/showroom/bg@2x.jpg",
    "normal": "/images/normal/showroom/bg.jpg",
    "retinaWebp": "/images/hi-res-webp/showroom/bg@2x.webp",
    "webp": "/images/normal-webp/showroom/bg.webp"
  },
  "showroom/necklace@2x.jpg": {
    "retina": "/images/hi-res/showroom/necklace@2x.jpg",
    "normal": "/images/normal/showroom/necklace.jpg",
    "retinaWebp": "/images/hi-res-webp/showroom/necklace@2x.webp",
    "webp": "/images/normal-webp/showroom/necklace.webp"
  },
  "showroom/pendant@2x.png": {
    "retina": "/images/hi-res/showroom/pendant@2x.png",
    "normal": "/images/normal/showroom/pendant.png",
    "retinaWebp": "/images/hi-res-webp/showroom/pendant@2x.webp",
    "webp": "/images/normal-webp/showroom/pendant.webp"
  },
  "showroom/ring@2x.png": {
    "retina": "/images/hi-res/showroom/ring@2x.png",
    "normal": "/images/normal/showroom/ring.png",
    "retinaWebp": "/images/hi-res-webp/showroom/ring@2x.webp",
    "webp": "/images/normal-webp/showroom/ring.webp"
  },
  "showroom/showroom_full-01@2x.jpg": {
    "retina": "/images/hi-res/showroom/showroom_full-01@2x.jpg",
    "normal": "/images/normal/showroom/showroom_full-01.jpg",
    "retinaWebp": "/images/hi-res-webp/showroom/showroom_full-01@2x.webp",
    "webp": "/images/normal-webp/showroom/showroom_full-01.webp"
  },
  "showroom/showroom_full-02@2x.jpg": {
    "retina": "/images/hi-res/showroom/showroom_full-02@2x.jpg",
    "normal": "/images/normal/showroom/showroom_full-02.jpg",
    "retinaWebp": "/images/hi-res-webp/showroom/showroom_full-02@2x.webp",
    "webp": "/images/normal-webp/showroom/showroom_full-02.webp"
  },
  "showroom/showroom_full-03@2x.jpg": {
    "retina": "/images/hi-res/showroom/showroom_full-03@2x.jpg",
    "normal": "/images/normal/showroom/showroom_full-03.jpg",
    "retinaWebp": "/images/hi-res-webp/showroom/showroom_full-03@2x.webp",
    "webp": "/images/normal-webp/showroom/showroom_full-03.webp"
  },
  "showroom/showroom_full-04@2x.jpg": {
    "retina": "/images/hi-res/showroom/showroom_full-04@2x.jpg",
    "normal": "/images/normal/showroom/showroom_full-04.jpg",
    "retinaWebp": "/images/hi-res-webp/showroom/showroom_full-04@2x.webp",
    "webp": "/images/normal-webp/showroom/showroom_full-04.webp"
  },
  "showroom/showroom_full-05@2x.jpg": {
    "retina": "/images/hi-res/showroom/showroom_full-05@2x.jpg",
    "normal": "/images/normal/showroom/showroom_full-05.jpg",
    "retinaWebp": "/images/hi-res-webp/showroom/showroom_full-05@2x.webp",
    "webp": "/images/normal-webp/showroom/showroom_full-05.webp"
  },
  "showroom/showroom_full-06@2x.jpg": {
    "retina": "/images/hi-res/showroom/showroom_full-06@2x.jpg",
    "normal": "/images/normal/showroom/showroom_full-06.jpg",
    "retinaWebp": "/images/hi-res-webp/showroom/showroom_full-06@2x.webp",
    "webp": "/images/normal-webp/showroom/showroom_full-06.webp"
  },
  "showroom/showroom_full-07@2x.jpg": {
    "retina": "/images/hi-res/showroom/showroom_full-07@2x.jpg",
    "normal": "/images/normal/showroom/showroom_full-07.jpg",
    "retinaWebp": "/images/hi-res-webp/showroom/showroom_full-07@2x.webp",
    "webp": "/images/normal-webp/showroom/showroom_full-07.webp"
  },
  "showroom/showroom_full-08@2x.jpg": {
    "retina": "/images/hi-res/showroom/showroom_full-08@2x.jpg",
    "normal": "/images/normal/showroom/showroom_full-08.jpg",
    "retinaWebp": "/images/hi-res-webp/showroom/showroom_full-08@2x.webp",
    "webp": "/images/normal-webp/showroom/showroom_full-08.webp"
  },
  "showroom/showroom_full-09@2x.jpg": {
    "retina": "/images/hi-res/showroom/showroom_full-09@2x.jpg",
    "normal": "/images/normal/showroom/showroom_full-09.jpg",
    "retinaWebp": "/images/hi-res-webp/showroom/showroom_full-09@2x.webp",
    "webp": "/images/normal-webp/showroom/showroom_full-09.webp"
  },
  "showroom/showroom_full-10@2x.jpg": {
    "retina": "/images/hi-res/showroom/showroom_full-10@2x.jpg",
    "normal": "/images/normal/showroom/showroom_full-10.jpg",
    "retinaWebp": "/images/hi-res-webp/showroom/showroom_full-10@2x.webp",
    "webp": "/images/normal-webp/showroom/showroom_full-10.webp"
  },
  "showroom/showroom_full-11@2x.jpg": {
    "retina": "/images/hi-res/showroom/showroom_full-11@2x.jpg",
    "normal": "/images/normal/showroom/showroom_full-11.jpg",
    "retinaWebp": "/images/hi-res-webp/showroom/showroom_full-11@2x.webp",
    "webp": "/images/normal-webp/showroom/showroom_full-11.webp"
  },
  "showroom/showroom_full-12@2x.jpg": {
    "retina": "/images/hi-res/showroom/showroom_full-12@2x.jpg",
    "normal": "/images/normal/showroom/showroom_full-12.jpg",
    "retinaWebp": "/images/hi-res-webp/showroom/showroom_full-12@2x.webp",
    "webp": "/images/normal-webp/showroom/showroom_full-12.webp"
  },
  "showroom/showroom_full-13@2x.jpg": {
    "retina": "/images/hi-res/showroom/showroom_full-13@2x.jpg",
    "normal": "/images/normal/showroom/showroom_full-13.jpg",
    "retinaWebp": "/images/hi-res-webp/showroom/showroom_full-13@2x.webp",
    "webp": "/images/normal-webp/showroom/showroom_full-13.webp"
  },
  "showroom/showroom_full-14@2x.jpg": {
    "retina": "/images/hi-res/showroom/showroom_full-14@2x.jpg",
    "normal": "/images/normal/showroom/showroom_full-14.jpg",
    "retinaWebp": "/images/hi-res-webp/showroom/showroom_full-14@2x.webp",
    "webp": "/images/normal-webp/showroom/showroom_full-14.webp"
  },
  "showroom/showroom_full-15@2x.jpg": {
    "retina": "/images/hi-res/showroom/showroom_full-15@2x.jpg",
    "normal": "/images/normal/showroom/showroom_full-15.jpg",
    "retinaWebp": "/images/hi-res-webp/showroom/showroom_full-15@2x.webp",
    "webp": "/images/normal-webp/showroom/showroom_full-15.webp"
  },
  "showroom/showroom_full-16@2x.jpg": {
    "retina": "/images/hi-res/showroom/showroom_full-16@2x.jpg",
    "normal": "/images/normal/showroom/showroom_full-16.jpg",
    "retinaWebp": "/images/hi-res-webp/showroom/showroom_full-16@2x.webp",
    "webp": "/images/normal-webp/showroom/showroom_full-16.webp"
  },
  "showroom/showroom_full-17@2x.jpg": {
    "retina": "/images/hi-res/showroom/showroom_full-17@2x.jpg",
    "normal": "/images/normal/showroom/showroom_full-17.jpg",
    "retinaWebp": "/images/hi-res-webp/showroom/showroom_full-17@2x.webp",
    "webp": "/images/normal-webp/showroom/showroom_full-17.webp"
  },
  "showroom/showroom_full-18@2x.jpg": {
    "retina": "/images/hi-res/showroom/showroom_full-18@2x.jpg",
    "normal": "/images/normal/showroom/showroom_full-18.jpg",
    "retinaWebp": "/images/hi-res-webp/showroom/showroom_full-18@2x.webp",
    "webp": "/images/normal-webp/showroom/showroom_full-18.webp"
  },
  "showroom/showroom_full-19@2x.jpg": {
    "retina": "/images/hi-res/showroom/showroom_full-19@2x.jpg",
    "normal": "/images/normal/showroom/showroom_full-19.jpg",
    "retinaWebp": "/images/hi-res-webp/showroom/showroom_full-19@2x.webp",
    "webp": "/images/normal-webp/showroom/showroom_full-19.webp"
  },
  "showroom/showroom_full-20@2x.jpg": {
    "retina": "/images/hi-res/showroom/showroom_full-20@2x.jpg",
    "normal": "/images/normal/showroom/showroom_full-20.jpg",
    "retinaWebp": "/images/hi-res-webp/showroom/showroom_full-20@2x.webp",
    "webp": "/images/normal-webp/showroom/showroom_full-20.webp"
  },
  "showroom/showroom_full-21@2x.jpg": {
    "retina": "/images/hi-res/showroom/showroom_full-21@2x.jpg",
    "normal": "/images/normal/showroom/showroom_full-21.jpg",
    "retinaWebp": "/images/hi-res-webp/showroom/showroom_full-21@2x.webp",
    "webp": "/images/normal-webp/showroom/showroom_full-21.webp"
  },
  "showroom/showroom_full-22@2x.jpg": {
    "retina": "/images/hi-res/showroom/showroom_full-22@2x.jpg",
    "normal": "/images/normal/showroom/showroom_full-22.jpg",
    "retinaWebp": "/images/hi-res-webp/showroom/showroom_full-22@2x.webp",
    "webp": "/images/normal-webp/showroom/showroom_full-22.webp"
  },
  "showroom/showroom_full-23@2x.jpg": {
    "retina": "/images/hi-res/showroom/showroom_full-23@2x.jpg",
    "normal": "/images/normal/showroom/showroom_full-23.jpg",
    "retinaWebp": "/images/hi-res-webp/showroom/showroom_full-23@2x.webp",
    "webp": "/images/normal-webp/showroom/showroom_full-23.webp"
  },
  "showroom/showroom_full-24@2x.jpg": {
    "retina": "/images/hi-res/showroom/showroom_full-24@2x.jpg",
    "normal": "/images/normal/showroom/showroom_full-24.jpg",
    "retinaWebp": "/images/hi-res-webp/showroom/showroom_full-24@2x.webp",
    "webp": "/images/normal-webp/showroom/showroom_full-24.webp"
  },
  "showroom/thumbnails/showroom_full-01@2x.jpg": {
    "retina": "/images/hi-res/showroom/thumbnails/showroom_full-01@2x.jpg",
    "normal": "/images/normal/showroom/thumbnails/showroom_full-01.jpg",
    "retinaWebp": "/images/hi-res-webp/showroom/thumbnails/showroom_full-01@2x.webp",
    "webp": "/images/normal-webp/showroom/thumbnails/showroom_full-01.webp"
  },
  "showroom/thumbnails/showroom_full-02@2x.jpg": {
    "retina": "/images/hi-res/showroom/thumbnails/showroom_full-02@2x.jpg",
    "normal": "/images/normal/showroom/thumbnails/showroom_full-02.jpg",
    "retinaWebp": "/images/hi-res-webp/showroom/thumbnails/showroom_full-02@2x.webp",
    "webp": "/images/normal-webp/showroom/thumbnails/showroom_full-02.webp"
  },
  "showroom/thumbnails/showroom_full-03@2x.jpg": {
    "retina": "/images/hi-res/showroom/thumbnails/showroom_full-03@2x.jpg",
    "normal": "/images/normal/showroom/thumbnails/showroom_full-03.jpg",
    "retinaWebp": "/images/hi-res-webp/showroom/thumbnails/showroom_full-03@2x.webp",
    "webp": "/images/normal-webp/showroom/thumbnails/showroom_full-03.webp"
  },
  "showroom/thumbnails/showroom_full-04@2x.jpg": {
    "retina": "/images/hi-res/showroom/thumbnails/showroom_full-04@2x.jpg",
    "normal": "/images/normal/showroom/thumbnails/showroom_full-04.jpg",
    "retinaWebp": "/images/hi-res-webp/showroom/thumbnails/showroom_full-04@2x.webp",
    "webp": "/images/normal-webp/showroom/thumbnails/showroom_full-04.webp"
  },
  "showroom/thumbnails/showroom_full-05@2x.jpg": {
    "retina": "/images/hi-res/showroom/thumbnails/showroom_full-05@2x.jpg",
    "normal": "/images/normal/showroom/thumbnails/showroom_full-05.jpg",
    "retinaWebp": "/images/hi-res-webp/showroom/thumbnails/showroom_full-05@2x.webp",
    "webp": "/images/normal-webp/showroom/thumbnails/showroom_full-05.webp"
  },
  "showroom/thumbnails/showroom_full-06@2x.jpg": {
    "retina": "/images/hi-res/showroom/thumbnails/showroom_full-06@2x.jpg",
    "normal": "/images/normal/showroom/thumbnails/showroom_full-06.jpg",
    "retinaWebp": "/images/hi-res-webp/showroom/thumbnails/showroom_full-06@2x.webp",
    "webp": "/images/normal-webp/showroom/thumbnails/showroom_full-06.webp"
  },
  "showroom/thumbnails/showroom_full-07@2x.jpg": {
    "retina": "/images/hi-res/showroom/thumbnails/showroom_full-07@2x.jpg",
    "normal": "/images/normal/showroom/thumbnails/showroom_full-07.jpg",
    "retinaWebp": "/images/hi-res-webp/showroom/thumbnails/showroom_full-07@2x.webp",
    "webp": "/images/normal-webp/showroom/thumbnails/showroom_full-07.webp"
  },
  "showroom/thumbnails/showroom_full-08@2x.jpg": {
    "retina": "/images/hi-res/showroom/thumbnails/showroom_full-08@2x.jpg",
    "normal": "/images/normal/showroom/thumbnails/showroom_full-08.jpg",
    "retinaWebp": "/images/hi-res-webp/showroom/thumbnails/showroom_full-08@2x.webp",
    "webp": "/images/normal-webp/showroom/thumbnails/showroom_full-08.webp"
  },
  "showroom/thumbnails/showroom_full-09@2x.jpg": {
    "retina": "/images/hi-res/showroom/thumbnails/showroom_full-09@2x.jpg",
    "normal": "/images/normal/showroom/thumbnails/showroom_full-09.jpg",
    "retinaWebp": "/images/hi-res-webp/showroom/thumbnails/showroom_full-09@2x.webp",
    "webp": "/images/normal-webp/showroom/thumbnails/showroom_full-09.webp"
  },
  "showroom/thumbnails/showroom_full-10@2x.jpg": {
    "retina": "/images/hi-res/showroom/thumbnails/showroom_full-10@2x.jpg",
    "normal": "/images/normal/showroom/thumbnails/showroom_full-10.jpg",
    "retinaWebp": "/images/hi-res-webp/showroom/thumbnails/showroom_full-10@2x.webp",
    "webp": "/images/normal-webp/showroom/thumbnails/showroom_full-10.webp"
  },
  "showroom/thumbnails/showroom_full-11@2x.jpg": {
    "retina": "/images/hi-res/showroom/thumbnails/showroom_full-11@2x.jpg",
    "normal": "/images/normal/showroom/thumbnails/showroom_full-11.jpg",
    "retinaWebp": "/images/hi-res-webp/showroom/thumbnails/showroom_full-11@2x.webp",
    "webp": "/images/normal-webp/showroom/thumbnails/showroom_full-11.webp"
  },
  "showroom/thumbnails/showroom_full-12@2x.jpg": {
    "retina": "/images/hi-res/showroom/thumbnails/showroom_full-12@2x.jpg",
    "normal": "/images/normal/showroom/thumbnails/showroom_full-12.jpg",
    "retinaWebp": "/images/hi-res-webp/showroom/thumbnails/showroom_full-12@2x.webp",
    "webp": "/images/normal-webp/showroom/thumbnails/showroom_full-12.webp"
  },
  "showroom/thumbnails/showroom_full-13@2x.jpg": {
    "retina": "/images/hi-res/showroom/thumbnails/showroom_full-13@2x.jpg",
    "normal": "/images/normal/showroom/thumbnails/showroom_full-13.jpg",
    "retinaWebp": "/images/hi-res-webp/showroom/thumbnails/showroom_full-13@2x.webp",
    "webp": "/images/normal-webp/showroom/thumbnails/showroom_full-13.webp"
  },
  "showroom/thumbnails/showroom_full-14@2x.jpg": {
    "retina": "/images/hi-res/showroom/thumbnails/showroom_full-14@2x.jpg",
    "normal": "/images/normal/showroom/thumbnails/showroom_full-14.jpg",
    "retinaWebp": "/images/hi-res-webp/showroom/thumbnails/showroom_full-14@2x.webp",
    "webp": "/images/normal-webp/showroom/thumbnails/showroom_full-14.webp"
  },
  "showroom/thumbnails/showroom_full-15@2x.jpg": {
    "retina": "/images/hi-res/showroom/thumbnails/showroom_full-15@2x.jpg",
    "normal": "/images/normal/showroom/thumbnails/showroom_full-15.jpg",
    "retinaWebp": "/images/hi-res-webp/showroom/thumbnails/showroom_full-15@2x.webp",
    "webp": "/images/normal-webp/showroom/thumbnails/showroom_full-15.webp"
  },
  "showroom/thumbnails/showroom_full-16@2x.jpg": {
    "retina": "/images/hi-res/showroom/thumbnails/showroom_full-16@2x.jpg",
    "normal": "/images/normal/showroom/thumbnails/showroom_full-16.jpg",
    "retinaWebp": "/images/hi-res-webp/showroom/thumbnails/showroom_full-16@2x.webp",
    "webp": "/images/normal-webp/showroom/thumbnails/showroom_full-16.webp"
  },
  "showroom/thumbnails/showroom_full-17@2x.jpg": {
    "retina": "/images/hi-res/showroom/thumbnails/showroom_full-17@2x.jpg",
    "normal": "/images/normal/showroom/thumbnails/showroom_full-17.jpg",
    "retinaWebp": "/images/hi-res-webp/showroom/thumbnails/showroom_full-17@2x.webp",
    "webp": "/images/normal-webp/showroom/thumbnails/showroom_full-17.webp"
  },
  "showroom/thumbnails/showroom_full-18@2x.jpg": {
    "retina": "/images/hi-res/showroom/thumbnails/showroom_full-18@2x.jpg",
    "normal": "/images/normal/showroom/thumbnails/showroom_full-18.jpg",
    "retinaWebp": "/images/hi-res-webp/showroom/thumbnails/showroom_full-18@2x.webp",
    "webp": "/images/normal-webp/showroom/thumbnails/showroom_full-18.webp"
  },
  "showroom/thumbnails/showroom_full-19@2x.jpg": {
    "retina": "/images/hi-res/showroom/thumbnails/showroom_full-19@2x.jpg",
    "normal": "/images/normal/showroom/thumbnails/showroom_full-19.jpg",
    "retinaWebp": "/images/hi-res-webp/showroom/thumbnails/showroom_full-19@2x.webp",
    "webp": "/images/normal-webp/showroom/thumbnails/showroom_full-19.webp"
  },
  "showroom/thumbnails/showroom_full-20@2x.jpg": {
    "retina": "/images/hi-res/showroom/thumbnails/showroom_full-20@2x.jpg",
    "normal": "/images/normal/showroom/thumbnails/showroom_full-20.jpg",
    "retinaWebp": "/images/hi-res-webp/showroom/thumbnails/showroom_full-20@2x.webp",
    "webp": "/images/normal-webp/showroom/thumbnails/showroom_full-20.webp"
  },
  "showroom/thumbnails/showroom_full-21@2x.jpg": {
    "retina": "/images/hi-res/showroom/thumbnails/showroom_full-21@2x.jpg",
    "normal": "/images/normal/showroom/thumbnails/showroom_full-21.jpg",
    "retinaWebp": "/images/hi-res-webp/showroom/thumbnails/showroom_full-21@2x.webp",
    "webp": "/images/normal-webp/showroom/thumbnails/showroom_full-21.webp"
  },
  "showroom/thumbnails/showroom_full-22@2x.jpg": {
    "retina": "/images/hi-res/showroom/thumbnails/showroom_full-22@2x.jpg",
    "normal": "/images/normal/showroom/thumbnails/showroom_full-22.jpg",
    "retinaWebp": "/images/hi-res-webp/showroom/thumbnails/showroom_full-22@2x.webp",
    "webp": "/images/normal-webp/showroom/thumbnails/showroom_full-22.webp"
  },
  "showroom/thumbnails/showroom_full-23@2x.jpg": {
    "retina": "/images/hi-res/showroom/thumbnails/showroom_full-23@2x.jpg",
    "normal": "/images/normal/showroom/thumbnails/showroom_full-23.jpg",
    "retinaWebp": "/images/hi-res-webp/showroom/thumbnails/showroom_full-23@2x.webp",
    "webp": "/images/normal-webp/showroom/thumbnails/showroom_full-23.webp"
  },
  "showroom/thumbnails/showroom_full-24@2x.jpg": {
    "retina": "/images/hi-res/showroom/thumbnails/showroom_full-24@2x.jpg",
    "normal": "/images/normal/showroom/thumbnails/showroom_full-24.jpg",
    "retinaWebp": "/images/hi-res-webp/showroom/thumbnails/showroom_full-24@2x.webp",
    "webp": "/images/normal-webp/showroom/thumbnails/showroom_full-24.webp"
  }
};